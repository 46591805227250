import { IFeedbackMessage } from '../types'

export const ADD_FEEDBACK_MESSAGE = 'ADD_FEEDBACK_MESSAGE'
export const RESET_FEEDBACK_MESSAGE = 'RESET_FEEDBACK_MESSAGE'

export interface IAddFeedbackMessage {
  type: typeof ADD_FEEDBACK_MESSAGE
  payload: IFeedbackMessage
}

export interface IResetFeedbackMessages {
  type: typeof RESET_FEEDBACK_MESSAGE
}

export type TFeedbackTypes = IAddFeedbackMessage | IResetFeedbackMessages

export const addToasterMessage = (
  message: IFeedbackMessage
): TFeedbackTypes => ({
  payload: message,
  type: ADD_FEEDBACK_MESSAGE
})

export const resetToasterMessages = () => ({ type: RESET_FEEDBACK_MESSAGE })
