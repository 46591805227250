import React from 'react'
import { IAccount, ISenderSourceProperty } from '../../../redux/types'
import scss from './edit-sender-source.module.scss'
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../../redux/store'
import { Edit, Delete } from '@material-ui/icons'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'

interface Props {
  account: IAccount
}
const EditSenderSource = (props: Props) => {
  const { account } = props
  const properties = useSelector(
    (state: AppState) => state.accountProperties.senderSourceList
  )
  const [property, setProperty] = React.useState<
    ISenderSourceProperty | undefined
  >(undefined)
  const [identifier, setIdentifier] = React.useState<string>('')
  const [toDelete, setToDelete] = React.useState<number | undefined>(undefined)

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (account)
      dispatch(AccountPropsActions.fetchSenderSources(account.uniqueKey))
  }, [account])

  React.useEffect(() => {
    if (property !== undefined) {
      setIdentifier(property.identifier)
    }
  }, [property])

  const reset = () => {
    setIdentifier('')
    setProperty(undefined)
  }
  const handleCancel = () => {
    reset()
  }
  const handleSave = () => {
    if (!property) {
      const p: ISenderSourceProperty = {
        identifier,
      }
      dispatch(AccountPropsActions.addSenderSource(p, account.uniqueKey))
    } else {
      dispatch(
        AccountPropsActions.replalceSenderSource(
          { ...property, identifier: identifier },
          account.uniqueKey
        )
      )
    }
    reset()
  }

  const handleDelete = () => {
    if (toDelete) {
      dispatch(
        AccountPropsActions.removeSenderSource(toDelete, account.uniqueKey)
      )
      setToDelete(undefined)
    }
  }

  return (
    <Box className={scss.root}>
      <Dialog
        onClose={() => {
          setToDelete(undefined)
        }}
        open={toDelete !== undefined}
      >
        <DialogTitle>Delete Property</DialogTitle>
        <DialogContent>
          Selected property is going to be permanently deleted
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setToDelete(undefined)
            }}
          >
            Cancel
          </Button>
          <Button color="secondary" onClick={handleDelete}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h2">Sender Source</Typography>
      <Box className={scss.actions}>
        <FormControl className={scss.identifier}>
          <TextField
            label="Identifier"
            required
            value={identifier}
            onChange={e => {
              setIdentifier(e.target.value)
            }}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={identifier === ''}
        >
          Save
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </Box>

      <Table className={scss.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Identifier</TableCell>
            <TableCell padding="checkbox">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((p, i) => (
            <TableRow hover key={`prop_${i}`}>
              <TableCell>{p.identifier}</TableCell>
              <TableCell padding="checkbox" align="right">
                <Box className={scss.tableButtons}>
                  <IconButton
                    size="small"
                    onClick={e => {
                      setProperty(p)
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton size="small" onClick={() => setToDelete(p.id)}>
                    <Delete />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
export default EditSenderSource
