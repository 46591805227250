import {
  Box,
  CircularProgress,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  useTheme,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import FingerprintIcon from '@material-ui/icons/Fingerprint'
import GroupIcon from '@material-ui/icons/Group'
import AlertIcon from '@material-ui/icons/Warning'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import EditOrganisation from '../components/accounts/edit-organisation'
import EditUomMapping from '../components/accounts/edit-uom-mapping'
import EditOrgProperties from '../components/accounts/properties/edit-org-properties'
import EditDrawer from '../components/edit-drawer'
import RouterLink from '../components/RouterLink'
import useCredentials from '../hooks/use-credentials'
import { selectAccount } from '../redux/actions/account.actions'
import {
  fetchAccounts,
  loadAccounts,
} from '../redux/actions/account.actions.old'
import { changeTitle } from '../redux/actions/header.actions'
import { AppState } from '../redux/store'
import { IAccount } from '../redux/types'
import { UIActions } from '../redux/reducers/ui.reducer'
import SkeletonTable from '../components/skeleton-table'
import { ComponentWithRoutes } from './types'
import EditOrgReceivingEmail from '../components/accounts/properties/edit-org-receiving-email'
import EditEdiExportProps from '../components/accounts/properties/edit-edi-export-props'
import EditSenderSource from '../components/accounts/properties/edit-sender-source'

type Props = {
  query?: { key: string; value: string }[]
} & ComponentWithRoutes
// TODO refactor to use OrgList from Components
const AccountsList = (props: Props) => {
  const theme = useTheme()
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const [title] = useState('Accounts')
  // ? filter by name
  const [name, setName] = useState('')
  const [tab, setTab] = useState(0)

  const selectedAccount = useSelector(
    (state: AppState) => state.selectedAccount
  )

  const credentials = useCredentials()

  //? limit of results per query
  const [limit] = useState(20)

  const dispatch = useDispatch()
  const accounts = useSelector((state: AppState) => state.accountsList)
  const loadingLines = useSelector(
    (state: AppState) => state.ui.fetching['account_lines']
  )
  const [ref, inView] = useInView()

  useEffect(() => {
    dispatch(changeTitle(title))
    document.title = title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  useEffect(() => {
    if (inView) {
      fetchNextBatch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    dispatch(UIActions.uiSetFetching('account_lines', true))
    dispatch(fetchAccounts(0, false, limit, constructQuery()))
    return () => {
      dispatch(loadAccounts([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, limit])

  const fetchNextBatch = () => {
    dispatch(UIActions.uiSetFetching('account_lines', true))
    dispatch(fetchAccounts(accounts.length, true, limit, constructQuery()))
  }

  const constructQuery = () => {
    let query: { key: string; value: string }[] = []
    if (name) {
      query.push({ key: 'nameLike', value: name })
    }
    if (props.query && props.query.length > 0) {
      query = [...query, ...props.query]
    }
    if (query.length > 0) return query
    return undefined
  }

  const editAccount = (a: IAccount) => {
    dispatch(selectAccount(a))
    setOpenEditDrawer(true)
  }

  return (
    <>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ zIndex: 1 }}>
              <Tooltip title="Add Account">
                <IconButton
                  onClick={() => {
                    dispatch(selectAccount(null))
                    setOpenEditDrawer(true)
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell />
            <TableCell>
              <TextField
                value={name}
                placeholder="Account Name"
                onChange={e => setName(e.target.value)}
                fullWidth
                helperText="Filter by Name"
              />
            </TableCell>
            <TableCell>Account Type</TableCell>
            <TableCell>Suburb</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Post Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((a, i) => (
            <TableRow
              key={'acc_' + a.uniqueKey}
              hover={true}
              selected={
                selectedAccount !== null &&
                selectedAccount.uniqueKey === a.uniqueKey
              }
              ref={
                i + 1 === accounts.length && accounts.length >= limit // ! the last row will trigger next query by entering the screen
                  ? ref
                  : null
              }
            >
              <TableCell padding="checkbox">
                {a.busy && <CircularProgress size="1.8em" />}
                {!a.busy && (
                  <>
                    <IconButton size="small" onClick={() => editAccount(a)}>
                      {a.alert && <AlertIcon color="secondary" />}
                      {!a.alert && <EditIcon />}
                    </IconButton>

                    {credentials.isSupport() && (
                      <Tooltip title="Assume a role of this org.">
                        <IconButton
                          size="small"
                          onClick={() => {
                            dispatch(
                              UIActions.uiSwapRenderFor({
                                orgType:
                                  a.type === 1 ? 'SUPPLIER' : 'UNIVERSITY',
                                orgId: a.uniqueKey,
                                companyName: a.tradingName || a.name,
                              })
                            )
                          }}
                        >
                          <FingerprintIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                )}
              </TableCell>
              <TableCell>
                <RouterLink to={`${a.uniqueKey}/user`}>
                  <GroupIcon />
                </RouterLink>
              </TableCell>
              <TableCell>{a.name}</TableCell>
              <TableCell>{a.type === 1 ? 'Supplier' : 'University'}</TableCell>
              <TableCell>{a.address2}</TableCell>
              <TableCell>{a.state}</TableCell>
              <TableCell>{a.postCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <SkeletonTable columns={5} rows={5} show={loadingLines} />

      <EditDrawer
        name="Add/Edit Account"
        open={openEditDrawer}
        handleClose={() => setOpenEditDrawer(false)}
      >
        <Box
          marginBottom="2em"
          position="sticky"
          top="2em"
          bgcolor={theme.palette.background.paper}
          zIndex={1}
        >
          <Tabs
            value={tab}
            onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
              setTab(newValue)
            }
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value={0} label="Basic Info" />
            <Tab value={1} label="Properties" disabled={!selectedAccount} />
            {selectedAccount && selectedAccount.type === 1 && (
              <Tab value={2} label="UOM Mapping" disabled={!selectedAccount} />
            )}
          </Tabs>
        </Box>

        {tab === 0 && <EditOrganisation account={selectedAccount} />}
        {tab === 1 && selectedAccount && (
          <>
            <EditOrgProperties account={selectedAccount} />
            <EditOrgReceivingEmail account={selectedAccount} />
            <EditEdiExportProps account={selectedAccount} />
            <EditSenderSource account={selectedAccount} />
            <Box minHeight="70vh"></Box>
          </>
        )}
        {tab === 2 && selectedAccount && selectedAccount.type === 1 && (
          <EditUomMapping orgId={selectedAccount.uniqueKey}></EditUomMapping>
        )}
      </EditDrawer>
    </>
  )
}
export default AccountsList
