import { Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import { IInvoiceMessage } from '../../redux/types/invoice.types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& header': {
        display: 'grid',
        gridTemplateAreas: `
        "subject     subject"
        "sender      date"
        "recipients  recipients "
      `,
        gridTemplateColumns: '1fr 1fr',
        gridGap: '1em',
        marginBottom: '2em',
        position: 'sticky',
        top: 0,
        background: theme.palette.background.default,
        padding: theme.spacing(2)
      }
    },
    body: {
      padding: theme.spacing(2)
    },
    subject: {
      gridArea: 'subject',
      '& span': {
        fontWeight: 'bold'
      }
    },
    date: {
      gridArea: 'date',
      '& span': {
        fontWeight: 'bold'
      }
    },
    recipients: {
      gridArea: 'recipients',
      '& span': {
        fontWeight: 'bold'
      }
    },
    sender: {
      gridArea: 'sender',
      '& span': {
        fontWeight: 'bold'
      }
    }
  })
)

interface Props {
  message: IInvoiceMessage
}
const InvoiceMessageView = (props: Props) => {
  const { message } = props
  const classes = useStyles()
  return (
    <article className={classes.root}>
      <header>
        <Typography className={classes.subject}>
          <span>Subject: </span>
          {message.subject}
        </Typography>
        <Typography className={classes.sender}>
          <span>From: </span>
          {message.senderEmail}
        </Typography>
        <Typography className={classes.date}>
          <span>Date: </span>
          {message.createdDate
            ? new Date(message.createdDate).toDateString()
            : ''}
        </Typography>
        <Typography className={classes.recipients}>
          <span>To: </span>
          {message.recipients ? message.recipients.join(', ') : ''}
        </Typography>
      </header>
      {message.htmlBody && (
        <section className={classes.body}>
          {ReactHtmlParser(message.htmlBody)}
        </section>
      )}
    </article>
  )
}
export default InvoiceMessageView
