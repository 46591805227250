import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrow from '@material-ui/icons/PlayArrow'
import classNames from 'classnames'
import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import styles from './AutoPlayView.module.css'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

class AutoPlayView extends Component {
  state = { activeStep: 0, play: this.props.play || false, isOpen: false }

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }))
  }

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }))
  }

  handleStepChange = activeStep => {
    this.setState({ activeStep })
  }

  togglePlay = () => {
    this.setState({ play: !this.state.play })
  }

  openLightbox = event => {
    event.preventDefault()
    this.setState({ isOpen: true })
  }

  // component tries to take the size of its parent
  // if there is no styled parent, minimum height is going to be applied
  render() {
    const { images, rounded, className, contained } = this.props
    const backgroundSize = contained ? 'contain' : 'cover'
    const { activeStep, play } = this.state
    const maxSteps = images.length
    return (
      <div
        className={classNames(
          styles.wrapper,
          className,
          rounded && styles.rounded
        )}
      >
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={this.handleStepChange}
          enableMouseEvents
          autoplay={play}
          containerStyle={{ height: '100%' }}
          style={{ height: '100%' }}
        >
          {images.map((img, index) => (
            <a
              key={index}
              href="#"
              onClick={this.openLightbox}
              className={styles.cropper}
              style={{
                backgroundImage: `url(${img})`,
                backgroundSize: backgroundSize,
              }}
            />
          ))}
        </AutoPlaySwipeableViews>
        {/* show control panel for a player, if more than one image */}
        {maxSteps > 1 && (
          <div className={styles.controls}>
            <IconButton
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </IconButton>

            <IconButton size="small" onClick={this.togglePlay}>
              {play ? <PauseIcon /> : <PlayArrow />}
            </IconButton>
            <IconButton
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <KeyboardArrowRight />
            </IconButton>
          </div>
        )}
        {this.state.isOpen && (
          <Lightbox
            reactModalStyle={{ overlay: { zIndex: 2000 } }}
            mainSrc={images[activeStep]}
            nextSrc={images[(activeStep + 1) % images.length]}
            prevSrc={images[(activeStep + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                activeStep: (activeStep + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                activeStep: (activeStep + 1) % images.length,
              })
            }
          />
        )}
      </div>
    )
  }
}
export default AutoPlayView
