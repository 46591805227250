import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      minHeight: 'calc(100vh - 64px - 3em)'
    }
  })
)

// Paper surface for the main content with consistent spacing.
// Will respect sizes of the header etc.
// ! Please use for Pages with detail information (i.e. not list and tables)

interface Props {
  children: React.ReactNode
}
const PaperMain = ({ children }: Props) => {
  const classes = useStyles()
  return <Paper className={classes.root}>{children}</Paper>
}

export default PaperMain
