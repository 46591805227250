import { Box, createStyles, Theme } from '@material-ui/core'
import { BoxProps } from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      padding: '1em 0',
      zIndex: 1
    }
  })
)

interface IProps {
  children: React.ReactNode
}
const StickyActionsBar = (props: IProps & BoxProps) => {
  const { children, ...rest } = props
  const classes = useStyles()
  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  )
}
export default StickyActionsBar
