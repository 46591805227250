// ! refactoring actions to typesafe-actions which are less verbose
import { createAction } from 'typesafe-actions'
import { IAccount } from '../types'

export const selectAccount = createAction(
  'account/SELECT',
  action => (account: IAccount | null) => action(account)
)

export const getAccount = createAction('account/GET', action => (id: string) =>
  action(id)
)
