import { createSlice, PayloadAction, createAction } from 'redux-starter-kit'
import { IPricelist, IPricelistPrice, IPricelistStore } from './../types'

const initialdData: IPricelistStore = {
  list: [],
  selected: null,
  prices: [],
}

const pricelistStore = createSlice({
  name: 'pricelist',
  initialState: initialdData,
  reducers: {
    addPricelistToStore: (state, action: PayloadAction<IPricelist>) => {
      state.list.push(action.payload)
    },
    removePricelistFromStore: (state, action: PayloadAction<IPricelist>) => {
      state.list = [...state.list.filter(l => l !== action.payload)]
    },
    modifyPricelistInStore: (state, action: PayloadAction<IPricelist>) => {
      state.list = [
        ...state.list.map(l => {
          if (l.pricelistId === action.payload.pricelistId) {
            return action.payload
          }
          return l
        }),
      ]
    },
    selectPriceList: (state, action: PayloadAction<IPricelist | null>) => {
      state.selected = action.payload
    },
    populatePricelistInStore: {
      prepare: (pricelists: IPricelist[], cursor?: string) => ({
        payload: pricelists,
        meta: { cursor },
      }),
      reducer: (
        state,
        action: PayloadAction<
          IPricelist[],
          'pricelist/populate',
          { cursor?: string }
        >
      ) => {
        state.list = action.payload
        state.cursor = action.meta.cursor
      },
    },
    appendPricelistInStore: {
      prepare: (pricelists: IPricelist[], cursor?: string) => ({
        payload: pricelists,
        meta: { cursor },
      }),
      reducer: (
        state,
        action: PayloadAction<
          IPricelist[],
          'pricelist/append',
          { cursor?: string }
        >
      ) => {
        state.list = [...state.list, ...action.payload]
        state.cursor = action.meta.cursor
      },
    },
    populatePricelistPrices: {
      prepare: (prices: IPricelistPrice[], cursor?: string) => ({
        payload: prices,
        meta: { cursor },
      }),
      reducer: (
        state,
        action: PayloadAction<
          IPricelistPrice[],
          'pricelist/populatePricelistPrices',
          { cursor?: string }
        >
      ) => {
        state.prices = action.payload
        state.pricesCursor = action.meta.cursor
      },
    },
    appendPricelistPrices: {
      prepare: (prices: IPricelistPrice[], cursor?: string) => ({
        payload: prices,
        meta: { cursor },
      }),
      reducer: (
        state,
        action: PayloadAction<
          IPricelistPrice[],
          'pricelist/appendPricelistPrices',
          { cursor?: string }
        >
      ) => {
        state.prices = [...state.prices, ...action.payload]
        state.pricesCursor = action.meta.cursor
      },
    },
  },
})

const EpicActions = {
  getPricelists: createAction(
    'pricelist/GET',
    (sellerId: string, more: boolean, cursor?: string) => ({
      payload: sellerId,
      meta: { more, cursor },
    })
  ),
  addPricelist: createAction(
    'pricelist/ADD',
    (pricelist: IPricelist, sellerId: string) => ({
      payload: pricelist,
      meta: { sellerId },
    })
  ),
  removePricelist: createAction(
    'pricelist/REMOVE',
    (pricelist: IPricelist, sellerId: string) => ({
      payload: pricelist,
      meta: { sellerId },
    })
  ),
  modifyPricelist: createAction(
    'pricelist/MODIFY',
    (pricelist: IPricelist, sellerId: string) => ({
      payload: pricelist,
      meta: { sellerId },
    })
  ),
  getPricelistPrices: createAction(
    'pricelist/GET_PRICES',
    (
      pricelistId: string,
      sellerId: string,
      more: boolean,
      cursor?: string
    ) => ({
      payload: pricelistId,
      meta: {
        sellerId,
        more,
        cursor,
      },
    })
  ),
}

export default pricelistStore.reducer
export const PricelistActions = {
  ...pricelistStore.actions,
  ...EpicActions,
}
