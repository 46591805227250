import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import {
  Theme,
  createStyles,
  Button,
  Typography,
  Grid,
} from '@material-ui/core'
import Password from './password'
import PasswordMeter from './password-meter'
import { changeUserPassword } from '../../redux/actions/user.actions'
import { AppState } from '../../redux/store'
import { UIActions } from '../../redux/reducers/ui.reducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    item: {
      padding: theme.spacing(3, 2),
    },
  })
)

const ChangePassword = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const profile = useSelector((state: AppState) => state.profile)
  const waiting = useSelector((state: AppState) => state.ui.waitingForServer)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')
  const [score, setScore] = useState(0)

  const isValid = () => {
    return (
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      repeatNewPassword.length > 0 &&
      newPassword === repeatNewPassword &&
      score > 2
    )
  }

  const hadleChangePassword = async () => {
    dispatch(UIActions.uiSetWaitingForServer(true))
    const orgid = profile && profile.policy && profile.policy.orgId
    dispatch(changeUserPassword(orgid as string, oldPassword, newPassword))
  }

  return (
    <form className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.item}>
          <Typography>Change Password</Typography>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Password
            password={oldPassword}
            setPassword={setOldPassword}
            label="Old Password"
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Password
            password={newPassword}
            setPassword={setNewPassword}
            label="New Password"
          />
          <Typography variant="caption" display="block">
            Password strength must be at least "Good"
          </Typography>
          <PasswordMeter
            password={newPassword}
            score={score}
            setScore={setScore}
          />
          <Password
            password={repeatNewPassword}
            setPassword={setRepeatNewPassword}
            label="Repeat New Password"
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Button
            color="primary"
            variant="contained"
            disabled={waiting || !isValid()}
            onClick={hadleChangePassword}
          >
            Change
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
export default ChangePassword
