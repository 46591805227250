import { Reducer } from 'redux'
import { actions, IChangeTitle, ITitle } from '../actions/header.actions'
export interface IHeaderState extends ITitle {}

const initialTitle = 'Vendor Exchange'
export const headerTitleState: Reducer<string, IChangeTitle> = (
  state = initialTitle,
  action: IChangeTitle
) => {
  switch (action.type) {
    case actions.CHANGE_TITLE:
      return action.payload.title
    default:
      return state
  }
}
