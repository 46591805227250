import React from 'react'
import { ICategoryTreeNode, ICategoryTreeContent } from '../../redux/types'
import { TreeView, TreeItem } from '@material-ui/lab'
import { Folder, FolderOpen } from '@material-ui/icons'
import nanoid from 'nanoid'
interface Props {
  categories: ICategoryTreeNode
}
const CategoryTree = (props: Props) => {
  const { categories } = props

  const Item = (props: { node: ICategoryTreeContent; code: string }) => (
    <TreeItem
      nodeId={nanoid()}
      label={`${props.node.description} [ ${props.code} ]`}
    >
      {props.node.children &&
        Object.entries(props.node.children).map(([code, value], i) => (
          <Item key={i} node={value} code={code} />
        ))}
    </TreeItem>
  )

  return (
    <TreeView
      defaultCollapseIcon={<FolderOpen />}
      defaultExpandIcon={<Folder />}
    >
      {categories &&
        typeof categories === 'object' &&
        Object.entries(categories).map(([code, value], i) => (
          <Item key={i} node={value} code={code} />
        ))}
    </TreeView>
  )
}

export default React.memo(CategoryTree)
