import { createAction } from 'typesafe-actions'
import { IAsn } from '../types/asn.types'

export const add = createAction(
  'asn/ADD',
  action => (asn: IAsn, sellerId: string, buyerId: string) => action(asn, { sellerId, buyerId })
)

export const populateList = createAction(
  'asn/POPULATE_LIST',
  action => (orders: IAsn[]) => action(orders)
)

export const extendList = createAction(
  'asn/EXPAND_LIST',
  action => (orders: IAsn[]) => action(orders)
)

export const addToList = createAction(
  'asn/ADD_TO_LIST',
  action => (asn: IAsn) => action(asn)
)

export const fetchASNs = createAction(
  'asn/FETCH_ASNs',
  action => (
    orgid: string,
    offset: number,
    limit: number,
    orderNumber: string,
    append: boolean,
    renderFor: string,
    query?: { key: string; value: string }[]
  ) => action(orgid, { offset, limit, orderNumber, append, renderFor, query })
)

export const fetchMoreASNs = createAction(
  'asn/FETCH_MORE_ASNs',
  action => (
    orgid: string,
    offset: number,
    limit: number,
    orderNumber: string,
    append: boolean,
    renderFor: string,
    query?: { key: string; value: string }[]
  ) => action(orgid, { offset, limit, orderNumber, append, renderFor, query })
)

// export const selectAsn = createAction(
//   'asn/SELECT',
//   action => (order: IAsn | null) => action(order)
// )

export const fetchAsn = createAction(
  'asn/FETCH_ASN',
  action => (orgid: string, asnid: string, renderFor: string) =>
    action(orgid, { asnid, renderFor })
)
