import {
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { IAsnAddress } from '../../../redux/types'

type Props = {
  asnAddress?: IAsnAddress
  readonly?: boolean
  setAddress: (address: IAsnAddress) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    divider: {
      marginTop: '1.5em',
      marginBottom: '1.5em',
    },
    sectionHeader: {
      marginBottom: '.5em',
    },
    alginRight: {
      alignContent: 'right',
    },
  })
)

const AsnAddress = (props: Props) => {
  const { asnAddress, readonly, setAddress } = props
  const classes = useStyles()

  const [name, setName] = useState((asnAddress && asnAddress.name) || '')
  const [postalAddressName, setPostalAddressName] = useState(
    (asnAddress && asnAddress.postalAddressName) || ''
  )
  const [postalAddressStreet, setPostalAddressStreet] = useState(
    (asnAddress && asnAddress.postalAddressStreet) || ''
  )
  const [postalAddressCity, setPostalAddressCity] = useState(
    (asnAddress && asnAddress.postalAddressCity) || ''
  )
  const [postalAddressState, setPostalAddressState] = useState(
    (asnAddress && asnAddress.postalAddressState) || ''
  )
  const [postalAddressPostcode, setPostalAddressPostcode] = useState(
    (asnAddress && asnAddress.postalAddressPostcode) || ''
  )
  const [postalAddressCountryCode, setPostalAddressCountryCode] = useState(
    (asnAddress && asnAddress.postalAddressCountryCode) || ''
  )
  const [email, setEmail] = useState((asnAddress && asnAddress.email) || '')

  useEffect(() => {
    if (asnAddress) {
      setName(asnAddress.name || '')
      setPostalAddressName(asnAddress.postalAddressName || '')
      setPostalAddressStreet(asnAddress.postalAddressStreet || '')
      setPostalAddressCity(asnAddress.postalAddressCity || '')
      setPostalAddressState(asnAddress.postalAddressState || '')
      setPostalAddressPostcode(asnAddress.postalAddressPostcode || '')
      setPostalAddressCountryCode(asnAddress.postalAddressCountryCode || '')
      setEmail(asnAddress.email || '')
    }
  }, [asnAddress])

  const setAsnName = (value: string) => {
    setName(value)
    const updatedAddress = { ...asnAddress, ...{ name: value } }
    setAddress(updatedAddress)
  }
  const setAsnPostalAddressName = (value: string) => {
    setPostalAddressName(value)
    const updatedAddress = { ...asnAddress, ...{ postalAddressName: value } }
    setAddress(updatedAddress)
  }
  const setAsnPostalAddressStreet = (value: string) => {
    setPostalAddressStreet(value)
    const updatedAddress = { ...asnAddress, ...{ postalAddressStreet: value } }
    setAddress(updatedAddress)
  }
  const setAsnPostalAddressCity = (value: string) => {
    setPostalAddressCity(value)
    const updatedAddress = { ...asnAddress, ...{ postalAddressCity: value } }
    setAddress(updatedAddress)
  }
  const setAsnPostalAddressState = (value: string) => {
    setPostalAddressState(value)
    const updatedAddress = { ...asnAddress, ...{ postalAddressState: value } }
    setAddress(updatedAddress)
  }
  const setAsnPostalAddressPostcode = (value: string) => {
    setPostalAddressPostcode(value)
    const updatedAddress = {
      ...asnAddress,
      ...{ postalAddressPostcode: value },
    }
    setAddress(updatedAddress)
  }
  const setAsnPostalAddressCountryCode = (value: string) => {
    setPostalAddressCountryCode(value)
    const updatedAddress = {
      ...asnAddress,
      ...{ postalAddressCountryCode: value },
    }
    setAddress(updatedAddress)
  }
  const setAsnEmail = (value: string) => {
    setEmail(value)
    const updatedAddress = { ...asnAddress, ...{ email: value } }
    setAddress(updatedAddress)
  }

  if (asnAddress) {
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item md>
            <FormControl>
              <TextField
                disabled={readonly}
                label="Name"
                required={false}
                value={name}
                inputProps={{
                  maxLength: 120,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                disabled={readonly}
                label="Postal Address Name"
                required={false}
                value={postalAddressName}
                inputProps={{
                  maxLength: 120,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnPostalAddressName(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                disabled={readonly}
                label="Street"
                required={false}
                value={postalAddressStreet}
                inputProps={{
                  maxLength: 250,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnPostalAddressStreet(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                disabled={readonly}
                label="City"
                required={false}
                value={postalAddressCity}
                inputProps={{
                  maxLength: 50,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnPostalAddressCity(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item md>
            <FormControl>
              <TextField
                disabled={readonly}
                label="State"
                required={false}
                value={postalAddressState}
                inputProps={{
                  maxLength: 50,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnPostalAddressState(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                disabled={readonly}
                label="Postcode"
                required={false}
                value={postalAddressPostcode}
                inputProps={{
                  maxLength: 15,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnPostalAddressPostcode(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                disabled={readonly}
                label="Country Code"
                required={false}
                value={postalAddressCountryCode}
                type="text"
                inputProps={{
                  maxLength: 2,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnPostalAddressCountryCode(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <TextField
                disabled={readonly}
                label="Email"
                required={false}
                value={email}
                type="email"
                inputProps={{
                  maxLength: 100,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setAsnEmail(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    )
  }
  return <Typography>Empty</Typography>
}

export default AsnAddress
