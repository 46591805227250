import { createReducer } from 'typesafe-actions'
import { addPolicy, addUserProfile } from '../actions/profile.actions'
import { IProfile, TProfileActions } from './../types'

const initialState: IProfile = { policy: undefined }

export const profile = createReducer<IProfile, TProfileActions>(initialState)
  .handleAction(addPolicy, (state, action) => {
    return { ...state, policy: action.payload }
  })
  .handleAction(addUserProfile, (state, action) => ({
    ...state,
    user: action.payload
  }))
