import { createAction } from 'typesafe-actions'
import { IProductFromAPI, IProductMeta } from '../types'

export const populateList = createAction(
  'product/POPULATE_LIST',
  action => (list: IProductFromAPI[], cursor?: string) =>
    action(list, { cursor })
)

export const addToList = createAction(
  'product/ADD_TO_LIST',
  action => (list: IProductFromAPI[], cursor?: string) =>
    action(list, { cursor })
)

export const selectProduct = createAction(
  'product/SELECT',
  action => (product: IProductFromAPI) => action(product)
)

export const fetchProducts = createAction(
  'product/FETCH_PRODUCTS',
  action => (
    query: { seller: string; buyer?: string },
    append: boolean,
    limit?: number,
    cursor?: string
  ) => action(query, { limit, cursor, append })
)

export const updateProductMeta = createAction(
  'product/UPDATE_META',
  action => (meta: IProductMeta, sellerId: string, buyerId: string) =>
    action(meta, { sellerId, buyerId })
)

export const updateProductMetaOnList = createAction(
  'product/UPDATE_META_ON_LIST',
  action => (meta: IProductMeta, sellerId: string, buyerId: string) =>
    action(meta, { sellerId, buyerId })
)
