export const pricelists = {
  getOne: '/seller/:sellerId/pricelist/:pricelistId',
  putOne: '/seller/:sellerId/pricelist/:pricelistId',
  getList: '/seller/:sellerId/pricelist',
  postOne: '/seller/:sellerId/pricelist',
  prices: {
    getList: '/seller/:sellerId/pricelist/:pricelistId/price',
  },
  //? mapping buyers to a pricelist
  buyers: {
    getList: '/seller/:sellerId/pricelist/:pricelistId/buyers',
    putList: '/seller/:sellerId/pricelist/:pricelistId/buyers',
  },
}

export const otherOrgProperties = {
  getList: '/org/:orgid/other-org-property',
  getOne: '/org/:orgid/other-org-property', //? same endpoint, use query
}

export const buyerSellerProps = {
  getOne: '/buyer/:buyerId/seller/:sellerId/props',
  postOne: '/buyer/:buyerId/seller/:sellerId/props',
}

export const receivingEmail = {
  getList: '/org/:orgId/receiving-email',
  postOne: '/org/:orgId/receiving-email',
  putOne: '/org/:orgId/receiving-email/:propid',
  deleteOne: '/org/:orgId/receiving-email/:propid',
}

export const ediExport = {
  getList: '/org/:orgId/edi-export',
  postOne: '/org/:orgId/edi-export',
}

export const orderEP = {
  exportOne: '/seller/:sellerId/order/:orderid/actions/export',
}

export const senderSource = {
  getList: '/org/:orgId/sending-email-domain',
  postOne: '/org/:orgId/sending-email-domain',
  putOne: '/org/:orgId/sending-email-domain/:propid',
  deleteOne: '/org/:orgId/sending-email-domain/:propid',
}
