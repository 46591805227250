import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Drawer,
  Tooltip,
  Slide,
} from '@material-ui/core'
import { AddCircle, CloudUpload, ScatterPlot } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import CommanderView from '../components/commander-vew'
import FloatingCardPanel from '../components/floating-card-panel'
import PriceListPrices from '../components/pricelists/pricelist-prices'
import useCredentials from '../hooks/use-credentials'
import { changeTitle } from '../redux/actions/header.actions'
import { UIActions } from '../redux/reducers/ui.reducer'
import { AppState } from '../redux/store'
import SkeletonTable from './../components/skeleton-table'
import scss from './pricelist.module.scss'
import { ComponentWithRoutes } from './types'
import { PricelistActions } from '../redux/reducers/pricelist.reducer'
import FileUpload from '../components/filesystem/file-upload'
import PricelistMapping from '../components/pricelists/pricelist-mapping'

const PricelistsPage = (props: ComponentWithRoutes) => {
  const [title] = useState('Pricelists')
  const dispatch = useDispatch()
  const credentials = useCredentials()
  const list = useSelector((state: AppState) => state.pricelist.list)
  const cursor = useSelector((state: AppState) => state.pricelist.cursor)
  const selected = useSelector((state: AppState) => state.pricelist.selected)
  const loadingList = useSelector(
    (state: AppState) => state.ui.fetching['pricelist_list']
  )
  const [name, setname] = React.useState<string>('')
  const [limit] = React.useState<number>(20)
  const [showMapping, setShowMapping] = React.useState<boolean>(false)

  const [isnew, setisnew] = React.useState<boolean>(false)
  const [uploadUrl, setuploadUrl] = React.useState<string | undefined>()

  const [ref, inView] = useInView()
  const getInvViewRef = (position: number) => {
    if (
      position === list.length &&
      list.length >= limit &&
      cursor !== undefined
    ) {
      return ref
    }
    return null
  }

  const getUploadURL = async () => {
    if (selected && credentials.getOrgId()) {
      const url = `${
        process.env.REACT_APP_API_SELLER
      }/${credentials.getOrgId()}/pricelist/${
        selected.pricelistId
      }/price/action/upload-url`

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res.ok) {
        const j = await res.json()
        if (j.uploadUrl) {
          setuploadUrl(j.uploadUrl)
        }
      }
    }
  }

  useEffect(() => {
    dispatch(changeTitle(title))
    document.title = title

    return () => {
      setuploadUrl(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  useEffect(() => {
    if (credentials.getOrgId() !== '') {
      dispatch(UIActions.uiSetFetching('pricelist_list', true))
      dispatch(
        PricelistActions.getPricelists(credentials.getOrgId(), false, cursor)
      )
    }
    return () => {
      dispatch(PricelistActions.populatePricelistInStore([]))
      handleReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.getOrgId()])

  useEffect(() => {
    if (inView && credentials.getOrgId()) {
      dispatch(UIActions.uiSetFetching('pricelist_list', true))
      dispatch(
        PricelistActions.getPricelists(credentials.getOrgId(), true, cursor)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    if (selected !== null) {
      setname(selected.name)
    } else {
      setname('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  const handleReset = () => {
    dispatch(PricelistActions.selectPriceList(null))
    setisnew(false)
  }

  const handleSubmit = () => {
    if (name !== '') {
      if (selected === null) {
        dispatch(
          PricelistActions.addPricelist({ name: name }, credentials.getOrgId())
        )
      } else {
        dispatch(
          PricelistActions.modifyPricelist(
            { name: name, pricelistId: selected.pricelistId },
            credentials.getOrgId()
          )
        )
      }
    }
  }

  const openMappingDrawer = () => setShowMapping(true)

  return (
    <CommanderView defaultLeftSize={30}>
      <FloatingCardPanel className={scss.listPanel}>
        <Table stickyHeader size="small" className={scss.list}>
          <TableHead>
            <TableRow>
              <TableCell>Pricelist Name</TableCell>
              <TableCell padding="checkbox">
                <IconButton
                  onClick={() => {
                    handleReset()
                    setisnew(true)
                  }}
                >
                  <AddCircle />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((l, i) => (
              <TableRow
                key={l.pricelistId}
                hover
                onClick={() => dispatch(PricelistActions.selectPriceList(l))}
                selected={
                  selected !== null && selected.pricelistId === l.pricelistId
                }
                ref={getInvViewRef(i + 1)}
              >
                <TableCell>{l.name}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SkeletonTable columns={1} rows={4} show={loadingList} />
      </FloatingCardPanel>
      <FloatingCardPanel className={scss.formPanel}>
        {(selected || isnew) && (
          <Box>
            <Box className={scss.form}>
              <FormControl fullWidth>
                <TextField
                  label="Pricelist Name"
                  value={name}
                  onChange={e => setname(e.target.value)}
                  onKeyDown={k => {
                    if (k.key === 'Enter') {
                      handleSubmit()
                    }
                  }}
                />
              </FormControl>
              <Button onClick={handleSubmit}>
                {selected === null ? 'Add' : 'Modify'}
              </Button>
            </Box>
            <Divider />
            {selected && (
              <Box
                marginBottom="2em"
                flexDirection="row"
                display="flex"
                alignItems="center"
              >
                <Box className={scss.actions}>
                  <Tooltip title="Request Upload Pricing">
                    <Button onClick={getUploadURL} startIcon={<CloudUpload />}>
                      Request Upload
                    </Button>
                  </Tooltip>
                  <Button
                    onClick={openMappingDrawer}
                    startIcon={<ScatterPlot />}
                  >
                    Map Universities
                  </Button>
                </Box>
                <Slide in={uploadUrl !== undefined} direction="down">
                  <Box padding="1em" flexGrow={1}>
                    {uploadUrl && (
                      <FileUpload
                        url={uploadUrl}
                        method="PUT"
                        callback={() => {
                          setTimeout(() => {
                            setuploadUrl(undefined)
                            dispatch(
                              PricelistActions.getPricelistPrices(
                                selected.pricelistId!,
                                credentials.getOrgId(),
                                false,
                                cursor
                              )
                            )
                          }, 3000)
                        }}
                      />
                    )}
                  </Box>
                </Slide>
              </Box>
            )}
            <Box className={scss.prices}>
              {selected && <PriceListPrices pricelist={selected} />}
            </Box>
            <Drawer
              anchor="right"
              open={showMapping}
              onClose={() => setShowMapping(false)}
              classes={{
                paper: scss.mappingDrawer,
              }}
            >
              <PricelistMapping pricelist={selected!} />
            </Drawer>
          </Box>
        )}
      </FloatingCardPanel>
    </CommanderView>
  )
}
export default PricelistsPage
