export interface IURLParams {
  [key: string]: string | number
}
export interface IURLQuery {
  [key: string]: string | number
}
export const genUrl = (
  endpoint: string,
  params?: IURLParams,
  query?: IURLQuery
): string => {
  const amp = process.env.REACT_APP_AMPERSAND_SYMBOL
  const url = process.env.REACT_APP_API_PREFIX + endpoint
  const exploded = url.split('/')
  const supp = exploded
    .map(e => {
      const key = e.substr(1)
      if (params !== undefined && params[key] !== undefined) {
        return params[key]
      }
      return e
    })
    .join('/')

  let q = ''
  if (query !== undefined) {
    q =
      '?' +
      Object.entries(query)
        .map(([key, value], i) => {
          return `${key}=${encodeURI(value.toString())}`
        })
        .join(amp)
  }

  return supp + q
}
export default genUrl
