import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import AccountsList from '../../pages/accounts-list'
import { fetchAccounts } from '../../redux/actions/account.actions.old'
import BuyersProductList from './buyers-product-list'
import SellersProductList from './sellers-product-list'
import { ComponentWithRoutes } from '../../pages/types'

const ProductList = (props: ComponentWithRoutes) => {
  const dispatch = useDispatch()
  const credentials = useCredentials()

  if (credentials.renderForOrgType('SUPPLIER')) {
    return <SellersProductList sellerId={credentials.getOrgId()} />
  } else if (credentials.renderForOrgType('UNIVERSITY')) {
    // fetching suppliers for the list to select from
    // TODO pagination
    dispatch(fetchAccounts(0, false, 100, [{ key: 'type', value: '1' }]))
    return <BuyersProductList />
  } else if (credentials.isSupport()) {
    return (
      <>
        <Box marginBottom="2em" padding="1em">
          <Typography variant="h3">
            Please, assume a role of either seller or buyer to see the catalogue
            from their perspective!{' '}
          </Typography>
        </Box>
        <AccountsList path="/" />
      </>
    )
  }

  return <Typography variant="h1"></Typography>
}

export default ProductList
