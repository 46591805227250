import {
  Box,
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import {
  fetchProducts,
  populateList,
  selectProduct,
} from '../../redux/actions/product.actions'
import { AppState } from '../../redux/store'
import EditDrawer from '../edit-drawer'
import ProductProfile from './product-profile'
import SellerCatalogueToolset from './seller-catalogue-toolset'
import SkeletonTable from '../skeleton-table'
import { UIActions } from '../../redux/reducers/ui.reducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableRow: {
      cursor: 'pointer',
    },
  })
)
interface Props {
  sellerId: string
}
const SellersProductList = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const credentials = useCredentials()
  const [limit] = React.useState(20)
  const [peek, setPeek] = React.useState(false)

  const cursor = useSelector((state: AppState) => state.products.cursor)
  const list = useSelector((state: AppState) => state.products.list)
  const loadingList = useSelector(
    (state: AppState) => state.ui.fetching['product_list']
  )
  const selectedProduct = useSelector(
    (state: AppState) => state.products.selected
  )

  const [ref, inView] = useInView()
  const getInvViewRef = (position: number) => {
    if (
      position === list.length - 5 &&
      list.length >= limit &&
      cursor !== undefined
    ) {
      return ref
    }
    return null
  }

  React.useEffect(() => {
    if (
      credentials.getOrgId() &&
      credentials.renderForOrgType('SUPPLIER') &&
      limit > 0
    ) {
      dispatch(UIActions.uiSetFetching('product_list', true))
      dispatch(
        fetchProducts(
          {
            seller: credentials.getOrgId(),
          },
          false,
          limit
        )
      )
    }
    return () => {
      dispatch(populateList([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, credentials.getOrgId()])

  React.useEffect(() => {
    if (inView) loadMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const loadMore = () => {
    dispatch(UIActions.uiSetFetching('product_list', true))
    dispatch(
      fetchProducts(
        {
          seller: credentials.getOrgId(),
        },
        true,
        limit,
        cursor
      )
    )
  }

  return (
    <Box>
      <SellerCatalogueToolset sellerId={props.sellerId} />
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell size="medium">Code</TableCell>
            <TableCell size="medium">Description</TableCell>
            <TableCell size="medium">UOM</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((p, i) => (
            <TableRow
              className={classes.tableRow}
              key={i}
              hover
              selected={
                selectedProduct !== null &&
                selectedProduct.product.productCode === p.product.productCode
              }
              ref={getInvViewRef(i + 1)}
              onClick={() => {
                setPeek(true)
                dispatch(selectProduct(p))
              }}
            >
              <TableCell>{p.product.productCode}</TableCell>
              <TableCell>{p.product.description}</TableCell>
              <TableCell>{p.product.unitOfMeasure}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <SkeletonTable columns={5} show={loadingList} />
      {selectedProduct !== null && (
        <EditDrawer
          open={peek}
          name={`Product Code: ${selectedProduct.product.productCode}`}
          handleClose={() => setPeek(false)}
        >
          <ProductProfile product={selectedProduct} />
        </EditDrawer>
      )}
    </Box>
  )
}
export default SellersProductList
