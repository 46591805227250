import { configureStore, getDefaultMiddleware } from 'redux-starter-kit'
import { createEpicMiddleware } from 'redux-observable'
import rootEpic from './epics'
import rootReducer from './reducers'

export type AppState = ReturnType<typeof rootReducer>
const epicMiddleware = createEpicMiddleware()
const middleware = [...getDefaultMiddleware(), epicMiddleware]

const store = configureStore({
  reducer: rootReducer,
  middleware,
})

epicMiddleware.run(rootEpic)

export default store
