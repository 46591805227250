import { Box, CircularProgress, createStyles, Drawer, Fade, IconButton, Theme, Tooltip, Typography } from '@material-ui/core'
import { DrawerProps } from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../redux/store'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: '45vw',
      height: 'calc(100vh - 64px)',
      marginTop: '64px',
      padding: '1em',
      [theme.breakpoints.down('md')]: {
        width: '65vw'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100vw'
      }
    },
    titlebox: {
      position: 'sticky',
      top: '-1em',
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.appBar
    },
    modal: {
      background: 'rgba(0,0,0,.5)',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.modal,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
)
interface IEditDrawer extends DrawerProps {
  name: string
  handleClose?: () => void
}
const EditDrawer = ({ name, handleClose, children, ...rest }: IEditDrawer) => {
  const classes = useStyles()
  const busy = useSelector((state: AppState) => state.ui.editDrawerBusy)
  return (
    <Drawer
      anchor="right"
      {...rest}
      classes={{
        paper: classes.drawer
      }}
      variant="persistent"
      PaperProps={{ elevation: 8 }}
    >
      <Box
        className={classes.titlebox}
        justifyContent="space-between"
        alignItems="center"
        display="flex"
      >
        <Typography variant="h5">{name}</Typography>
        {handleClose && (
          <Tooltip title="Close">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {children}
      <Fade in={busy}>
        <div className={classes.modal}>
          <CircularProgress style={{ color: '#fff' }} size={80} />
        </div>
      </Fade>
    </Drawer>
  )
}
export default EditDrawer
