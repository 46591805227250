import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { IOrderAddress } from '../../redux/types'
import PropertyTag from '../products/property-tag'

type Props = {
  orderAddress?: IOrderAddress
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    divider: {
      marginTop: '1.5em',
      marginBottom: '1.5em',
    },
    sectionHeader: {
      marginBottom: '.5em',
    },
    alginRight: {
      alignContent: 'right',
    },
  })
)

const OrderAddress = (props: Props) => {
  const { orderAddress } = props
  const classes = useStyles()

  if (orderAddress) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" className={classes.sectionHeader}>
          {orderAddress.name}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md>
            <PropertyTag
              propertyKey="Name"
              propertyValue={orderAddress.postalAddressName}
            />
            <PropertyTag
              propertyKey="Street"
              propertyValue={orderAddress.postalAddressStreet}
            />
            <PropertyTag
              propertyKey="City"
              propertyValue={orderAddress.postalAddressCity}
            />
            <PropertyTag
              propertyKey="State"
              propertyValue={orderAddress.postalAddressState}
            />
            <PropertyTag
              propertyKey="Postcode"
              propertyValue={orderAddress.postalAddressPostcode}
            />
            <PropertyTag
              propertyKey="Country Code"
              propertyValue={orderAddress.postalAddressCountryCode}
            />
            <PropertyTag
              propertyKey="Country Name"
              propertyValue={orderAddress.postalAddressCountryName}
            />
          </Grid>
          <Grid item md>
            <PropertyTag
              propertyKey="Email"
              propertyValue={orderAddress.email}
            />
            <PropertyTag
              propertyKey="Phone Country Code"
              propertyValue={orderAddress.phoneCountryCode}
            />
            <PropertyTag
              propertyKey="Phone Area Code"
              propertyValue={orderAddress.phoneAreaCode}
            />
            <PropertyTag
              propertyKey="Phone Number"
              propertyValue={orderAddress.phoneNumber}
            />
            <PropertyTag
              propertyKey="Fax Country Code"
              propertyValue={orderAddress.faxCountryCode}
            />
            <PropertyTag
              propertyKey="Fax Area Code"
              propertyValue={orderAddress.faxAreaCode}
            />
            <PropertyTag
              propertyKey="Fax Number"
              propertyValue={orderAddress.faxNumber}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
  return <Typography>Empty</Typography>
}

export default OrderAddress

