import { ActionsObservable, StateObservable } from 'redux-observable'
import { of } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { resetToasterMessages } from '../actions/feedback.actions'
import {
  acceptTermsOfUse,
  addPolicy,
  addUserProfile,
  fetchPolicy,
} from '../actions/profile.actions'
import { IPolicy, IUIRenderFor, IUserProfile, TProfileActions } from '../types'
import { broadcastAjaxErrors } from './utils'
import { UIActions } from '../reducers/ui.reducer'
import { AppState } from '../store'

export const fetchPolicyEpic = (
  action$: ActionsObservable<TProfileActions>
) => {
  return action$.pipe(
    filter(isActionOf(fetchPolicy)),
    switchMap(() => {
      const url = process.env.REACT_APP_API_POLICY as string
      return ajax.getJSON(url).pipe(
        map(response => {
          return addPolicy(response as IPolicy)
        }),
        catchError((error: AjaxError) => {
          // const eb: APIError = error.response
          // const e: string = eb && eb.errors ? eb.errors.join() : error.message
          console.log(error)
          return of(resetToasterMessages())
        })
      )
    })
  )
}

export const acceptTermsOfUSeEpic = (
  action$: ActionsObservable<TProfileActions>
) => {
  return action$.pipe(
    filter(isActionOf(acceptTermsOfUse)),
    switchMap(action => {
      const url = process.env.REACT_APP_API_POLICY + '/actions/acceptTermsOfUse'

      return ajax
        .put(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          map(response => {
            return addPolicy(response.response as IPolicy)
          }),
          catchError((error: AjaxError) => of(broadcastAjaxErrors(error)))
        )
    })
  )
}

export const fetchUserProfileEpic = (
  action$: ActionsObservable<TProfileActions>
) =>
  action$.pipe(
    filter(isActionOf(addPolicy)),
    switchMap(action => {
      const url = process.env.REACT_APP_API_PROFILE as string
      return ajax.getJSON(url).pipe(
        mergeMap(response => {
          let a = []
          a.push(addUserProfile(response as IUserProfile))
          const saved = localStorage.getItem('renderFor')
          if (!saved) {
            a.push(
              UIActions.uiRenderFor({
                ...action.payload,
                ...(response as IPolicy),
              } as IUIRenderFor)
            )
          }
          return a
        }),
        catchError((error: AjaxError) => of(broadcastAjaxErrors(error)))
      )
    })
  )

export const profileEpics = [
  fetchPolicyEpic,
  fetchUserProfileEpic,
  acceptTermsOfUSeEpic,
]
