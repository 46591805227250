import { Slide } from '@material-ui/core'
import React from 'react'

type Props = {
  children: React.ReactNode
  show: boolean
}
const TabContent = (props: Props) => {
  if (props.show) {
    return (
      <Slide in={props.show} direction="right">
        {props.children}
      </Slide>
    )
  }
  return null
}
export default TabContent
