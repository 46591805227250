import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../../redux/store'

const AsnExportErrors = () => {
  const history = useSelector((state: AppState) => state.asns.history)

  return (
    <Box ml={2} mt={2}>
      <Typography variant="h2">
        <strong>ASN Export Errors</strong>
      </Typography>
      {history &&
        history.map((h, i) => (
          <Box m={2} key={i}>
            <div>
              <strong>Date: </strong>
              {h.date && new Date(h.date).toString().slice(0, 21)}
            </div>
            <div>
              <strong>Action: </strong>
              {h.action}
            </div>
            <div>
              <strong>Description: </strong>
              {h.description}
            </div>
            <div>
              <strong>By: </strong>
              {h.user}
            </div>
          </Box>
        ))}
    </Box>
  )
}

export default AsnExportErrors
