import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'

interface Props {
  open: boolean
  title?: string
  children: React.ReactNode
  onConfirm: () => void
  onCancel: () => void
}
const ConfrimDialog = (props: Props) => {
  const { children, title, open, onConfirm, onCancel } = props

  const handleConfirm = () => {
    onConfirm()
  }
  const handleCancel = () => {
    onCancel()
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm}>Confirm</Button>
        <Button onClick={handleCancel} variant="contained" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfrimDialog
