import {
  Radio,
  IconButton,
  Box,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import useCredentials from '../../../hooks/use-credentials'
import { IAccount, IOtherOrgProperty } from '../../../redux/types'
import CommanderView from '../../commander-vew'
import FloatingCardPanel from '../../floating-card-panel'
import OrgList from '../list'
import OtherOrgPropertiesForm from './other-org-properties-form'
import OtherOrgPropertiesList from './other-org-properties-list'
import scss from './other-org-properties.module.scss'
import { Edit, Delete } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'
import BuyerSellerProps from './buyer-seller-props'
import BuyerSellerAccountCodes from './buyer-seller-account-codes'

interface Props {
  className?: string
}
const OtherOrgProperties = (props: Props) => {
  const { className } = props

  const dispatch = useDispatch()
  const credentials = useCredentials()
  const [selectedOrg, setSelectedOrg] = React.useState<IAccount | undefined>(
    undefined
  )
  const [orgproperty, setOrgproperty] = React.useState<
    IOtherOrgProperty | undefined
  >(undefined)

  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false)

  const [orgpropertyToDelete, setOrgpropertyToDelete] = React.useState<
    IOtherOrgProperty | undefined
  >(undefined)

  const [tab, setTab] = React.useState<string>('other')

  const ListAction = (account: IAccount) => {
    return (
      <Radio
        color="primary"
        checked={
          selectedOrg !== undefined &&
          selectedOrg.uniqueKey === account.uniqueKey
        }
        onChange={() => setSelectedOrg(account)}
      />
    )
  }

  const PropertyActions = (property: IOtherOrgProperty) => {
    return (
      <Box display="flex" flexDirection="row">
        <Tooltip title="Edit">
          <IconButton size="small" onClick={() => setOrgproperty(property)}>
            <Edit />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton
            size="small"
            onClick={() => {
              setOrgpropertyToDelete(property)
              setDeleteDialogOpen(true)
            }}
          >
            <Delete />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }

  const handleCancel = () => {
    setDeleteDialogOpen(false)
    setOrgpropertyToDelete(undefined)
  }
  const handleDelete = () => {
    setDeleteDialogOpen(false)
    setOrgpropertyToDelete(undefined)
    if (orgpropertyToDelete && credentials.getOrgId())
      dispatch(
        AccountPropsActions.removeOtherOrgProperty(
          orgpropertyToDelete,
          credentials.getOrgId()
        )
      )
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTab(newValue)
  }

  return (
    <CommanderView defaultLeftSize={60} className={clsx(className)}>
      <FloatingCardPanel className={scss.leftPane}>
        <OrgList
          query={[
            {
              key: 'type',
              value: credentials.renderForOrgType('SUPPLIER') ? '2' : '1',
            },
          ]}
          actions={ListAction}
        />
      </FloatingCardPanel>
      <FloatingCardPanel>
        <Tabs onChange={handleTabChange} value={tab}>
          <Tab value="other" label="Other Properties" />
          {credentials.renderForOrgType('UNIVERSITY') && (
            <Tab label="Account No" value="account_no" />
          )}
        </Tabs>

        {!selectedOrg && (
          <Box padding="1em">
            <Typography>Please select an account first</Typography>
          </Box>
        )}

        {selectedOrg && tab === 'other' && (
          <OtherOrgPropertiesForm
            orgproperty={orgproperty}
            otherAccount={selectedOrg}
          />
        )}

        {selectedOrg && credentials.getOrgId() && tab === 'other' && (
          <OtherOrgPropertiesList
            myId={credentials.getOrgId()}
            otherId={selectedOrg.uniqueKey}
            actions={PropertyActions}
          />
        )}

        {selectedOrg && credentials.getOrgId() && tab === 'account_no' && (
          <>
            <BuyerSellerProps
              myOrgId={credentials.getOrgId()}
              account={selectedOrg}
            />
            <BuyerSellerAccountCodes
              myOrgId={credentials.getOrgId()}
              account={selectedOrg}
            />
          </>
        )}

        {deleteDialogOpen && orgpropertyToDelete && (
          <Dialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
          >
            <DialogTitle>Delete Property</DialogTitle>
            <DialogContent>
              Property <strong>{orgpropertyToDelete.property}</strong> is going
              to be permanently deleted.
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={handleCancel}>
                Cancel
              </Button>
              <Button color="secondary" onClick={handleDelete}>
                Proceed
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </FloatingCardPanel>
    </CommanderView>
  )
}
export default OtherOrgProperties
