import { createStyles, Grid, LinearProgress, Theme } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles'
import { makeStyles, withStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import zxcvbn from 'zxcvbn'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    strengthBar: {
      height: 10,
      width: 220,
      minWidth: 50,
      borderRadius: 3
    },
    strengthBarWrapper: {
      padding: theme.spacing(2, 0),
      alignContent: 'center'
    }
  })
)

type Props = {
  password: string
  score: number
  setScore: (score: number) => void
}

const PasswordMeter = (props: Props) => {
  const classes = useStyles()

  const { score, setScore, password } = props
  const strengthScore = zxcvbn(password)
  const [completed, setCompleted] = useState(0)
  const [strengthLabel, setStrengthLabel] = useState('Weak')

  const [strengthColor, setStrengthColor] = useState('#F25F5C')

  const BorderLinearProgress = withStyles({
    root: {
      height: 10,
      backgroundColor: lighten('#aaaaaa', 0.5)
    },
    bar: {
      borderRadius: 20,
      backgroundColor: strengthColor
    }
  })(LinearProgress)

  useEffect(() => {
    setScore(strengthScore.score)
    switch (strengthScore.score) {
      case 0:
      case 1:
        setStrengthLabel('Weak')
        setStrengthColor('#F25F5C')
        setCompleted(5)
        break
      case 2:
        setStrengthLabel('Fair')
        setStrengthColor('#FFE066')
        setCompleted(33)
        break
      case 3:
        setStrengthLabel('Good')
        setStrengthColor('#247BA0')
        setCompleted(66)
        break
      case 4:
        setStrengthLabel('Strong')
        setStrengthColor('#70C1B3')
        setCompleted(100)
        break
      default:
        setStrengthLabel('Weak')
        setStrengthColor('#F25F5C')
        setCompleted(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [strengthScore])

  return (
    <Grid container>
      <Grid item xs={4} className={classes.strengthBarWrapper}>
        <BorderLinearProgress
          className={classes.strengthBar}
          variant="determinate"
          value={completed}
        />
        <label>{strengthLabel}</label>
      </Grid>
    </Grid>
  )
}
export default PasswordMeter
