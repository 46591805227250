import {
  Box,
  Button,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import Password from '../settings/password'
import PasswordMeter from '../settings/password-meter'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginForm: {
      alignSelf: 'end',
      margin: '2em',
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        marginRight: '1em',
      },
    },
    loginFormFields: {
      marginBottom: '1em',
    },
  })
)

interface IProps {
  handleToLogin: () => void
}

const ForgotPassword = (props: IProps) => {
  const { handleToLogin } = props

  const [username, setUsername] = useState('')
  const [message, setMessage] = useState('')
  const [messageColor, setMessageColor] = useState('red')
  const [verificationCode, setVerificationCode] = useState('')
  const [score, setScore] = useState(0)
  const [newPassword, setNewPassword] = useState('')
  const [repeatNewPassword, setRepeatNewPassword] = useState('')

  const [showVerificationCode, setShowVerificationCode] = useState(false)
  const [waitingForServer, setWaitingForServer] = useState(false)

  const classes = useStyles()

  const handleSendRecoveryCode = async () => {
    setMessage('')
    setWaitingForServer(true)
    const data = {
      username: username,
    }

    const resp = await fetch(
      process.env.REACT_APP_API_LOGIN + `/actions/sendRecoveryCode`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
    if (resp.ok) {
      setMessageColor('green')
      setMessage('A verification code has been sent to your email')
      setShowVerificationCode(true)
    } else if (resp.status === 400) {
      setMessageColor('red')
      const jsonResponse = await resp.json()
      setMessage(jsonResponse.errors)
    } else {
      setMessageColor('red')
      setMessage('Something went wrong, please try again later')
    }

    setWaitingForServer(false)
  }

  const isValid = () => {
    return (
      verificationCode.length > 0 &&
      newPassword.length > 0 &&
      repeatNewPassword.length > 0 &&
      newPassword === repeatNewPassword &&
      score > 2 &&
      !waitingForServer
    )
  }

  const handleRecoverPassword = async () => {
    setMessage('')
    setWaitingForServer(true)
    const data = {
      username: username,
      verificationCode: verificationCode,
      newPassword: newPassword,
    }

    const resp = await fetch(
      process.env.REACT_APP_API_LOGIN + `/actions/resetPassword`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }
    )
    if (resp.ok) {
      setMessageColor('green')
      setMessage('Password succesfully reseted!')
      setShowVerificationCode(true)
      setVerificationCode('')
      setNewPassword('')
      setRepeatNewPassword('')
    } else if (resp.status === 400) {
      setMessageColor('red')
      const jsonResponse = await resp.json()
      setMessage(jsonResponse.errors)
    } else {
      setMessageColor('red')
      setMessage('Something went wrong, please try again later')
    }

    setWaitingForServer(false)
  }

  return (
    <form className={classes.loginForm}>
      <Box pb={3}>
        <Typography variant="h2">Password recovery</Typography>
      </Box>
      {!showVerificationCode && (
        <div>
          <Box pb={2}>
            <TextField
              label="Username"
              type="email"
              value={username}
              fullWidth
              onChange={e => setUsername(e.target.value)}
              required={true}
            />
          </Box>
          <div>
            <Button variant="contained" onClick={handleToLogin}>
              Login
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSendRecoveryCode}
              disabled={username === '' || waitingForServer}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
      {showVerificationCode && (
        <div>
          <Box pb={2}>
            <TextField
              label="Verification code"
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
              fullWidth
              required={true}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <Password
            password={newPassword}
            setPassword={setNewPassword}
            label="New Password"
          />
          <Typography variant="caption" display="block">
            Password strength must be at least "Good"
          </Typography>
          <PasswordMeter
            password={newPassword}
            score={score}
            setScore={setScore}
          />
          <Password
            password={repeatNewPassword}
            setPassword={setRepeatNewPassword}
            label="Repeat New Password"
          />
          <Box pt={2}>
            <Button variant="contained" onClick={handleToLogin}>
              Login
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleRecoverPassword}
              disabled={!isValid()}
            >
              Reset
            </Button>
          </Box>
        </div>
      )}
      {message && (
        <Box padding="1em" marginTop="1em" color={messageColor}>
          {message}
        </Box>
      )}
    </form>
  )
}

export default ForgotPassword
