import { createReducer } from 'typesafe-actions'
import { addToList, populateList, selectProduct, updateProductMetaOnList } from '../actions/product.actions'
import { IProductStore, TProductActions } from '../types'

const initData: IProductStore = {
  list: [],
  selected: null
}

export const products = createReducer<IProductStore, TProductActions>(initData)
  .handleAction(populateList, (state, action) => ({
    ...state,
    cursor: action.meta.cursor,
    list: action.payload
  }))
  .handleAction(addToList, (state, action) => ({
    ...state,
    cursor: action.meta.cursor,
    list: [...state.list, ...action.payload]
  }))
  .handleAction(selectProduct, (state, action) => ({
    ...state,
    selected: action.payload
  }))
  .handleAction(updateProductMetaOnList, (state, action) => ({
    ...state,
    list: state.list.map(l => {
      if (
        l.product.sellerId === action.meta.sellerId &&
        l.product.productCode === action.payload.productCode
      ) {
        return { ...l, meta: action.payload }
      }
      return l
    }),
    selected:
      state.selected &&
      state.selected.product.sellerId === action.meta.sellerId &&
      state.selected.product.productCode === action.payload.productCode
        ? { ...state.selected, meta: action.payload }
        : state.selected
  }))
