import React from 'react'
import { IProductPrice, IProductContractPrice } from '../../redux/types'
import FormatCurrencyText from '../utils/format-currency-text'
interface Props {
  type: 'product' | 'contract'
  prices?: IProductPrice[] | IProductContractPrice[]
}

type Price = IProductPrice | IProductContractPrice
const isProductPrice = (object: any): object is IProductPrice => {
  return object.lowerQuantity !== undefined
}
const isContractPrice = (object: any): object is IProductContractPrice => {
  return object.quantity !== undefined
}

const pricesComparator = (a: Price, b: Price) => {
  if (isProductPrice(a) && isProductPrice(b)) {
    if (a.lowerQuantity && b.lowerQuantity) {
      if (a.lowerQuantity > b.lowerQuantity) return 1
      if (a.lowerQuantity < b.lowerQuantity) return -1
    }
  } else if (isContractPrice(a) && isContractPrice(b)) {
    if (a.quantity && b.quantity) {
      if (a.quantity > b.quantity) return 1
      if (a.quantity < b.quantity) return -1
    }
  }
  return 0
}

const PriceForQtyOneBadge = ({ prices, type }: Props) => {
  if (prices) {
    const sortedPrices = [...prices].sort(pricesComparator)
    if (sortedPrices && sortedPrices.length > 0) {
      const price = sortedPrices[0]
      return (
        <FormatCurrencyText currency={price.currency} value={price.price} />
      )
    }
  }
  return null
}
export default PriceForQtyOneBadge
