import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { IProductPriceDiscrepancy } from '../../redux/types'
import FormatCurrencyText from '../utils/format-currency-text'
interface Props {
  discrepancies: IProductPriceDiscrepancy[]
}
const PricingDiscrepancies = (props: Props) => {
  const { discrepancies } = props

  return (
    <Box>
      <Typography variant="h1">Pricing Discrepancies</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>UOM</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Seller's Price</TableCell>
            <TableCell>Buyer's Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {discrepancies.map((d: IProductPriceDiscrepancy, i) => (
            <TableRow key={i} hover>
              <TableCell>{d.uom}</TableCell>
              <TableCell>{d.quantity}</TableCell>
              <TableCell>
                <FormatCurrencyText value={d.sellerPrice} />
              </TableCell>
              <TableCell>
                <FormatCurrencyText value={d.buyerPrice} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
export default PricingDiscrepancies
