import {
  Drawer,
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import HistoryIcon from '@material-ui/icons/History'
import PrintIcon from '@material-ui/icons/Print'
import React, { ReactInstance, useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import EditDrawer from '../components/edit-drawer'
import AsnPage from '../components/orders/asns/asn-page'
import OrderAddress from '../components/orders/order-address'
import TabContent from '../components/tab-content'
import FormatCurrencyText from '../components/utils/format-currency-text'
import useCredentials from '../hooks/use-credentials'
import { AppState } from '../redux/store'
import { ComponentWithRoutes } from './types'
import Orders from './orders-list'
import { navigate } from '@reach/router'
import { ListAlt, CloudQueue, CloudUpload } from '@material-ui/icons'
import OrderHistory from '../components/orders/order-history'
import { OrderActions } from '../redux/reducers/order.reducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: '1em 0.5em',
      '@media print': {
        boxShadow: 'none',
      },
    },
    divider: {
      marginTop: '1.5em',
      marginBottom: '1.5em',
    },
    sectionHeader: {
      marginBottom: '.5em',
    },
    total: {
      textAlign: 'right',
      paddingRight: '1em',
    },
    toolbar: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      marginBottom: '2em',
    },
    listDrawer: {
      height: '50vh',
    },
    withPaddings: {
      padding: '0 1em',
    },
    forPrinter: {
      marginTop: '1em',
      '& h5': {
        background: theme.palette.primary.light,
        padding: '.3em',
        margin: 0,
        color: theme.palette.primary.contrastText,
      },
      '@media screen': {
        display: 'none',
      },
    },
    dontPrint: {
      '@media print': {
        display: 'none',
      },
    },
  })
)

type Props = {
  orgid?: string
  orderid?: string
} & ComponentWithRoutes

const Order = (props: Props) => {
  const dispatch = useDispatch()
  const { orgid, orderid } = props
  const order = useSelector((state: AppState) => state.orders.selectedOrder)
  const [tab, setTab] = useState(0)
  const classes = useStyles()
  const credentials = useCredentials()
  const isSeller = credentials.renderForOrgType('SUPPLIER')
  const [openEditDrawer, setOpenEditDrawer] = useState(false)
  const printableOrder = useRef()
  const [openListDrawer, setOpenListDrawer] = useState<boolean>(false)
  const [openHistoryDrawer, setOpenHistoryDrawer] = useState<boolean>(false)

  const handleExport = () => {
    if (order && order.id && credentials.getOrgId())
      dispatch(OrderActions.exportOrder(order.id, credentials.getOrgId()))
  }

  useEffect(() => {
    dispatch(
      OrderActions.getOrder(
        orgid as string,
        orderid as string,
        isSeller ? 'SUPPLIER' : 'UNIVERSITY'
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgid, orderid])

  if (order) {
    return (
      <div className={classes.root}>
        <Paper className={classes.root} ref={printableOrder}>
          <Box displayPrint="none" display="flex" justifyContent="flex-end">
            <Toolbar disableGutters variant="dense" className={classes.toolbar}>
              <Tooltip title="Advance Ship Notice (ASN)">
                <IconButton
                  onClick={() => {
                    setOpenEditDrawer(true)
                  }}
                >
                  <LocalShippingIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Orders List">
                <IconButton onClick={() => setOpenListDrawer(true)}>
                  <ListAlt />
                </IconButton>
              </Tooltip>
              <ReactToPrint
                trigger={() => (
                  <Tooltip title="Print">
                    <IconButton>
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                )}
                content={() =>
                  (printableOrder.current as unknown) as ReactInstance
                }
              />
              <Tooltip title="History">
                <IconButton onClick={() => setOpenHistoryDrawer(true)}>
                  <HistoryIcon />
                </IconButton>
              </Tooltip>
              {credentials.renderForOrgType('SUPPLIER') && order.canExport && (
                <Tooltip title="Export">
                  <IconButton onClick={handleExport}>
                    <CloudUpload />
                  </IconButton>
                </Tooltip>
              )}
            </Toolbar>
          </Box>
          {/* Order Header */}
          <Grid container spacing={2} justify="center">
            <Grid item xs={4}>
              <Typography variant="subtitle2" className={classes.withPaddings}>
                <strong>Order Date:</strong>{' '}
                {order.orderDate &&
                  new Date(order.orderDate).toString().slice(0, 21)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {isSeller ? (
                <Typography variant="subtitle2">
                  <strong>Customer: </strong> {order.buyerName}
                </Typography>
              ) : (
                <Typography variant="subtitle2">
                  <strong>Supplier: </strong> {order.sellerName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" className={classes.withPaddings}>
                <strong>{order.state}</strong>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h2">
                <strong>Order#</strong> {order.orderNo}
              </Typography>
            </Grid>
          </Grid>

          <Box className={classes.forPrinter}>
            <Typography variant="h5">Deliver To</Typography>
          </Box>

          <Tabs
            value={tab}
            onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
              setTab(newValue)
            }
            variant="scrollable"
            scrollButtons="auto"
            className={classes.dontPrint}
          >
            <Tab value={0} label="Deliver To" />
            <Tab value={1} label="Invoice To" />
            <Tab value={2} label="Contact" />
          </Tabs>
          <TabContent show={tab === 1}>
            <Box>
              <OrderAddress orderAddress={order.billTo ? order.billTo : {}} />
            </Box>
          </TabContent>
          <TabContent show={tab === 0}>
            <Box>
              <OrderAddress orderAddress={order.shipTo ? order.shipTo : {}} />
            </Box>
          </TabContent>
          <TabContent show={tab === 2}>
            <Box>
              <OrderAddress orderAddress={order.contact ? order.contact : {}} />
            </Box>
          </TabContent>

          {/* Order Lines */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">#</TableCell>
                    <TableCell>Product Code</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>UOM</TableCell>
                    <TableCell>Price (excl)</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Line Total (excl)</TableCell>
                    <TableCell>Comments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.lines &&
                    order.lines.map(l => (
                      <TableRow key={'line_' + l.id} hover={true}>
                        <TableCell padding="checkbox">{l.number}</TableCell>
                        <TableCell align="left">
                          {l.supplierProductCode}
                        </TableCell>
                        <TableCell align="left">{l.description}</TableCell>
                        <TableCell align="right">
                          <NumberFormat
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            displayType="text"
                            decimalScale={2}
                            value={l.quantity}
                          />
                        </TableCell>
                        <TableCell align="left">{l.uom}</TableCell>
                        <TableCell align="right">
                          <FormatCurrencyText
                            value={l.unitPrice}
                            currency={l.priceCurreny}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormatCurrencyText
                            value={l.taxLine}
                            currency={l.taxLineCurrency}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <FormatCurrencyText
                            value={
                              (l.quantity ? l.quantity : 0) *
                              (l.unitPrice ? l.unitPrice : 0)
                            }
                            currency={l.priceCurreny}
                          />
                        </TableCell>
                        <TableCell>{l.lineComment}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          {/* Order footer */}
          <Grid container spacing={2}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                alignContent="flex-end"
                spacing={1}
              >
                <Grid item className={classes.total}>
                  <strong>Total Tax: </strong>
                  <FormatCurrencyText
                    value={order.taxTotal}
                    currency={order.taxTotalCurrency}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction="column"
                alignContent="flex-end"
                spacing={1}
              >
                <Grid item>
                  <div className={classes.total}>
                    <strong>Subtotal: </strong>
                    <FormatCurrencyText
                      value={order.orderTotalExtTax}
                      currency={order.orderTotalCurrency}
                    />
                  </div>
                </Grid>
                <Grid item className={classes.total}>
                  <div className={classes.total}>
                    <strong>Total: </strong>
                    <FormatCurrencyText
                      value={
                        (order.taxTotal ? order.taxTotal : 0) +
                        (order.orderTotalExtTax ? order.orderTotalExtTax : 0)
                      }
                      currency={order.orderTotalCurrency}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box className={classes.forPrinter}>
            <Typography variant="h5">Invoice To</Typography>
            <OrderAddress orderAddress={order.billTo ? order.billTo : {}} />
          </Box>
        </Paper>
        <EditDrawer
          name="Advance Ship Notice (ASN)"
          open={openEditDrawer}
          handleClose={() => setOpenEditDrawer(false)}
        >
          {openEditDrawer && (
            <AsnPage orgid={orgid as string} buyerId={order.buyerId as string} orderNumber={order.orderNo as string} />
          )}
        </EditDrawer>
        <Drawer
          anchor="bottom"
          open={openListDrawer}
          classes={{
            paper: classes.listDrawer,
          }}
          onClose={() => setOpenListDrawer(false)}
        >
          <Orders
            path="/"
            navigate={(path: any) => navigate(`/app/orders/${path}`)}
            selectedLineId={props.orderid}
            keepList={true}
          />
        </Drawer>
        <Drawer
          anchor="bottom"
          open={openHistoryDrawer}
          classes={{
            paper: classes.listDrawer,
          }}
          onClose={() => setOpenHistoryDrawer(false)}
        >
          <OrderHistory orgid={orgid} orderid={orderid} isSeller={isSeller} />
        </Drawer>
      </div>
    )
  }
  return <Typography>Unknown Order</Typography>
}

export default Order
