import React from 'react'
import { IProductUOM } from '../../redux/types'

interface Props {
  mapping?: IProductUOM
}
const UomMappingBadge = ({ mapping }: Props) => {
  const convert = () => {
    if (mapping) {
      return Object.entries(mapping).reduce((acc: string, [key, val]) => {
        return acc + val.description
        // return acc + ' [ ' + key + ':' + val.globalUom + ' ] '
      }, '')
    }
    return ''
  }
  return <>{convert()}</>
}

export default UomMappingBadge
