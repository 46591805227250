import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'sticky',
      top: '1.8em',
      overflow: 'auto',
      height: 'calc(100vh - 6em)',
    },
  })
)

interface Props {
  children: React.ReactNode
  className?: string
}
const FloatingCardPanel = (props: Props) => {
  const classes = useStyles()

  return (
    <Paper className={clsx(classes.root, props.className)}>
      {props.children}
    </Paper>
  )
}
export default FloatingCardPanel
