import React from 'react'
import { Box, Typography } from '@material-ui/core'
interface Props {
  epoch?: number
}
const ExportedDateTimeWidget = (props: Props) => {
  const { epoch } = props

  if (epoch) {
    const d = new Date(epoch)
    const date = d.toLocaleDateString(window.navigator.language)
    // const date = d.toDateString()
    const time = d.toLocaleTimeString(window.navigator.language)
    return (
      <Box>
        <Typography>{date}</Typography>
        <Typography variant="caption">{time}</Typography>
      </Box>
    )
  }
  return null
}
export default ExportedDateTimeWidget
