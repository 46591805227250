import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import Categories from '../components/preferences/categories'
import TaxCodes from '../components/preferences/tax-codes'
import { changeTitle } from '../redux/actions/header.actions'
import { ComponentWithRoutes } from './types'
import PropertyBox from '../components/preferences/property'
import OtherOrgProperties from '../components/accounts/properties/other-org-properties'
import useCredentials from '../hooks/use-credentials'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'grid',
      gridGap: theme.spacing(1),
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridAutoRows: 'auto',
    },
    categories: {
      gridColumn: 'auto / span 2',
      // gridRow: 'auto/span 2',
      [theme.breakpoints.down('sm')]: {
        gridColumn: 'span 3',
      },
    },
    otherProps: {
      gridColumn: 'auto / span 3',
      padding: 0,
      margin: 0,
    },
  })
)

interface Props {}
const PreferencesPage = (props: Props & ComponentWithRoutes) => {
  const classes = useStyles()
  const [title] = React.useState<string>('Preferences')
  const dispatch = useDispatch()
  const credentials = useCredentials()

  React.useEffect(() => {
    dispatch(changeTitle(title))
    document.title = title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes.root}>
      {/* Properties for Seller */}
      {credentials.renderForOrgType('SUPPLIER') && (
        <PropertyBox
          title="List of ASN Recipients"
          name="ASN_CONTACT_EMAIL_LIST"
          subheader="Comma-separated list of emails"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+(,?\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+)*$"
        />
      )}
      {credentials.renderForOrgType('UNIVERSITY') && (
        <Categories className={classes.categories} />
      )}
      {credentials.renderForOrgType('UNIVERSITY') && <TaxCodes />}
      <OtherOrgProperties className={classes.otherProps} />
    </Box>
  )
}
export default PreferencesPage
