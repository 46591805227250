import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { replaceUOMMapping } from '../../redux/actions/uom.actions'
import { IGlobalUOM, IUomMapping } from '../../redux/types'
import StickyActionsBar from '../sticky-actions-bar'
import { UIActions } from '../../redux/reducers/ui.reducer'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridGap: theme.spacing(2),
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: 'auto',
      marginBottom: '.5em',
      padding: '1em',
    },
    globPane: {
      gridColumn: '1/2',
    },
    mapPane: {
      gridColumn: '2/3',
      alignSelf: 'center',
    },
  })
)
interface Props {
  orgId: string
}
const EditUomMapping = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { orgId } = props
  const [globalUom, setGlobalUom] = React.useState<IGlobalUOM | null>(null)
  const [uomMapping, setUomMapping] = React.useState<IUomMapping>({})

  React.useEffect(() => {
    // ! move to redux only if necessary
    fetchUOM()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId])

  const fetchUOM = async () => {
    const url = process.env.REACT_APP_API_UOM as string
    const res = await fetch(url)
    const guom: IGlobalUOM = await res.json()
    setGlobalUom(guom)

    // TODO fetch saved mapping here

    const mapRes = await fetch(
      `${process.env.REACT_APP_API_SELLER}/${orgId}/uom`
    )
    const savedMapping = mapRes.status === 200 ? await mapRes.json() : {}
    const mapping: IUomMapping = Object.keys(guom).reduce(
      (acc: IUomMapping, key: string) => {
        return { ...acc, [key]: savedMapping[key] || '' }
      },
      {}
    )
    setUomMapping(mapping)
  }

  const disableButton = () => {
    return !Object.values(uomMapping).some(v => v !== '')
  }

  const handleSave = () => {
    dispatch(UIActions.uiSetEditDrawerBusy(true))
    dispatch(replaceUOMMapping(uomMapping, orgId))
  }

  return (
    <>
      <StickyActionsBar top="6em" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          disabled={disableButton()}
          onClick={handleSave}
        >
          Save Mapping
        </Button>
      </StickyActionsBar>
      {globalUom &&
        Object.keys(globalUom).map(k => {
          const gu = globalUom[k]
          return (
            <Paper className={classes.root} elevation={1} key={k}>
              <Box className={classes.globPane}>
                <Typography variant="h1">{k}</Typography>
                <Typography variant="subtitle1">{gu.description}</Typography>
                {gu.variations && gu.variations.length > 0 && (
                  <Typography variant="caption">
                    variations: [ {gu.variations.join(', ')} ]
                  </Typography>
                )}
              </Box>
              <Box className={classes.mapPane}>
                <TextField
                  variant="outlined"
                  name={k}
                  value={uomMapping[k] || ''}
                  onChange={e => {
                    const v = e.target.value
                    const nm = { ...uomMapping, [k]: v }
                    setUomMapping(nm)
                  }}
                />
              </Box>
            </Paper>
          )
        })}
    </>
  )
}
export default EditUomMapping
