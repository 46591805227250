import {
  Box,
  Button,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { acceptTermsOfUse } from '../redux/actions/profile.actions'
import { AppState } from '../redux/store'
import { IPolicy } from '../redux/types'
import { ComponentWithRoutes } from '../pages/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
    paper: {
      padding: '1em',
    },
    title: {
      textAlign: 'center',
      padding: theme.spacing(3, 2),
    },
    content: {
      padding: theme.spacing(3, 2),
      width: '45vw',
      height: '60vh',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      overflowY: 'scroll',
    },
  })
)

type Props = {
  productId?: string
  sellerId?: string
  buyerId?: string
} & ComponentWithRoutes

const TermsOfUse = (props: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [isTermsAceepted, setIsTermsAceepted] = useState(false)
  const profile = useSelector((state: AppState) => state.profile)

  const handleAcceptTems = () => {
    if (isTermsAceepted) {
      const nPolicy = { ...profile.policy, ...{ state: 'ACTIVE' } }
      dispatch(acceptTermsOfUse(nPolicy as IPolicy))
    }
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h1" className={classes.title}>
          VENDOR EXCHANGE TERMS AND CONDITIONS
        </Typography>
        <Grid container justify="center">
          <Grid item>
            <Box className={classes.content}>
              <Typography>Terms of Use content.</Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="center" direction="row">
          <Grid item>
            <form>
              {profile.policy && profile.policy.state === 'TERMS_OF_USE' && (
                <Box marginTop="1em">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isTermsAceepted}
                        onChange={() => setIsTermsAceepted(!isTermsAceepted)}
                        color="primary"
                      />
                    }
                    label="I have read and accept the Terms of Use"
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={!isTermsAceepted}
                    onClick={handleAcceptTems}
                  >
                    Save
                  </Button>
                </Box>
              )}
            </form>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default TermsOfUse
