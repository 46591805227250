import React from 'react'
import { IOtherOrgProperty } from '../../../redux/types'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../../redux/store'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'
import { useInView } from 'react-intersection-observer'

interface Props {
  myId: string
  otherId: string
  actions?: (property: IOtherOrgProperty) => React.ReactNode
}
const OtherOrgPropertiesList = (props: Props) => {
  const { actions, myId, otherId } = props
  const list = useSelector(
    (state: AppState) => state.accountProperties.otherOrgPropertyList
  )
  const [limit] = React.useState(20)
  const dispatch = useDispatch()
  const [ref, inView] = useInView()
  const getInvViewRef = (position: number) => {
    if (position === list.length && list.length >= limit) {
      return ref
    }
    return null
  }

  const fetchLines = (more: boolean, offset: number) => {
    dispatch(
      AccountPropsActions.fetchOtherProperties(myId, {
        limit: limit,
        offset: offset,
        more: more,
        otherOrgId: otherId,
      })
    )
  }

  React.useEffect(() => {
    fetchLines(true, list.length)
  }, [inView])

  React.useEffect(() => {
    fetchLines(false, 0)
    return () => {
      dispatch(AccountPropsActions.populateOtherList([], false))
    }
  }, [otherId, myId])

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Property</TableCell>
          <TableCell>Value</TableCell>
          <TableCell padding="checkbox"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((p, i) => (
          <TableRow hover key={p.property + '_' + i} ref={getInvViewRef(i + 1)}>
            <TableCell>{p.property}</TableCell>
            <TableCell>{p.value}</TableCell>
            <TableCell padding="checkbox">{actions && actions(p)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
export default OtherOrgPropertiesList
