import { createStyles, Theme, Typography } from '@material-ui/core'
import EmailIcon from '@material-ui/icons/Email'
import ForumIcon from '@material-ui/icons/Forum'
import LinkIcon from '@material-ui/icons/Link'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ForgotPassword from '../components/login/forgot-password'
import Login from '../components/login/login'
import { changeTitle } from '../redux/actions/header.actions'
import logo from './../logo.svg'

import velogo from '../velogo.svg'
import velogo_dark from '../velogo_darkmode.svg'
import { AppState } from '../redux/store'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      backgroundImage: 'url(/login.jpg)',
      backgroundSize: 'cover',
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      gridTemplateRows: '100vh',
      '& a': {
        color: '#3EA3DB',
        textDecoration: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 100vh',
      },
    },
    picturePane: {
      gridColumn: '1/2',
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      // padding: '2em',
      [theme.breakpoints.down('sm')]: {
        gridRow: '2/3',
      },
    },
    formPane: {
      gridColumn: '2/3',
      display: 'grid',
      height: '100vh',
      background:
        theme.palette.type === 'dark'
          ? theme.palette.background.paper
          : 'linear-gradient(135deg, rgba(255,255,255,0.75) 0%, rgba(255,255,255,0.99) 61%, rgba(255,255,255,1) 63%);',

      [theme.breakpoints.down('sm')]: {
        gridColumn: '1/2',
        gridRow: '1/2',
      },
    },
    twoColumn: {
      columnCount: 2,
      background: 'rgba(0,0,0,0.5)',
      padding: '1em',
      marginTop: '2em',
      color: theme.palette.common.white,
    },
    header: {
      marginBottom: '2em',
    },
    list: {
      width: '80%',
      '& li': {
        fontSize: '1.1em',
        marginBottom: '1em',
      },
    },
    tagline: {
      fontSize: '1.3em',
      marginTop: '2em',
    },
    sentence: {
      color: '#3EA3DB',
      background: 'rgba(255,255,255,0.5)',
      // borderRadius: '2px',
      padding: '1em',
      fontSize: '1.5em',
      '& span': {
        fontWeight: 'bold',
      },
    },
    logo: {
      width: '70%',
      margin: '0 auto 1.7em auto',
      display: 'block',
    },
    links: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '1em',
      alignSelf: 'end',
      color: '#3EA3DB',
      '& li': {
        display: 'flex',
        listStyle: 'none',
        marginLeft: 0,
        '& a': {
          display: 'inline-block',
          marginLeft: '.5em',
        },
      },
    },
  })
)

// type ILoginOption = 'LOGIN' | 'FORGET_PASSWORD'

const LoginPage = () => {
  const [title] = useState('Vendor Exchange Login')
  const [loginOption, setLoginOption] = useState('LOGIN')

  const palette = useSelector((state: AppState) => state.ui.palette)
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    document.title = title
    dispatch(changeTitle(title))
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.picturePane}>
        <Typography className={classes.sentence}>
          <span>Automation Intelligence</span> for Internal Catalogues, EDI and
          Accounts Payable
        </Typography>
      </div>
      <div className={classes.formPane}>
        {loginOption === 'LOGIN' && (
          <>
            <Login
              handleForgotPassword={() => setLoginOption('FORGET_PASSWORD')}
            />
          </>
        )}
        {loginOption === 'FORGET_PASSWORD' && (
          <ForgotPassword handleToLogin={() => setLoginOption('LOGIN')} />
        )}

        <div className={classes.links}>
          <div>
            {palette.type !== 'dark' && (
              <img alt="Logo" src={velogo} className={classes.logo} />
            )}
            {palette.type === 'dark' && (
              <img alt="Logo" src={velogo_dark} className={classes.logo} />
            )}
          </div>
          <ul>
            <li>
              <LinkIcon />
              <a href="https://www.portalink.com" target="_new">
                www.portalink.com
              </a>
            </li>
            <li>
              <ForumIcon />
              <a href="https://community.vendor.exchange" target="_new">
                community forum
              </a>
            </li>
            <li>
              <EmailIcon />
              <a href="mailto:info@portalink.com">info@portalink.com</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
export default LoginPage
