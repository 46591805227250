import React, { useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../redux/store'
import { OrderActions } from '../../redux/reducers/order.reducer'

type Props = {
  orgid?: string
  orderid?: string
  isSeller?: boolean
}

const OrderHistory = (props: Props) => {
  const { orgid, orderid, isSeller } = props
  const history = useSelector((state: AppState) => state.orders.selectedHistory)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      OrderActions.getOrderHistory(
        orderid as string,
        orgid as string,
        isSeller ? 'SUPPLIER' : 'UNIVERSITY'
      )
    )
  }, [orgid, orderid])

  return (
    <Box ml={2} mt={2}>
      <Typography variant="h2">
        <strong>Order History</strong>
      </Typography>
      {history &&
        history.map((h, i) => (
          <Box m={2} key={i}>
            <div>
              <strong>Date: </strong>
              {h.date && new Date(h.date).toString().slice(0, 21)}
            </div>
            <div>
              <strong>Action: </strong>
              {h.action}
            </div>
            <div>
              <strong>By: </strong>
              {h.user}
            </div>
          </Box>
        ))}
    </Box>
  )
}

export default OrderHistory
