import {
  Box,
  Button,
  createStyles,
  Drawer,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Chip,
} from '@material-ui/core'
import { Error, CloudDoneOutlined } from '@material-ui/icons'
import React from 'react'
import { IInvoice } from '../../redux/types/invoice.types'
import FormatCurrencyText from '../utils/format-currency-text'
import InvoiceErrorsView from './invoice-errors-view'
import InvoiceListItems from './invoice-list-items'
import RouterLink from '../RouterLink'
import useCredentials from '../../hooks/use-credentials'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: '1fr  1fr',
      gridTemplateRows: 'auto',
      gridTemplateAreas: `
        "title                 sender"
        "createdDate           reference"
        "errors                invstatus"
        "items                 items"
        "charges               totals"
        "comments                 ."
        "actions               actions"
      `,
      gridGap: theme.spacing(1),
    },

    sender: {
      gridArea: 'sender',
    },
    title: {
      gridArea: 'title',
      alignSelf: 'end',
    },
    createdDate: {
      marginTop: '2em',
      gridArea: 'createdDate',
    },
    reference: {
      marginTop: '2em',
      gridArea: 'reference',
    },
    errors: {
      gridArea: 'errors',
    },
    invstatus: {
      gridArea: 'invstatus',
    },
    errorsButton: {},
    items: {
      gridArea: 'items',
      margin: '3em 0',
    },
    errorsPane: {
      width: '45vw',
    },
    charges: {
      gridArea: 'charges',
    },
    totals: {
      gridArea: 'totals',
    },
    comments: {
      gridArea: 'comments',
    },
    actions: {
      gridArea: 'actions',
    },
    exportedChip: {
      marginBottom: theme.spacing(1),
      float: 'right',
    },
  })
)

interface Props {
  invoice: IInvoice
}
const InvoiceDataView = ({ invoice }: Props) => {
  const classes = useStyles()
  const credentials = useCredentials()
  const [errorsPane, seterrorsPane] = React.useState<boolean>(false)
  return (
    <Box className={classes.root}>
      <Box className={classes.sender}>
        {invoice.exported && (
          <Chip
            label="Exported"
            className={classes.exportedChip}
            color="primary"
            icon={<CloudDoneOutlined />}
          />
        )}
        <Typography variant="caption">Sender</Typography>
        <Typography>{invoice.supplierName}</Typography>
        {invoice.supplierCode && (
          <Typography variant="subtitle2">
            Account Code: {invoice.supplierCode}
          </Typography>
        )}
      </Box>

      <Box className={classes.title}>
        <Typography variant="h2">{invoice.title}</Typography>

        <Typography variant="subtitle2">
          No.: {invoice.document ? invoice.document.documentId : ''}
        </Typography>
      </Box>

      <Box className={classes.createdDate}>
        <Typography variant="caption">Created</Typography>
        <Typography>
          {invoice.document &&
            invoice.document.dateCreated &&
            new Date(invoice.document.dateCreated).toDateString()}
        </Typography>
      </Box>

      {invoice.document && invoice.document.validationErrors && (
        <Box className={classes.errors}>
          <Button
            className={classes.errorsButton}
            variant="contained"
            color="secondary"
            startIcon={<Error />}
            onClick={() => seterrorsPane((prev) => !prev)}
          >
            Validation Errors detected!
          </Button>
        </Box>
      )}

      <Box className={classes.invstatus}>
        <Typography variant="caption">Invoice Status</Typography>
        <Typography>{invoice.veStatus}</Typography>
      </Box>

      {!invoice.document && (
        <Box className={classes.errors}>
          <Button
            className={classes.errorsButton}
            variant="contained"
            color="secondary"
            startIcon={<Error />}
            onClick={() => {}}
          >
            Extraction Error
          </Button>
        </Box>
      )}

      <Box className={classes.reference}>
        <Typography variant="caption">Order Number</Typography>
        <Typography variant="subtitle2" display="block">
          {invoice.document && (
            <RouterLink
              to={`/app/orders/${credentials.getOrgId()}/order/${
                invoice.document.referenceSystemId
              }`}
            >
              {invoice.document.referenceId}
            </RouterLink>
          )}
        </Typography>
      </Box>

      {invoice.document && invoice.document.lineItems && (
        <Box className={classes.items}>
          <InvoiceListItems items={invoice.document.lineItems} />
        </Box>
      )}

      <Box className={classes.charges}>
        <Grid container>
          <Grid item md>
            <Typography variant="subtitle2">Freight</Typography>
          </Grid>
          <Grid item md>
            <Typography>
              {invoice.document && invoice.document.freight && (
                <FormatCurrencyText
                  value={invoice.document.freight}
                  currency={invoice.document && invoice.document.currency + ' '}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md>
            <Typography variant="subtitle2">Additional charges</Typography>
          </Grid>
          <Grid item md>
            <Typography>
              {invoice.document && invoice.document.additionalCharges && (
                <FormatCurrencyText
                  value={invoice.document.additionalCharges}
                  currency={invoice.document && invoice.document.currency + ' '}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.totals}>
        <Grid container>
          <Grid item md>
            <Typography variant="subtitle2">Subtotal</Typography>
          </Grid>
          <Grid item md>
            <Typography>
              {invoice.document && invoice.document.subtotal && (
                <FormatCurrencyText
                  value={invoice.document.subtotal}
                  currency={invoice.document && invoice.document.currency + ' '}
                />
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md>
            <Typography variant="subtitle2">Discount</Typography>
          </Grid>
          <Grid item md>
            <Typography>
              {invoice.document && invoice.document.discount && (
                <FormatCurrencyText
                  value={invoice.document.discount}
                  currency={invoice.document && invoice.document.currency + ' '}
                />
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md>
            <Typography variant="subtitle2">TAX</Typography>
          </Grid>
          <Grid item md>
            <Typography>
              {invoice.document && invoice.document.totalTax && (
                <FormatCurrencyText
                  value={invoice.document.totalTax}
                  currency={invoice.document && invoice.document.currency + ' '}
                />
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md>
            <Typography variant="subtitle2">Total</Typography>
          </Grid>
          <Grid item md>
            <Typography>
              {invoice.document && invoice.document.totalValue && (
                <FormatCurrencyText
                  value={invoice.document.totalValue}
                  currency={invoice.document && invoice.document.currency}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.comments}>
        <Typography variant="caption">Comments:</Typography>
        <Typography>{invoice.document && invoice.document.comments}</Typography>
      </Box>
      <Box className={classes.actions}></Box>

      {invoice.document && invoice.document.validationErrors && (
        <Drawer
          anchor="right"
          open={errorsPane}
          onClose={() => seterrorsPane(false)}
          classes={{
            paper: classes.errorsPane,
          }}
        >
          <InvoiceErrorsView errors={invoice.document.validationErrors} />
        </Drawer>
      )}
    </Box>
  )
}

export default InvoiceDataView
