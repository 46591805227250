import React from 'react'
import {
  createStyles,
  makeStyles,
  Theme,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Box,
  CardActions,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import { AppState } from '../../redux/store'
import { UIActions } from '../../redux/reducers/ui.reducer'
import { CategoryActions } from '../../redux/reducers/category.reducer'
import { broadcastAjaxErrors } from '../../redux/epics/utils'
import { AccountTreeOutlined, CloudUpload } from '@material-ui/icons'
import FileUpload from '../filesystem/file-upload'
import CategoryTree from './../categories/category-tree'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& h4': {
        marginBottom: theme.spacing(2),
      },
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      //   overflow: 'auto',
    },
  })
)
interface Props {
  className?: string
}
const Categories = (props: Props) => {
  const classes = useStyles()
  const { className } = props
  const dispatch = useDispatch()
  const credentials = useCredentials()

  const loading = useSelector(
    (state: AppState) => state.ui.fetching['categories']
  )
  const categories = useSelector((state: AppState) => state.categoryTree)
  const [fetchingUrl, setfetchingUrl] = React.useState<boolean>(false)
  const [uploadUrl, setuploadUrl] = React.useState<string>('')

  React.useEffect(() => {
    const id = credentials.getOrgId()
    dispatch(UIActions.uiSetFetching('categories', true))
    if (id) {
      dispatch(CategoryActions.getCategoryTree(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.getOrgId()])

  const requestUpdate = async () => {
    setfetchingUrl(true)
    const url = `${
      process.env.REACT_APP_API_BUYER
    }/${credentials.getOrgId()}/category-tree/action/upload-url`

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (res.ok) {
      const j = await res.json()
      if (j.uploadUrl) {
        setuploadUrl(j.uploadUrl)
      }
    } else {
      dispatch(
        broadcastAjaxErrors('Something went wrong, please try again later.')
      )
    }
    setfetchingUrl(false)
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        title="Available Categories"
        subheader="Click to expand"
        avatar={
          <Avatar>
            <AccountTreeOutlined />
          </Avatar>
        }
      ></CardHeader>
      <CardContent className={classes.content}>
        <CategoryTree categories={categories} />
        {uploadUrl !== '' && (
          <Box marginTop="1em">
            <FileUpload
              url={uploadUrl}
              method="PUT"
              callback={() => {
                setTimeout(() => {
                  const id = credentials.getOrgId()
                  if (id) {
                    dispatch(CategoryActions.getCategoryTree(id))
                  }
                  setuploadUrl('')
                }, 3000)
              }}
            />
          </Box>
        )}
      </CardContent>
      <CardActions>
        <Tooltip title="Request Update">
          <div>
            <IconButton onClick={requestUpdate} disabled={fetchingUrl}>
              {fetchingUrl && <CircularProgress size={24} />}
              {!fetchingUrl && <CloudUpload />}
            </IconButton>
          </div>
        </Tooltip>
      </CardActions>
    </Card>
  )
}
export default Categories
