import { Epic } from 'redux-observable'
import { filter, tap, map } from 'rxjs/operators'
import { UIActions } from '../reducers/ui.reducer'
import { broadcastAjaxSuccess } from './utils'

const switchRenderForEpic: Epic = action$ =>
  action$.pipe(
    filter(UIActions.uiSwapRenderFor.match),
    tap(action => {
      if (action.payload)
        localStorage.setItem('renderFor', JSON.stringify(action.payload))
    }),
    map(action => {
      return broadcastAjaxSuccess('Identity assumed.')
    })
  )

export const uiEpics = [switchRenderForEpic]
