import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/styles'
import React from 'react'
import { IInvoiceLineItem } from '../../redux/types/invoice.types'
import FormatCurrencyText from '../utils/format-currency-text'

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  })
)(TableCell)

interface Props {
  items: IInvoiceLineItem[]
}
const InvoiceListItems = ({ items }: Props) => {
  const comparator = (a: IInvoiceLineItem, b: IInvoiceLineItem) => {
    if (a.sequence && b.sequence) {
      if (a.sequence > b.sequence) return 1
      if (a.sequence < b.sequence) return -1
    }
    return 0
  }
  const sortedItems = [...items].sort(comparator)
  return (
    <Table size="small" stickyHeader>
      <TableHead>
        <TableRow>
          <StyledTableCell colSpan={3} align="center">
            Product
          </StyledTableCell>
          <StyledTableCell colSpan={2} align="center">
            Quantity
          </StyledTableCell>
          <StyledTableCell></StyledTableCell>
          <StyledTableCell colSpan={4} align="center">
            Pricing
          </StyledTableCell>
        </TableRow>
        <TableRow>
          <TableCell>#</TableCell>
          <TableCell>Code</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Invoiced</TableCell>
          <TableCell>UOM</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Discount</TableCell>
          <TableCell>TAX</TableCell>
          <TableCell>Gross Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedItems.map((si: IInvoiceLineItem, i) => (
          <TableRow key={si.id} hover>
            <TableCell>{si.sequence || i + 1}</TableCell>
            <TableCell>{si.code}</TableCell>
            <TableCell>{si.description}</TableCell>
            <TableCell>{si.qtyShipped}</TableCell>
            <TableCell>{si.uom}</TableCell>
            <TableCell>
              <FormatCurrencyText value={si.unitPrice} />
            </TableCell>
            <TableCell>
              <FormatCurrencyText value={si.discount} />
            </TableCell>
            <TableCell>
              <FormatCurrencyText value={si.tax} />
            </TableCell>
            <TableCell>
              <FormatCurrencyText value={si.grossLineTotal} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default InvoiceListItems
