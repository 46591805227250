import { TCountry } from '../types'

export const CountriesDic: TCountry[] = [
  {
    CountryNo: 53,
    Name: 'Afghanistan',
    Code: 'AF',
    RegionNo: 0
  },
  {
    CountryNo: 2,
    Name: 'Africa',
    Code: '',
    RegionNo: 1
  },
  {
    CountryNo: 54,
    Name: 'Åland Islands',
    Code: 'AX',
    RegionNo: 0
  },
  {
    CountryNo: 55,
    Name: 'Albania',
    Code: 'AL',
    RegionNo: 0
  },
  {
    CountryNo: 56,
    Name: 'Algeria',
    Code: 'DZ',
    RegionNo: 0
  },
  {
    CountryNo: 57,
    Name: 'American Samoa',
    Code: 'AS',
    RegionNo: 0
  },
  {
    CountryNo: 58,
    Name: 'Andorra',
    Code: 'AD',
    RegionNo: 0
  },
  {
    CountryNo: 59,
    Name: 'Angola',
    Code: 'AO',
    RegionNo: 0
  },
  {
    CountryNo: 60,
    Name: 'Anguilla',
    Code: 'AI',
    RegionNo: 0
  },
  {
    CountryNo: 61,
    Name: 'Antarctica',
    Code: 'AQ',
    RegionNo: 0
  },
  {
    CountryNo: 62,
    Name: 'Antigua and Barbuda',
    Code: 'AG',
    RegionNo: 0
  },
  {
    CountryNo: 63,
    Name: 'Argentina',
    Code: 'AR',
    RegionNo: 0
  },
  {
    CountryNo: 64,
    Name: 'Armenia',
    Code: 'AM',
    RegionNo: 0
  },
  {
    CountryNo: 65,
    Name: 'Aruba',
    Code: 'AW',
    RegionNo: 0
  },
  {
    CountryNo: 3,
    Name: 'Asia',
    Code: '',
    RegionNo: 1
  },
  {
    CountryNo: 1,
    Name: 'Australia',
    Code: 'AU',
    RegionNo: 1
  },
  {
    CountryNo: 8,
    Name: 'Austria',
    Code: 'AT',
    RegionNo: 2
  },
  {
    CountryNo: 66,
    Name: 'Azerbaijan',
    Code: 'AZ',
    RegionNo: 0
  },
  {
    CountryNo: 67,
    Name: 'Bahamas',
    Code: 'BS',
    RegionNo: 0
  },
  {
    CountryNo: 68,
    Name: 'Bahrain',
    Code: 'BH',
    RegionNo: 0
  },
  {
    CountryNo: 69,
    Name: 'Bangladesh',
    Code: 'BD',
    RegionNo: 0
  },
  {
    CountryNo: 70,
    Name: 'Barbados',
    Code: 'BB',
    RegionNo: 0
  },
  {
    CountryNo: 71,
    Name: 'Belarus',
    Code: 'BY',
    RegionNo: 0
  },
  {
    CountryNo: 42,
    Name: 'Belgium',
    Code: 'BE',
    RegionNo: 2
  },
  {
    CountryNo: 72,
    Name: 'Belize',
    Code: 'BZ',
    RegionNo: 0
  },
  {
    CountryNo: 73,
    Name: 'Benin',
    Code: 'BJ',
    RegionNo: 0
  },
  {
    CountryNo: 74,
    Name: 'Bermuda',
    Code: 'BM',
    RegionNo: 0
  },
  {
    CountryNo: 75,
    Name: 'Bhutan',
    Code: 'BT',
    RegionNo: 0
  },
  {
    CountryNo: 76,
    Name: 'Bolivia Plurinational State of',
    Code: 'BO',
    RegionNo: 0
  },
  {
    CountryNo: 77,
    Name: 'Bonaire Sint Eustatius and Saba',
    Code: 'BQ',
    RegionNo: 0
  },
  {
    CountryNo: 78,
    Name: 'Bosnia and Herzegovina',
    Code: 'BA',
    RegionNo: 0
  },
  {
    CountryNo: 79,
    Name: 'Botswana',
    Code: 'BW',
    RegionNo: 0
  },
  {
    CountryNo: 80,
    Name: 'Bouvet Island',
    Code: 'BV',
    RegionNo: 0
  },
  {
    CountryNo: 21,
    Name: 'Brazil',
    Code: 'BR',
    RegionNo: 3
  },
  {
    CountryNo: 81,
    Name: 'British Indian Ocean Territory',
    Code: 'IO',
    RegionNo: 0
  },
  {
    CountryNo: 36,
    Name: 'Brunei',
    Code: 'BN',
    RegionNo: 1
  },
  {
    CountryNo: 82,
    Name: 'Bulgaria',
    Code: 'BG',
    RegionNo: 0
  },
  {
    CountryNo: 83,
    Name: 'Burkina Faso',
    Code: 'BF',
    RegionNo: 0
  },
  {
    CountryNo: 84,
    Name: 'Burundi',
    Code: 'BI',
    RegionNo: 0
  },
  {
    CountryNo: 85,
    Name: 'Cambodia',
    Code: 'KH',
    RegionNo: 0
  },
  {
    CountryNo: 86,
    Name: 'Cameroon',
    Code: 'CM',
    RegionNo: 0
  },
  {
    CountryNo: 22,
    Name: 'Canada',
    Code: 'CA',
    RegionNo: 3
  },
  {
    CountryNo: 87,
    Name: 'Cape Verde',
    Code: 'CV',
    RegionNo: 0
  },
  {
    CountryNo: 88,
    Name: 'Cayman Islands',
    Code: 'KY',
    RegionNo: 0
  },
  {
    CountryNo: 89,
    Name: 'Central African Republic',
    Code: 'CF',
    RegionNo: 0
  },
  {
    CountryNo: 90,
    Name: 'Chad',
    Code: 'TD',
    RegionNo: 0
  },
  {
    CountryNo: 23,
    Name: 'Chile',
    Code: 'CL',
    RegionNo: 3
  },
  {
    CountryNo: 24,
    Name: 'China',
    Code: 'CN',
    RegionNo: 1
  },
  {
    CountryNo: 91,
    Name: 'Christmas Island',
    Code: 'CX',
    RegionNo: 0
  },
  {
    CountryNo: 92,
    Name: 'Cocos (Keeling) Islands',
    Code: 'CC',
    RegionNo: 0
  },
  {
    CountryNo: 93,
    Name: 'Colombia',
    Code: 'CO',
    RegionNo: 0
  },
  {
    CountryNo: 94,
    Name: 'Comoros',
    Code: 'KM',
    RegionNo: 0
  },
  {
    CountryNo: 95,
    Name: 'Congo',
    Code: 'CG',
    RegionNo: 0
  },
  {
    CountryNo: 96,
    Name: 'Congo, the Democratic Republic of the',
    Code: 'CD',
    RegionNo: 0
  },
  {
    CountryNo: 43,
    Name: 'Cook Islands',
    Code: 'CK',
    RegionNo: 2
  },
  {
    CountryNo: 97,
    Name: 'Costa Rica',
    Code: 'CR',
    RegionNo: 0
  },
  {
    CountryNo: 98,
    Name: "Côte d'Ivoire",
    Code: 'CI',
    RegionNo: 0
  },
  {
    CountryNo: 99,
    Name: 'Croatia',
    Code: 'HR',
    RegionNo: 0
  },
  {
    CountryNo: 100,
    Name: 'Cuba',
    Code: 'CU',
    RegionNo: 0
  },
  {
    CountryNo: 101,
    Name: 'Curaçao',
    Code: 'CW',
    RegionNo: 0
  },
  {
    CountryNo: 102,
    Name: 'Cyprus',
    Code: 'CY',
    RegionNo: 0
  },
  {
    CountryNo: 10,
    Name: 'Czech Republic',
    Code: 'CZ',
    RegionNo: 2
  },
  {
    CountryNo: 40,
    Name: 'Denmark',
    Code: 'DK',
    RegionNo: 2
  },
  {
    CountryNo: 103,
    Name: 'Djibouti',
    Code: 'DJ',
    RegionNo: 0
  },
  {
    CountryNo: 104,
    Name: 'Dominica',
    Code: 'DM',
    RegionNo: 0
  },
  {
    CountryNo: 105,
    Name: 'Dominican Republic',
    Code: 'DO',
    RegionNo: 0
  },
  {
    CountryNo: 106,
    Name: 'Ecuador',
    Code: 'EC',
    RegionNo: 0
  },
  {
    CountryNo: 107,
    Name: 'Egypt',
    Code: 'EG',
    RegionNo: 0
  },
  {
    CountryNo: 108,
    Name: 'El Salvador',
    Code: 'SV',
    RegionNo: 0
  },
  {
    CountryNo: 109,
    Name: 'Equatorial Guinea',
    Code: 'GQ',
    RegionNo: 0
  },
  {
    CountryNo: 110,
    Name: 'Eritrea',
    Code: 'ER',
    RegionNo: 0
  },
  {
    CountryNo: 111,
    Name: 'Estonia',
    Code: 'EE',
    RegionNo: 0
  },
  {
    CountryNo: 112,
    Name: 'Ethiopia',
    Code: 'ET',
    RegionNo: 0
  },
  {
    CountryNo: 4,
    Name: 'Europe',
    Code: '',
    RegionNo: 1
  },
  {
    CountryNo: 113,
    Name: 'Falkland Islands (Malvinas)',
    Code: 'FK',
    RegionNo: 0
  },
  {
    CountryNo: 114,
    Name: 'Faroe Islands',
    Code: 'FO',
    RegionNo: 0
  },
  {
    CountryNo: 44,
    Name: 'Fiji',
    Code: 'FJ',
    RegionNo: 1
  },
  {
    CountryNo: 115,
    Name: 'Finland',
    Code: 'FI',
    RegionNo: 0
  },
  {
    CountryNo: 9,
    Name: 'France',
    Code: 'FR',
    RegionNo: 2
  },
  {
    CountryNo: 116,
    Name: 'French Guiana',
    Code: 'GF',
    RegionNo: 0
  },
  {
    CountryNo: 117,
    Name: 'French Polynesia',
    Code: 'PF',
    RegionNo: 0
  },
  {
    CountryNo: 118,
    Name: 'French Southern Territories',
    Code: 'TF',
    RegionNo: 0
  },
  {
    CountryNo: 119,
    Name: 'Gabon',
    Code: 'GA',
    RegionNo: 0
  },
  {
    CountryNo: 120,
    Name: 'Gambia',
    Code: 'GM',
    RegionNo: 0
  },
  {
    CountryNo: 121,
    Name: 'Georgia',
    Code: 'GE',
    RegionNo: 0
  },
  {
    CountryNo: 11,
    Name: 'Germany',
    Code: 'DE',
    RegionNo: 2
  },
  {
    CountryNo: 122,
    Name: 'Ghana',
    Code: 'GH',
    RegionNo: 0
  },
  {
    CountryNo: 123,
    Name: 'Gibraltar',
    Code: 'GI',
    RegionNo: 0
  },
  {
    CountryNo: 12,
    Name: 'Greece',
    Code: 'GR',
    RegionNo: 2
  },
  {
    CountryNo: 124,
    Name: 'Greenland',
    Code: 'GL',
    RegionNo: 0
  },
  {
    CountryNo: 125,
    Name: 'Grenada',
    Code: 'GD',
    RegionNo: 0
  },
  {
    CountryNo: 126,
    Name: 'Guadeloupe',
    Code: 'GP',
    RegionNo: 0
  },
  {
    CountryNo: 127,
    Name: 'Guam',
    Code: 'GU',
    RegionNo: 0
  },
  {
    CountryNo: 128,
    Name: 'Guatemala',
    Code: 'GT',
    RegionNo: 0
  },
  {
    CountryNo: 129,
    Name: 'Guernsey',
    Code: 'GG',
    RegionNo: 0
  },
  {
    CountryNo: 130,
    Name: 'Guinea',
    Code: 'GN',
    RegionNo: 0
  },
  {
    CountryNo: 131,
    Name: 'Guinea-Bissau',
    Code: 'GW',
    RegionNo: 0
  },
  {
    CountryNo: 132,
    Name: 'Guyana',
    Code: 'GY',
    RegionNo: 0
  },
  {
    CountryNo: 133,
    Name: 'Haiti',
    Code: 'HT',
    RegionNo: 0
  },
  {
    CountryNo: 134,
    Name: 'Heard Island and McDonald Islands',
    Code: 'HM',
    RegionNo: 0
  },
  {
    CountryNo: 135,
    Name: 'Holy See (Vatican City State)',
    Code: 'VA',
    RegionNo: 0
  },
  {
    CountryNo: 136,
    Name: 'Honduras',
    Code: 'HN',
    RegionNo: 0
  },
  {
    CountryNo: 25,
    Name: 'Hong Kong',
    Code: 'HK',
    RegionNo: 1
  },
  {
    CountryNo: 13,
    Name: 'Hungary',
    Code: 'HU',
    RegionNo: 2
  },
  {
    CountryNo: 137,
    Name: 'Iceland',
    Code: 'IS',
    RegionNo: 0
  },
  {
    CountryNo: 26,
    Name: 'India',
    Code: 'IN',
    RegionNo: 1
  },
  {
    CountryNo: 138,
    Name: 'Indonesia',
    Code: 'ID',
    RegionNo: 0
  },
  {
    CountryNo: 139,
    Name: 'Iran, Islamic Republic of',
    Code: 'IR',
    RegionNo: 0
  },
  {
    CountryNo: 140,
    Name: 'Iraq',
    Code: 'IQ',
    RegionNo: 0
  },
  {
    CountryNo: 41,
    Name: 'Ireland',
    Code: 'IE',
    RegionNo: 2
  },
  {
    CountryNo: 141,
    Name: 'Isle of Man',
    Code: 'IM',
    RegionNo: 0
  },
  {
    CountryNo: 142,
    Name: 'Israel',
    Code: 'IL',
    RegionNo: 0
  },
  {
    CountryNo: 29,
    Name: 'Italy',
    Code: 'IT',
    RegionNo: 2
  },
  {
    CountryNo: 143,
    Name: 'Jamaica',
    Code: 'JM',
    RegionNo: 0
  },
  {
    CountryNo: 27,
    Name: 'Japan',
    Code: 'JP',
    RegionNo: 1
  },
  {
    CountryNo: 144,
    Name: 'Jersey',
    Code: 'JE',
    RegionNo: 0
  },
  {
    CountryNo: 145,
    Name: 'Jordan',
    Code: 'JO',
    RegionNo: 0
  },
  {
    CountryNo: 146,
    Name: 'Kazakhstan',
    Code: 'KZ',
    RegionNo: 0
  },
  {
    CountryNo: 147,
    Name: 'Kenya',
    Code: 'KE',
    RegionNo: 0
  },
  {
    CountryNo: 148,
    Name: 'Kiribati',
    Code: 'KI',
    RegionNo: 0
  },
  {
    CountryNo: 149,
    Name: "Korea,Democratic People's Republic of",
    Code: 'KP',
    RegionNo: 0
  },
  {
    CountryNo: 256,
    Name: 'Kosovo',
    Code: 'XK',
    RegionNo: 2
  },
  {
    CountryNo: 150,
    Name: 'Kuwait',
    Code: 'KW',
    RegionNo: 0
  },
  {
    CountryNo: 151,
    Name: 'Kyrgyzstan',
    Code: 'KG',
    RegionNo: 0
  },
  {
    CountryNo: 152,
    Name: "Lao People's Democratic Republic",
    Code: 'LA',
    RegionNo: 0
  },
  {
    CountryNo: 153,
    Name: 'Latvia',
    Code: 'LV',
    RegionNo: 0
  },
  {
    CountryNo: 154,
    Name: 'Lebanon',
    Code: 'LB',
    RegionNo: 0
  },
  {
    CountryNo: 155,
    Name: 'Lesotho',
    Code: 'LS',
    RegionNo: 0
  },
  {
    CountryNo: 156,
    Name: 'Liberia',
    Code: 'LR',
    RegionNo: 0
  },
  {
    CountryNo: 157,
    Name: 'Libya',
    Code: 'LY',
    RegionNo: 0
  },
  {
    CountryNo: 158,
    Name: 'Liechtenstein',
    Code: 'LI',
    RegionNo: 0
  },
  {
    CountryNo: 159,
    Name: 'Lithuania',
    Code: 'LT',
    RegionNo: 0
  },
  {
    CountryNo: 14,
    Name: 'Luxembourg',
    Code: 'LU',
    RegionNo: 2
  },
  {
    CountryNo: 160,
    Name: 'Macao',
    Code: 'MO',
    RegionNo: 0
  },
  {
    CountryNo: 161,
    Name: 'Macedonia, the Former Yugoslav Republic of',
    Code: 'MK',
    RegionNo: 0
  },
  {
    CountryNo: 162,
    Name: 'Madagascar',
    Code: 'MG',
    RegionNo: 0
  },
  {
    CountryNo: 163,
    Name: 'Malawi',
    Code: 'MW',
    RegionNo: 0
  },
  {
    CountryNo: 30,
    Name: 'Malaysia',
    Code: 'MY',
    RegionNo: 1
  },
  {
    CountryNo: 164,
    Name: 'Maldives',
    Code: 'MV',
    RegionNo: 0
  },
  {
    CountryNo: 165,
    Name: 'Mali',
    Code: 'ML',
    RegionNo: 0
  },
  {
    CountryNo: 166,
    Name: 'Malta',
    Code: 'MT',
    RegionNo: 0
  },
  {
    CountryNo: 167,
    Name: 'Marshall Islands',
    Code: 'MH',
    RegionNo: 0
  },
  {
    CountryNo: 168,
    Name: 'Martinique',
    Code: 'MQ',
    RegionNo: 0
  },
  {
    CountryNo: 169,
    Name: 'Mauritania',
    Code: 'MR',
    RegionNo: 0
  },
  {
    CountryNo: 170,
    Name: 'Mauritius',
    Code: 'MU',
    RegionNo: 0
  },
  {
    CountryNo: 171,
    Name: 'Mayotte',
    Code: 'YT',
    RegionNo: 0
  },
  {
    CountryNo: 47,
    Name: 'Mexico',
    Code: 'MX',
    RegionNo: 3
  },
  {
    CountryNo: 172,
    Name: 'Micronesia, Federated States of',
    Code: 'FM',
    RegionNo: 0
  },
  {
    CountryNo: 173,
    Name: 'Moldova Republic of',
    Code: 'MD',
    RegionNo: 0
  },
  {
    CountryNo: 31,
    Name: 'Monaco',
    Code: 'MC',
    RegionNo: 2
  },
  {
    CountryNo: 174,
    Name: 'Mongolia',
    Code: 'MN',
    RegionNo: 0
  },
  {
    CountryNo: 175,
    Name: 'Montenegro',
    Code: 'ME',
    RegionNo: 0
  },
  {
    CountryNo: 176,
    Name: 'Montserrat',
    Code: 'MS',
    RegionNo: 0
  },
  {
    CountryNo: 177,
    Name: 'Morocco',
    Code: 'MA',
    RegionNo: 0
  },
  {
    CountryNo: 178,
    Name: 'Mozambique',
    Code: 'MZ',
    RegionNo: 0
  },
  {
    CountryNo: 179,
    Name: 'Myanmar',
    Code: 'MM',
    RegionNo: 0
  },
  {
    CountryNo: 180,
    Name: 'Namibia',
    Code: 'NA',
    RegionNo: 0
  },
  {
    CountryNo: 181,
    Name: 'Nauru',
    Code: 'NR',
    RegionNo: 0
  },
  {
    CountryNo: 182,
    Name: 'Nepal',
    Code: 'NP',
    RegionNo: 0
  },
  {
    CountryNo: 39,
    Name: 'Netherlands',
    Code: 'NL',
    RegionNo: 2
  },
  {
    CountryNo: 183,
    Name: 'New Caledonia',
    Code: 'NC',
    RegionNo: 0
  },
  {
    CountryNo: 5,
    Name: 'New Zealand',
    Code: 'NZ',
    RegionNo: 1
  },
  {
    CountryNo: 184,
    Name: 'Nicaragua',
    Code: 'NI',
    RegionNo: 0
  },
  {
    CountryNo: 185,
    Name: 'Niger',
    Code: 'NE',
    RegionNo: 0
  },
  {
    CountryNo: 186,
    Name: 'Nigeria',
    Code: 'NG',
    RegionNo: 0
  },
  {
    CountryNo: 187,
    Name: 'Niue',
    Code: 'NU',
    RegionNo: 0
  },
  {
    CountryNo: 48,
    Name: 'Norfolk Island',
    Code: 'NF',
    RegionNo: 1
  },
  {
    CountryNo: 6,
    Name: 'North America',
    Code: '',
    RegionNo: 1
  },
  {
    CountryNo: 188,
    Name: 'Northern Mariana Islands',
    Code: 'MP',
    RegionNo: 0
  },
  {
    CountryNo: 189,
    Name: 'Norway',
    Code: 'NO',
    RegionNo: 0
  },
  {
    CountryNo: 190,
    Name: 'Oman',
    Code: 'OM',
    RegionNo: 0
  },
  {
    CountryNo: 191,
    Name: 'Pakistan',
    Code: 'PK',
    RegionNo: 0
  },
  {
    CountryNo: 192,
    Name: 'Palau',
    Code: 'PW',
    RegionNo: 0
  },
  {
    CountryNo: 193,
    Name: 'Palestine, State of',
    Code: 'PS',
    RegionNo: 0
  },
  {
    CountryNo: 194,
    Name: 'Panama',
    Code: 'PA',
    RegionNo: 0
  },
  {
    CountryNo: 38,
    Name: 'Papua New Guinea',
    Code: 'PG',
    RegionNo: 1
  },
  {
    CountryNo: 195,
    Name: 'Paraguay',
    Code: 'PY',
    RegionNo: 0
  },
  {
    CountryNo: 196,
    Name: 'Peru',
    Code: 'PE',
    RegionNo: 0
  },
  {
    CountryNo: 32,
    Name: 'Philippines',
    Code: 'PH',
    RegionNo: 1
  },
  {
    CountryNo: 197,
    Name: 'Pitcairn',
    Code: 'PN',
    RegionNo: 0
  },
  {
    CountryNo: 15,
    Name: 'Poland',
    Code: 'PL',
    RegionNo: 2
  },
  {
    CountryNo: 198,
    Name: 'Portugal',
    Code: 'PT',
    RegionNo: 0
  },
  {
    CountryNo: 199,
    Name: 'Puerto Rico',
    Code: 'PR',
    RegionNo: 0
  },
  {
    CountryNo: 200,
    Name: 'Qatar',
    Code: 'QA',
    RegionNo: 0
  },
  {
    CountryNo: 201,
    Name: 'Réunion',
    Code: 'RE',
    RegionNo: 0
  },
  {
    CountryNo: 202,
    Name: 'Romania',
    Code: 'RO',
    RegionNo: 0
  },
  {
    CountryNo: 203,
    Name: 'Russian Federation',
    Code: 'RU',
    RegionNo: 0
  },
  {
    CountryNo: 204,
    Name: 'Rwanda',
    Code: 'RW',
    RegionNo: 0
  },
  {
    CountryNo: 205,
    Name: 'Saint Barthélemy',
    Code: 'BL',
    RegionNo: 0
  },
  {
    CountryNo: 206,
    Name: 'Saint Helena',
    Code: 'SH',
    RegionNo: 0
  },
  {
    CountryNo: 207,
    Name: 'Saint Kitts and Nevis',
    Code: 'KN',
    RegionNo: 0
  },
  {
    CountryNo: 208,
    Name: 'Saint Lucia',
    Code: 'LC',
    RegionNo: 0
  },
  {
    CountryNo: 209,
    Name: 'Saint Martin (French part)',
    Code: 'MF',
    RegionNo: 0
  },
  {
    CountryNo: 210,
    Name: 'Saint Pierre and Miquelon',
    Code: 'PM',
    RegionNo: 0
  },
  {
    CountryNo: 211,
    Name: 'Saint Vincent and the Grenadines',
    Code: 'VC',
    RegionNo: 0
  },
  {
    CountryNo: 51,
    Name: 'Samoa',
    Code: 'WS',
    RegionNo: 1
  },
  {
    CountryNo: 212,
    Name: 'Samoa',
    Code: 'WS',
    RegionNo: 0
  },
  {
    CountryNo: 213,
    Name: 'San Marino',
    Code: 'SM',
    RegionNo: 0
  },
  {
    CountryNo: 214,
    Name: 'Sao Tome and Principe',
    Code: 'ST',
    RegionNo: 0
  },
  {
    CountryNo: 215,
    Name: 'Saudi Arabia',
    Code: 'SA',
    RegionNo: 0
  },
  {
    CountryNo: 216,
    Name: 'Senegal',
    Code: 'SN',
    RegionNo: 0
  },
  {
    CountryNo: 217,
    Name: 'Serbia',
    Code: 'RS',
    RegionNo: 0
  },
  {
    CountryNo: 218,
    Name: 'Seychelles',
    Code: 'SC',
    RegionNo: 0
  },
  {
    CountryNo: 219,
    Name: 'Sierra Leone',
    Code: 'SL',
    RegionNo: 0
  },
  {
    CountryNo: 28,
    Name: 'Singapore',
    Code: 'SG',
    RegionNo: 1
  },
  {
    CountryNo: 220,
    Name: 'Sint Maarten (Dutch part)',
    Code: 'SX',
    RegionNo: 0
  },
  {
    CountryNo: 16,
    Name: 'Slovakia',
    Code: 'SK',
    RegionNo: 2
  },
  {
    CountryNo: 221,
    Name: 'Slovenia',
    Code: 'SI',
    RegionNo: 0
  },
  {
    CountryNo: 222,
    Name: 'Solomon Islands',
    Code: 'SB',
    RegionNo: 0
  },
  {
    CountryNo: 223,
    Name: 'Somalia',
    Code: 'SO',
    RegionNo: 0
  },
  {
    CountryNo: 52,
    Name: 'South Africa',
    Code: 'ZA',
    RegionNo: 1
  },
  {
    CountryNo: 7,
    Name: 'South America',
    Code: '',
    RegionNo: 1
  },
  {
    CountryNo: 224,
    Name: 'South Georgia and the South Sandwich Islands',
    Code: 'GS',
    RegionNo: 0
  },
  {
    CountryNo: 45,
    Name: 'South Korea',
    Code: 'KR',
    RegionNo: 1
  },
  {
    CountryNo: 225,
    Name: 'South Sudan',
    Code: 'SS',
    RegionNo: 0
  },
  {
    CountryNo: 17,
    Name: 'Spain',
    Code: 'ES',
    RegionNo: 2
  },
  {
    CountryNo: 46,
    Name: 'Sri Lanka',
    Code: 'LK',
    RegionNo: 1
  },
  {
    CountryNo: 226,
    Name: 'Sudan',
    Code: 'SD',
    RegionNo: 0
  },
  {
    CountryNo: 227,
    Name: 'Suriname',
    Code: 'SR',
    RegionNo: 0
  },
  {
    CountryNo: 228,
    Name: 'Svalbard and Jan Mayen',
    Code: 'SJ',
    RegionNo: 0
  },
  {
    CountryNo: 229,
    Name: 'Swaziland',
    Code: 'SZ',
    RegionNo: 0
  },
  {
    CountryNo: 33,
    Name: 'Sweden',
    Code: 'SE',
    RegionNo: 2
  },
  {
    CountryNo: 18,
    Name: 'Switzerland',
    Code: 'CH',
    RegionNo: 2
  },
  {
    CountryNo: 230,
    Name: 'Syrian Arab Republic',
    Code: 'SY',
    RegionNo: 0
  },
  {
    CountryNo: 50,
    Name: 'Taiwan',
    Code: 'TW',
    RegionNo: 1
  },
  {
    CountryNo: 231,
    Name: 'Tajikistan',
    Code: 'TJ',
    RegionNo: 0
  },
  {
    CountryNo: 232,
    Name: 'Tanzania,ited Republic of',
    Code: 'TZ',
    RegionNo: 0
  },
  {
    CountryNo: 37,
    Name: 'Thailand',
    Code: 'TH',
    RegionNo: 1
  },
  {
    CountryNo: 233,
    Name: 'Timor-Leste',
    Code: 'TL',
    RegionNo: 0
  },
  {
    CountryNo: 234,
    Name: 'Togo',
    Code: 'TG',
    RegionNo: 0
  },
  {
    CountryNo: 235,
    Name: 'Tokelau',
    Code: 'TK',
    RegionNo: 0
  },
  {
    CountryNo: 49,
    Name: 'Tonga',
    Code: 'TO',
    RegionNo: 1
  },
  {
    CountryNo: 236,
    Name: 'Trinidad and Tobago',
    Code: 'TT',
    RegionNo: 0
  },
  {
    CountryNo: 237,
    Name: 'Tunisia',
    Code: 'TN',
    RegionNo: 0
  },
  {
    CountryNo: 238,
    Name: 'Turkey',
    Code: 'TR',
    RegionNo: 0
  },
  {
    CountryNo: 239,
    Name: 'Turkmenistan',
    Code: 'TM',
    RegionNo: 0
  },
  {
    CountryNo: 240,
    Name: 'Turks and Caicos Islands',
    Code: 'TC',
    RegionNo: 0
  },
  {
    CountryNo: 241,
    Name: 'Tuvalu',
    Code: 'TV',
    RegionNo: 0
  },
  {
    CountryNo: 242,
    Name: 'Uganda',
    Code: 'UG',
    RegionNo: 0
  },
  {
    CountryNo: 243,
    Name: 'Ukraine',
    Code: 'UA',
    RegionNo: 0
  },
  {
    CountryNo: 35,
    Name: 'United Arab Emirates',
    Code: 'AE',
    RegionNo: 1
  },
  {
    CountryNo: 19,
    Name: 'United Kingdom',
    Code: 'GB',
    RegionNo: 2
  },
  {
    CountryNo: 20,
    Name: 'United States',
    Code: 'US',
    RegionNo: 3
  },
  {
    CountryNo: 244,
    Name: 'United States Minor Outlying Islands',
    Code: 'UM',
    RegionNo: 0
  },
  {
    CountryNo: 245,
    Name: 'Uruguay',
    Code: 'UY',
    RegionNo: 0
  },
  {
    CountryNo: 246,
    Name: 'Uzbekistan',
    Code: 'UZ',
    RegionNo: 0
  },
  {
    CountryNo: 247,
    Name: 'Vanuatu',
    Code: 'VU',
    RegionNo: 0
  },
  {
    CountryNo: 248,
    Name: 'Venezuela,livarian Republic of',
    Code: 'VE',
    RegionNo: 0
  },
  {
    CountryNo: 34,
    Name: 'Vietnam',
    Code: 'VN',
    RegionNo: 1
  },
  {
    CountryNo: 249,
    Name: 'Virgin Islands,itish',
    Code: 'VG',
    RegionNo: 0
  },
  {
    CountryNo: 250,
    Name: 'Virgin Islands,S.',
    Code: 'VI',
    RegionNo: 0
  },
  {
    CountryNo: 251,
    Name: 'Wallis and Futuna',
    Code: 'WF',
    RegionNo: 0
  },
  {
    CountryNo: 252,
    Name: 'Western Sahara',
    Code: 'EH',
    RegionNo: 0
  },
  {
    CountryNo: 253,
    Name: 'Yemen',
    Code: 'YE',
    RegionNo: 0
  },
  {
    CountryNo: 254,
    Name: 'Zambia',
    Code: 'ZM',
    RegionNo: 0
  },
  {
    CountryNo: 255,
    Name: 'Zimbabwe',
    Code: 'ZW',
    RegionNo: 0
  }
]
