import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Theme,
  createStyles,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {}
  })
)

type Props = {
  password: string
  setPassword: (password: string) => void
  label?: string
}

const Password = (props: Props) => {
  const classes = useStyles()

  const { password, setPassword, label } = props
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextField
      label={label ? label : 'Password'}
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={e => setPassword(e.target.value)}
      InputLabelProps={{
        shrink: true
      }}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={e => e.preventDefault()}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}
export default Password
