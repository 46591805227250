import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import { validate as emailValidator } from 'email-validator'
import nanoid from 'nanoid'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  addAccount,
  deleteAccount,
  editAccount,
} from '../../redux/actions/account.actions.old'
import { UIActions } from '../../redux/reducers/ui.reducer'
import { IAccount, TAccountType } from '../../redux/types'
import { CountriesDic } from './../../redux/dictionaries/countries'

interface IEditOrganisationProps {
  account: IAccount | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridGap: theme.spacing(2),
      gridTemplateColumns: '2fr 1fr',
      gridTemplateAreas: `
        "name  type"
        "email email"
        "country state"
        "suburb postcode"
        "primaryButtons primaryButtons"
      `,
      alignItems: 'start',
      [theme.breakpoints.down(396)]: {
        gridTemplateAreas: `
          "name"
          "type"
          "email"
          "country"
          "state"
          "suburb"
          "postcode"
          "primaryButtons"
        `,
        gridTemplateColumns: '1fr',
      },
    },
    nameFormControl: {
      gridArea: 'name',
    },
    typeFormControl: {
      gridArea: 'type',
    },
    emailFormControl: {
      gridArea: 'email',
    },
    countryFormControl: {
      gridArea: 'country',
    },
    suburbFormControl: {
      gridArea: 'suburb',
    },
    stateFormControl: {
      gridArea: 'state',
    },
    postCodeFormControl: {
      gridArea: 'postcode',
    },
    primaryButtons: {
      gridArea: 'primaryButtons',
      alignSelf: 'end',
      marginTop: theme.spacing(8),
    },
  })
)

const EditOrganisation = (props: IEditOrganisationProps) => {
  const { account } = props
  const classes = useStyles()

  const [readonly, setReadonly] = useState(false)
  const [orgNo, setOrgNo] = useState<number | undefined>(undefined)
  const [name, setName] = useState('')
  const [type, setType] = useState<TAccountType>(1)
  const [country, setCountry] = useState(1) //! default is Australia
  const [suburb, setSuburb] = useState('')
  const [state, setState] = useState('')
  const [postcode, setPostcode] = useState('')
  const [id, setId] = useState(nanoid(12))
  const [email, setEmail] = useState('')

  const [isNew, setIsNew] = useState(account ? false : true)
  const [isDeleted, setIsDeleted] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (account) {
      setOrgNo(account.orgNo)
      setName(account.name)
      setType(account.type)
      setCountry(account.countryNo)
      setSuburb(account.address2 || '')
      setState(account.state || '')
      setPostcode(account.postCode || '')
      setId(account.uniqueKey)
      setEmail(account.email || '')

      setIsNew(false)
      setIsDeleted(false)
    } else {
      reset()
    }
  }, [account])

  const validate = (): boolean => {
    if (name !== '' && (email === '' || !email || emailValidator(email)))
      return true
    return false
  }

  const handleSave = async () => {
    const nAccount: IAccount = {
      orgNo: orgNo,
      name: name,
      address2: suburb,
      countryNo: country,
      industryNo: 1,
      membershipStatus: 'A',
      postCode: postcode,
      state: state,
      tradingName: name,
      type: type,
      uniqueKey: id,
      email: email,
      busy: true,
    }

    dispatch(UIActions.uiSetEditDrawerBusy(true))
    if (!account) {
      dispatch(addAccount(nAccount))
      setIsNew(false)
    } else {
      dispatch(editAccount(nAccount))
    }
  }

  const reset = () => {
    setOrgNo(undefined)
    setName('')
    setId(nanoid())
    setType(1)
    setCountry(1)
    setSuburb('')
    setState('')
    setPostcode('')
    setEmail('')
    setIsDeleted(false)
    setReadonly(false)
  }

  const handleSaveAndNew = async () => {
    await handleSave()
    reset()
    setIsNew(true)
  }

  const handleDelete = async () => {
    dispatch(UIActions.uiSetEditDrawerBusy(true))
    dispatch(deleteAccount(id))
    setDeleteDialogOpen(false)
    setIsDeleted(true)
    setReadonly(true)
  }

  return (
    <form className={classes.root} autoComplete="off" onSubmit={handleSave}>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Account Confirmation</DialogTitle>
        <DialogContent>
          Selected account is going to be permanently deleted.
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handleDelete}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      {account && account.alert && <Box color="red">{account.alert}</Box>}

      <FormControl className={classes.nameFormControl}>
        <TextField
          disabled={readonly}
          label="Name"
          required={true}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </FormControl>
      <FormControl className={classes.typeFormControl}>
        <InputLabel htmlFor="account-type">Account Type</InputLabel>
        <Select
          disabled={readonly}
          value={type}
          inputProps={{
            id: 'account-type',
          }}
          onChange={e => {
            const t = e.target.value as TAccountType
            setType(t)
          }}
        >
          <MenuItem key="a_type1" value={1}>
            Supplier
          </MenuItem>
          <MenuItem key="a_type2" value={2}>
            University
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.emailFormControl}>
        <TextField
          disabled={readonly}
          label="Default Email Address"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </FormControl>

      <FormControl className={classes.countryFormControl}>
        <InputLabel>Country</InputLabel>
        <Select
          disabled={readonly}
          value={country}
          onChange={e => setCountry(e.target.value as number)}
        >
          {CountriesDic.map(c => (
            <MenuItem key={'country_' + c.CountryNo} value={c.CountryNo}>
              {c.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.stateFormControl}>
        <TextField
          disabled={readonly}
          value={state}
          label="State"
          onChange={e => setState(e.target.value)}
        />
      </FormControl>

      <FormControl className={classes.suburbFormControl}>
        <TextField
          disabled={readonly}
          value={suburb}
          label="Suburb"
          onChange={e => setSuburb(e.target.value)}
        />
      </FormControl>

      <FormControl className={classes.postCodeFormControl}>
        <TextField
          disabled={readonly}
          value={postcode}
          label="Postcode"
          onChange={e => setPostcode(e.target.value)}
        />
      </FormControl>

      <Box
        className={classes.primaryButtons}
        justifyContent="space-between"
        display="flex"
      >
        <Button
          color="primary"
          variant="contained"
          disabled={!validate() || isDeleted || readonly}
          onClick={handleSave}
        >
          Save
        </Button>
        <Button
          color="primary"
          disabled={!validate() || isDeleted || readonly}
          onClick={handleSaveAndNew}
        >
          Save & New
        </Button>
        <Button
          disabled={isNew || readonly}
          color="secondary"
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete
        </Button>
      </Box>
    </form>
  )
}
export default EditOrganisation
