import { createReducer } from 'typesafe-actions'
import { addToList, extendList, populateList, removeFromList, replaceOnList, selectUser } from '../actions/user.actions'
import { IUser, TUserActions } from '../types'

export const userList = createReducer<IUser[], TUserActions>([])
  .handleAction(addToList, (state, action) => {
    return [...state, action.payload]
  })
  .handleAction(populateList, (state, action) => {
    return action.payload
  })
  .handleAction(extendList, (state, action) => {
    return [...state, ...action.payload]
  })
  .handleAction(removeFromList, (state, action) => {
    return [...state.filter(u => u.userNo && u.userNo !== action.payload)]
  })
  .handleAction(replaceOnList, (state, action) => {
    return [
      ...state.map(u => {
        if (u.userNo === action.payload.userNo) return action.payload
        return u
      })
    ]
  })

export const selectedUser = createReducer<IUser | null, TUserActions>(
  null
).handleAction(selectUser, (state, action) => {
  return action.payload
})
