import { Box, createStyles, makeStyles, Theme, Tooltip, Typography } from '@material-ui/core'
import AlertIcon from '@material-ui/icons/ErrorOutline'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      margin: '.5em',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '.5em'
    },
    key: {
      fontWeight: 'bold',
      gridColumn: '1/2'
    },
    val: {
      gridColumn: '2/3'
    },
    alert: {
      color: theme.palette.error.main
    }
  })
)

type Props = {
  propertyKey: string
  propertyValue?: string | number | React.ReactNode
  suffix?: string
}

const PropertyTag = ({ propertyKey, propertyValue, suffix }: Props) => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Typography className={classes.key}>{propertyKey}:</Typography>
      <Typography className={classes.val}>
        {propertyValue === 0
          ? 0
          : propertyValue || (
              <Tooltip title="Information is not provided">
                <AlertIcon className={classes.alert} />
              </Tooltip>
            )}
        {suffix && (propertyValue || propertyValue === 0) && `${suffix}`}
      </Typography>
    </Box>
  )
}
export default PropertyTag
