import { createAction } from 'typesafe-actions'
import { ISellerBuyerProps } from './../types/customer.types'

//! for EPICS
export const modifyCustomerMeta = createAction(
  'customer/MODIFY',
  action => (customerMeta: ISellerBuyerProps) => action(customerMeta)
)

export const getCustomerMeta = createAction(
  'customer/GET',
  action => (sellerId: string, buyerId: string) => action({ sellerId, buyerId })
)
