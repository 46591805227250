import { combineEpics } from 'redux-observable'
import { accountEpics } from './accounts.epic'
import { asnEpics } from './asn.epic'
import { categoryEpics } from './category.epic'
import { customerEpics } from './customer.epic'
import { invoiceEpics } from './invoices.epic'
import { orderEpics } from './order.epic'
import { pricelistEpics } from './pricelist.epic'
import { productEpics } from './products.epic'
import { profileEpics } from './profile.epic'
import { propertiesEpics } from './properties.epic'
import { uomEpics } from './uom.epic'
import { userEpics } from './user.epic'
import { uiEpics } from './ui.epic'

export default combineEpics<any>(
  // ! UI Epics
  ...uiEpics,
  // ! Accounts vel Orgs
  ...accountEpics,
  // ! User profile
  ...profileEpics,
  // ! Users
  ...userEpics,
  // ! Org properties
  ...propertiesEpics,
  // ! UOM mappings
  ...uomEpics,
  //! Orders
  ...orderEpics,
  // ! Products in CATX
  ...productEpics,
  // ! ASN's
  ...asnEpics,
  // ! Invoices
  ...invoiceEpics,
  // ! Pricelists
  ...pricelistEpics,
  // ! Customer
  ...customerEpics,
  // ! Categories
  ...categoryEpics
)
