import { Box, createStyles, IconButton, Theme } from '@material-ui/core'
import {
  ArrowBack,
  ArrowForward,
  ZoomIn,
  ZoomOut,
  CloudDownload,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import React from 'react'
import ContainerDimensions from 'react-container-dimensions'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbox: {
      position: 'fixed',
      zIndex: theme.zIndex.appBar,
      // background: 'rgba(144, 164, 174, 0.05)',
      background: theme.palette.background.paper,
      opacity: '0.5',
      padding: '.5em',
      textAlign: 'center',
      '& i': {
        fontSize: '1em',
      },
      '& span.space': {
        margin: '0 .5em',
      },
      '&:hover': {
        // background: theme.palette.background.paper,
        opacity: 1,
        boxShadow: theme.shadows[1],
      },
      transition: 'all 0.5s ease',
    },
  })
)

interface Props {
  url: string
  // width: number
}

const InvoicePdfView = ({ url }: Props) => {
  const classes = useStyles()
  const [pages, setpages] = React.useState<number>(0)
  const [pageNumber, setpageNumber] = React.useState<number>(1)
  const [scale, setscale] = React.useState<number>(1)
  const onLoadSuccess = ({ numPages = 0 }) => {
    setpages(numPages)
  }

  // TODO use rxjs to get only last value from the stream
  return (
    <ContainerDimensions>
      {({ width }) => {
        const w = Math.round(width - 0)
        return (
          <Box width={w}>
            <Box className={classes.toolbox}>
              <IconButton size="small" href={url} download>
                <CloudDownload />
              </IconButton>
              <span className="space">
                Page {pageNumber} of {pages}
              </span>
              <IconButton
                disabled={pageNumber - 1 === 0}
                onClick={() => setpageNumber((prev) => prev - 1)}
                size="small"
              >
                <ArrowBack />
              </IconButton>
              <IconButton
                disabled={pageNumber + 1 > pages}
                onClick={() => setpageNumber((prev) => prev + 1)}
                size="small"
              >
                <ArrowForward />
              </IconButton>
              <IconButton
                onClick={() => setscale((prev) => prev - 0.2)}
                size="small"
              >
                <ZoomOut />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => setscale((prev) => prev + 0.2)}
              >
                <ZoomIn />
              </IconButton>
            </Box>
            <Document file={url} onLoadSuccess={onLoadSuccess}>
              <Page pageNumber={pageNumber} scale={scale} width={w} />
            </Document>
          </Box>
        )
      }}
    </ContainerDimensions>
  )
}

export default InvoicePdfView
