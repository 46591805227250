import {
  AppBar,
  createStyles,
  Divider,
  Drawer,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import Toaster from '../components/toaster'
import AvatarButton from '../components/user-profile/avatar-button'
import NameTag from '../components/user-profile/name-tag'
import velogo from '../velogo.svg'
import velogo_dark from '../velogo_darkmode.svg'
import { AppState } from '../redux/store'
import { MainMenu } from './main-menu'
import Settings from '../pages/settings'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // global variables to use in module.scss files
    '@global': {
      ':root': {
        '--standard-spacing-1': theme.spacing(1) + 'px',
        '--standard-spacing-2': theme.spacing(2) + 'px',
        '--standard-spacing-3': theme.spacing(3) + 'px',
        '--standard-spacing-4': theme.spacing(4) + 'px',

        '--color-primary-main': theme.palette.primary.main,
        '--color-primary-dark': theme.palette.primary.dark,
        '--color-primary-light': theme.palette.primary.light,

        '--color-primary-contrast-text': theme.palette.primary.contrastText,

        '--color-secondary-main': theme.palette.secondary.main,
        '--color-secondary-dark': theme.palette.secondary.dark,
        '--color-secondary-light': theme.palette.secondary.light,

        '--color-secondary-contrast-text': theme.palette.secondary.contrastText,

        '--background-color-default': theme.palette.background.default,
        '--background-color-paper': theme.palette.background.paper,

        '--box-shadow-1': theme.shadows[1],
        '--box-shadow-2': theme.shadows[2],
        '--box-shadow-3': theme.shadows[3],
        '--box-shadow-4': theme.shadows[4],

        '--z-index-appbar': theme.zIndex.appBar,
        '--z-index-drawer': theme.zIndex.drawer,
        '--z-index-mobile-stepper': theme.zIndex.mobileStepper,
        '--z-index-modal': theme.zIndex.modal,
        '--z-index-snackbar': theme.zIndex.snackbar,
        '--z-index-speed-dial': theme.zIndex.speedDial,
        '--z-index-tooltip': theme.zIndex.tooltip,
      },
    },

    root: {
      display: 'flex',
    },
    menuItemSide: {
      paddingLeft: 8,
      paddingRight: 16,
    },
    logo: {
      position: 'absolute',
      height: 55,
      margin: 8,
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: 'calc(100vh - 64px )',
      overflow: 'auto',
      marginTop: '60px',
    },
  })
)

const MasterPage = ({ children }: { children: ReactNode }) => {
  const [openDrawer, setOpenDrawer] = useState(true)
  const [openSettings, setOpenSettings] = useState(false)
  const styles = useStyles()
  const title = useSelector((state: AppState) => state.headerTitleState)
  const palette = useSelector((state: AppState) => state.ui.palette)

  return (
    <div className={styles.root}>
      <AppBar
        elevation={0}
        position="absolute"
        className={classNames(styles.appBar, openDrawer && styles.appBarShift)}
      >
        <Toolbar disableGutters={!openDrawer} className={styles.toolbar}>
          <IconButton
            color="inherit"
            className={classNames(
              styles.menuButton,
              openDrawer && styles.menuButtonHidden
            )}
            onClick={() => setOpenDrawer((prev) => !prev)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={styles.title}
          >
            {title}
          </Typography>
          <NameTag />
          <AvatarButton onClickSetting={() => setOpenSettings(true)} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            styles.drawerPaper,
            !openDrawer && styles.drawerPaperClose
          ),
        }}
        open={openDrawer}
      >
        {palette.type !== 'dark' && (
          <img alt="Logo" src={velogo} className={styles.logo} />
        )}
        {palette.type === 'dark' && (
          <img alt="Logo" src={velogo_dark} className={styles.logo} />
        )}
        <div className={styles.toolbarIcon}>
          <IconButton onClick={() => setOpenDrawer((prev) => !prev)}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <MainMenu />
      </Drawer>
      <main className={styles.content}>{children}</main>
      <Settings openSettings={openSettings} setOpenSettings={setOpenSettings} />
      <Toaster />
    </div>
  )
}
export default MasterPage
