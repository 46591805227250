import { Box, Button, TextField, FormControl } from '@material-ui/core'
import React from 'react'
import { IAccount, IBuyerSellerProps } from '../../../redux/types'
import scss from './buyer-seller-props.module.scss'
import { genUrl, buyerSellerProps } from './../../../API'
import { useDispatch } from 'react-redux'
import {
  broadcastAjaxErrors,
  broadcastAjaxSuccess,
} from '../../../redux/epics/utils'
interface Props {
  account: IAccount
  myOrgId: string
}
const BuyerSellerProps = (props: Props) => {
  const { account, myOrgId } = props
  const dispatch = useDispatch()
  const [accountCode, setAccountCode] = React.useState<string>('')
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountCode(e.target.value)
  }
  const getURL = genUrl(buyerSellerProps.getOne, {
    buyerId: myOrgId,
    sellerId: account.uniqueKey,
  })
  const postURL = genUrl(buyerSellerProps.postOne, {
    buyerId: myOrgId,
    sellerId: account.uniqueKey,
  })

  //? fetch account code here
  const fetchAccountCode = async () => {
    const res = await fetch(getURL)
    if (res.ok) {
      const j: IBuyerSellerProps = await res.json()
      if (j && j.supplierCode) {
        setAccountCode(j.supplierCode)
      }
    } else {
      dispatch(broadcastAjaxErrors(res.statusText))
    }
  }
  const updateAccountCode = async () => {
    const prop: IBuyerSellerProps = {
      buyerId: myOrgId,
      sellerId: account.uniqueKey,
      supplierCode: accountCode,
    }
    const res = await fetch(postURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(prop),
    })
    if (res.ok) {
      dispatch(
        broadcastAjaxSuccess(
          `Account Code for ${account.name} has been updated`
        )
      )
    } else {
      dispatch(broadcastAjaxErrors(res.statusText))
    }
  }

  const reset = () => {
    setAccountCode('')
  }

  React.useEffect(() => {
    fetchAccountCode()
    return () => {
      reset()
    }
  }, [account, myOrgId])

  return (
    <Box className={scss.root}>
      <FormControl className={scss.formControl}>
        <TextField
          label="Catalogue Account Code"
          value={accountCode}
          onChange={handleChange}
          className={scss.input}
        />
        <Button color="primary" variant="contained" onClick={updateAccountCode}>
          Save
        </Button>
      </FormControl>
    </Box>
  )
}
export default BuyerSellerProps
