import { AnyAction } from 'redux'
import { ActionsObservable, Epic } from 'redux-observable'
import { ajax, AjaxError } from 'rxjs/ajax'
import {
  catchError,
  filter,
  flatMap,
  mergeMap,
  switchMap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { exportInvoice } from '../actions/invoice.actions'
import { InvoiceActions } from '../reducers/invoice.reducer'
import { IInvoice, TInvoiceActions } from '../types/invoice.types'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'
import { UIActions } from '../reducers/ui.reducer'
import { IHistory } from '../types'

const exportInvoiceEpic = (action$: ActionsObservable<TInvoiceActions>) =>
  action$.pipe(
    filter(isActionOf(exportInvoice)),
    mergeMap((action) => {
      const url = `${process.env.REACT_APP_API_BUYER}/${action.meta.buyerId}/invoice/${action.payload}/actions/export`
      return ajax
        .post(
          url,
          {},
          {
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          mergeMap((response) => [
            broadcastAjaxSuccess('Invoice has been successfully exported.'),
          ]),
          catchError((error: AjaxError) => [broadcastAjaxErrors(error)])
        )
    })
  )

const fetchInvoices: Epic<AnyAction> = (action$) =>
  action$.pipe(
    filter(InvoiceActions.getInvoices.match),
    switchMap((action) => {
      const url = `${process.env.REACT_APP_API_BUYER}/${
        action.payload
      }/invoice?limit=${action.meta.limit}${
        process.env.REACT_APP_AMPERSAND_SYMBOL
      }offset=${action.meta.offset}${
        process.env.REACT_APP_AMPERSAND_SYMBOL
      }veStatus=${action.meta.veStatus || ''}`
      return ajax.getJSON(url).pipe(
        flatMap((response: any) => [
          action.meta.more
            ? InvoiceActions.appendToList(response.data as IInvoice[])
            : InvoiceActions.populateList(response.data as IInvoice[]),
          UIActions.uiSetFetching('invoices_list', false),
        ]),
        catchError((error) => [broadcastAjaxErrors(error)])
      )
    })
  )

const fetchInvoice: Epic<AnyAction> = (action$) =>
  action$.pipe(
    filter(InvoiceActions.getInvoice.match),
    switchMap((action) => {
      const url = `${process.env.REACT_APP_API_BUYER}/${action.meta.buyerId}/invoice/${action.payload}`
      return ajax.getJSON(url).pipe(
        flatMap((response: any) => {
          let m = []
          m.push(InvoiceActions.select(response as IInvoice))
          // ? mark an invoice as read
          if (!response.read) {
            m.push(
              InvoiceActions.setAsRead(action.payload, action.meta.buyerId)
            )
          }
          return m
        }),
        catchError((error) => [broadcastAjaxErrors(error)])
      )
    })
  )

const postReadFlag: Epic<AnyAction> = (action$) =>
  action$.pipe(
    filter(InvoiceActions.setAsRead.match),
    flatMap((action) => {
      const url = `${process.env.REACT_APP_API_BUYER}/${action.meta.buyerId}/invoice/${action.payload}/actions/read`
      return ajax
        .post(url, '', {
          'Content-Type': 'application/json',
        })
        .pipe(
          flatMap((response: any) => [
            InvoiceActions.updateReadFlag(action.payload),
          ]),
          catchError((error) => [broadcastAjaxErrors(error)])
        )
    })
  )

const fetchInvoiceHistory = (action$: ActionsObservable<TInvoiceActions>) =>
  action$.pipe(
    filter(InvoiceActions.getInvoiceHistory.match),
    switchMap((action) => {
      const url = `${process.env.REACT_APP_API_BUYER}/${action.meta.buyerId}/invoice/${action.payload}/history`
      return ajax.getJSON(url).pipe(
        flatMap((response: any) => [
          InvoiceActions.populateHistory(response.data as IHistory[]),
        ]),
        catchError((error) => [broadcastAjaxErrors(error)])
      )
    })
  )

export const invoiceEpics = [
  exportInvoiceEpic,
  fetchInvoices,
  fetchInvoice,
  postReadFlag,
  fetchInvoiceHistory,
]
