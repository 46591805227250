import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Theme,
  Toolbar,
} from '@material-ui/core'
import { Error, ImportExport, ListAlt } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import { createStyles, makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import useCredentials from '../../../hooks/use-credentials'
import { add } from '../../../redux/actions/asn.actions'
import { AsnActions } from '../../../redux/reducers/asn.reducer'
import { UIActions } from '../../../redux/reducers/ui.reducer'
import { AppState } from '../../../redux/store'
import { IAsn, IAsnAddress, IAsnLine, IOrderLine } from '../../../redux/types'
import PropertyTag from '../../products/property-tag'
import TabContent from '../../tab-content'
import AsnAddress from './asn-address'
import AsnExportErrors from './asn-export-errors'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    divider: {
      marginTop: '1.5em',
      marginBottom: '1.5em',
    },
    numberInput: {
      textAlign: 'right',
      width: '5vw',
    },
    bottomAppbar: {
      padding: theme.spacing(1),
      position: 'sticky',
      bottom: 0,
      background: theme.palette.background.paper,
      justifyContent: 'space-between',
      '& button': {
        marginRight: '1em',
      },
    },
    errors: {
      gridArea: 'errors',
      marginTop: '1.5em',
      marginBottom: '1.5em',
    },
    errorsButton: {},
    errorsPane: {
      height: '50vh',
    },
  })
)

type Props = {
  orgid: string
  buyerId: string
  orderNumber: string
  hanldeShowList: () => void
}

const AsnEdit = (props: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const formatToInputDate = (dateToFormat: Date) => {
    return `${dateToFormat.getFullYear()}-${(
      '0' +
      (dateToFormat.getMonth() + 1)
    ).slice(-2)}-${('0' + dateToFormat.getDate()).slice(-2)}`
  }
  const [tab, setTab] = useState(0)

  const { orgid, buyerId, orderNumber, hanldeShowList } = props
  const asn = useSelector((state: AppState) => state.asns.selected)
  const asns = useSelector((state: AppState) => state.asns.list)
  const order = useSelector((state: AppState) => state.orders.selectedOrder)
  const history = useSelector((state: AppState) => state.asns.history)

  const [exportErrors, setExportErrors] = useState<boolean>(false)

  const [readonly, setReadonly] = useState(false)
  const [createdDate, setCreatedDate] = useState((asn && asn.createdDate) || '')
  const [shipmentId, setShipmentId] = useState((asn && asn.createdDate) || '')
  const [noticeDate, setNoticeDate] = useState((asn && asn.createdDate) || '')
  const [deliveryDate, setDeliveryDate] = useState(
    (asn && asn.deliveryDate) || ''
  )
  const [shipmentDate, setShipmentDate] = useState(
    (asn && asn.shipmentDate) || ''
  )
  const [carrierName, setCarrierName] = useState((asn && asn.carrierName) || '')
  const [trackingId, setTrackingId] = useState((asn && asn.trackingId) || '')
  const [shipFrom, setShipFrom] = useState<IAsnAddress>(
    (asn && asn.shipFrom) || {}
  )
  const [shipTo, setShipTo] = useState<IAsnAddress>((asn && asn.shipTo) || {})
  const [lines, setLines] = useState<Array<IAsnLine>>((asn && asn.lines) || [])

  const credentials = useCredentials()
  const [submitDialogOpen, setSubmitDialogOpen] = useState(false)
  const waitingForServer = useSelector(
    (state: AppState) => state.ui.waitingForServer
  )

  const setAsnLineQty = (number?: number, quantity?: string) => {
    const newAnsLines = lines.map(l => {
      if (number === l.number) {
        return { ...l, ...{ quantity: parseFloat(quantity ? quantity : '0') } }
      }
      return l
    })
    setLines(newAnsLines)
  }

  const setAsnLineUom = (number?: number, uom?: string) => {
    const newAnsLines = lines.map(l => {
      if (number === l.number) {
        return { number: l.number, quantity: l.quantity, uom: uom }
      }
      return l
    })
    setLines(newAnsLines)
  }

  const handleRemoveLine = (line: IAsnLine) => {
    const newAnsLines = lines.filter(l => line.number !== l.number)
    setLines(newAnsLines as IAsnLine[])
  }

  useEffect(() => {
    if (asn) {
      setReadonly(true)
      setShipmentId(asn.shipmentId || '')
      setCreatedDate(
        (asn.createdDate && formatToInputDate(new Date(asn.createdDate))) || ''
      )
      setNoticeDate(
        (asn.noticeDate && formatToInputDate(new Date(asn.noticeDate))) || ''
      )
      setDeliveryDate(
        (asn.deliveryDate && formatToInputDate(new Date(asn.deliveryDate))) ||
          ''
      )
      setShipmentDate(
        (asn.shipmentDate && formatToInputDate(new Date(asn.shipmentDate))) ||
          ''
      )
      setCarrierName(asn.carrierName || '')
      setTrackingId(asn.trackingId || '')

      const complementedAsnLines = asn.lines
        ? asn.lines.map(l => {
            const orderLine =
              order &&
              order.lines &&
              order.lines.find(
                i => i.number === l.number && i.supplierProductCode
              )

            return {
              ...l,
              code: orderLine ? orderLine.supplierProductCode : '',
            }
          })
        : []
      setLines(complementedAsnLines)
      setShipFrom(asn.shipFrom ? asn.shipFrom : {})
      setShipTo(asn.shipTo ? asn.shipTo : {})

      if (asn.id && !asn.exported && asn.waitingForAction) {
        dispatch(
          AsnActions.getAsnHistory(asn.id, orgid, 'SUPPLIER', 'EXPORT_FAILED')
        )
      }
    } else {
      reset()
    }
  }, [asn])

  const reset = () => {
    setReadonly(false)
    setShipmentId('[Autogenerate]')
    setCreatedDate('[Autogenerate]')
    setCarrierName('')
    setTrackingId('')
    setNoticeDate(formatToInputDate(new Date()))
    setShipFrom({})
    if (order) {
      setMissingLines(order.lines || [])
      setShipTo(order.shipTo ? { ...order.shipTo, ...{ id: undefined } } : {})
    }
  }

  const setMissingLines = (orderLines: IOrderLine[]) => {
    const linesFromOrder: IAsnLine[] = orderLines.map(l => {
      const asnLine: IAsnLine = {
        number: l.number || 0,
        quantity: l.quantity || 0,
        code: l.supplierProductCode,
        uom: l.uom,
      }
      return asnLine
    })

    //Group existing ASNs lines
    const asnLines = asns.reduce((r, a) => {
      const lines = a.lines || []
      lines.forEach(l => {
        r[l.number] = r[l.number]
          ? {
              ...r[l.number],
              quantity: r[l.number].quantity + l.quantity,
            }
          : l
      })
      return r
    }, Object.create(null))

    const missingLines = linesFromOrder
      .map(l => {
        return asnLines[l.number]
          ? { ...l, quantity: l.quantity - asnLines[l.number].quantity }
          : l
      })
      .filter(l => l.quantity && l.quantity > 0)

    setLines(missingLines)
  }

  const validate = (): boolean => {
    const qtyOk = lines.every(l => l.quantity)
    if (!shipmentDate || !noticeDate || !orderNumber || !qtyOk) {
      return false
    }
    return true
  }

  const handleSubmit = async () => {
    const nAsn: IAsn = {
      orderNumber: orderNumber,
      noticeDate: noticeDate,
      deliveryDate: deliveryDate,
      shipmentDate: shipmentDate,
      carrierName: carrierName,
      trackingId: trackingId,
      shipFrom: shipFrom,
      shipTo: shipTo,
      lines: lines,
    }
    dispatch(UIActions.uiSetEditDrawerBusy(true))
    dispatch(add(nAsn, orgid, buyerId))
    setSubmitDialogOpen(false)
  }

  const handleExport = () => {
    if (asn && asn.id) {
      dispatch(UIActions.uiSetWaitingForServer(true))
      dispatch(AsnActions.export(orgid, asn.id))
    }
  }

  return (
    <form className={classes.root}>
      <Dialog
        open={submitDialogOpen}
        onClose={() => setSubmitDialogOpen(false)}
      >
        <DialogTitle>Submitting ASN Confirmation</DialogTitle>
        <DialogContent>
          ASN is going to be submitted. After submitting you cannot edit it.
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setSubmitDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handleSubmit}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PropertyTag propertyKey="Shipment Id" propertyValue={shipmentId} />
        </Grid>
        <Grid item xs={6}>
          <PropertyTag propertyKey="Created Date" propertyValue={createdDate} />
        </Grid>
      </Grid>

      {(credentials.isSupport() || credentials.renderForOrgType('SUPPLIER')) &&
        asn &&
        !asn.exported &&
        history &&
        history.length > 0 && (
          <Box className={classes.errors}>
            <Button
              className={classes.errorsButton}
              variant="contained"
              color="secondary"
              startIcon={<Error />}
              onClick={() => setExportErrors(prev => !prev)}
            >
              Export Errors detected!
            </Button>
          </Box>
        )}

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl>
            <TextField
              disabled={readonly}
              label="Issue Date"
              type="date"
              required={true}
              value={noticeDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setNoticeDate(e.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl>
            <TextField
              disabled={readonly}
              label="Shipment Date"
              type="date"
              required={true}
              value={shipmentDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setShipmentDate(e.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl>
            <TextField
              disabled={readonly}
              label="Delivery Date"
              type="date"
              required={false}
              value={deliveryDate}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setDeliveryDate(e.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl>
            <TextField
              disabled={readonly}
              label="Carrier Name"
              required={false}
              value={carrierName}
              inputProps={{
                maxLength: 120,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setCarrierName(e.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <FormControl>
            <TextField
              disabled={readonly}
              label="Tracking Id"
              required={false}
              value={trackingId}
              inputProps={{
                maxLength: 30,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setTrackingId(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Divider variant="fullWidth" className={classes.divider} />

      <Tabs
        value={tab}
        onChange={(event: React.ChangeEvent<{}>, newValue: number) =>
          setTab(newValue)
        }
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab value={0} label="Ship From" />
        <Tab value={1} label="Ship To" />
      </Tabs>
      <TabContent show={tab === 0}>
        <Box>
          <AsnAddress
            readonly={readonly}
            setAddress={setShipFrom}
            asnAddress={shipFrom}
          />
        </Box>
      </TabContent>
      <TabContent show={tab === 1}>
        <Box>
          <AsnAddress
            readonly={readonly}
            setAddress={setShipTo}
            asnAddress={shipTo}
          />
        </Box>
      </TabContent>

      {/* ASN Lines */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Number</TableCell>
                <TableCell align="center">Product Code</TableCell>
                <TableCell align="center">Quantity *</TableCell>
                <TableCell align="center">UOM</TableCell>
                <TableCell padding="checkbox"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lines &&
                lines.map((l, i) => (
                  <TableRow key={'line_' + i} hover={true}>
                    <TableCell>{l.number}</TableCell>
                    <TableCell>{l.code}</TableCell>
                    <TableCell align="right">
                      <FormControl
                        className={`${readonly &&
                          'Mui-disabled'} MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl`}
                      >
                        <NumberFormat
                          disabled={readonly}
                          thousandSeparator={readonly && ','}
                          required={true}
                          value={l.quantity}
                          className={`${classes.numberInput} MuiInputBase-input MuiInput-input`}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          onChange={e =>
                            setAsnLineQty(l.number, e.target.value)
                          }
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <FormControl>
                        <TextField
                          disabled={readonly}
                          required={true}
                          value={l.uom}
                          inputProps={{
                            className: classes.numberInput,
                            maxLength: 8,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={e =>
                            setAsnLineUom(l.number, e.target.value)
                          }
                        />
                      </FormControl>
                    </TableCell>
                    <TableCell align="right" padding="checkbox">
                      {!readonly && (
                        <IconButton onClick={() => handleRemoveLine(l)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>

      <Toolbar disableGutters className={classes.bottomAppbar}>
        <Button onClick={hanldeShowList} startIcon={<ListAlt />}>
          Show List
        </Button>
        {!readonly && (
          <Button
            color="primary"
            variant="contained"
            disabled={readonly || !validate()}
            onClick={() => setSubmitDialogOpen(true)}
          >
            Submit
          </Button>
        )}
        {(credentials.isSupport() ||
          credentials.renderForOrgType('SUPPLIER')) &&
          asn &&
          !asn.exported &&
          asn.waitingForAction && (
            <Button
              variant="contained"
              color="primary"
              disabled={waitingForServer}
              startIcon={<ImportExport />}
              onClick={handleExport}
            >
              Export
            </Button>
          )}
      </Toolbar>

      {(credentials.isSupport() || credentials.renderForOrgType('SUPPLIER')) &&
        history &&
        history.length > 0 && (
          <Drawer
            anchor="bottom"
            open={exportErrors}
            onClose={() => setExportErrors(false)}
            classes={{
              paper: classes.errorsPane,
            }}
          >
            <AsnExportErrors />
          </Drawer>
        )}
    </form>
  )
}

export default AsnEdit
