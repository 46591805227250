import React from 'react'
import NumberFormat from 'react-number-format'
import scss from './format-currency-text.module.scss'
interface Props {
  currency?: string
  value?: number | string
}
const FormatCurrencyText = (props: Props) => {
  const { currency, value } = props

  return (
    <NumberFormat
      displayType="text"
      decimalScale={2}
      value={value}
      thousandSeparator=","
      prefix={currency ? currency + ' ' : ''}
      fixedDecimalScale
      className={scss.currencyText}
    />
  )
}
export default FormatCurrencyText
