import { Box, createStyles, Hidden, Slider, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    leftPane: {
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
        marginTop: theme.spacing(1),
      },
    },
    rightPane: {
      [theme.breakpoints.down('sm')]: {
        flexBasis: '100%',
        marginTop: theme.spacing(1),
      },
    },
    actions: {
      padding: '0 1.5em',
      position: 'sticky',
      top: 0,
      marginTop: '-1em',
      opacity: 0.1,
      transition: 'opacity .25s ease-in-out',
      '&:hover': {
        opacity: 1,
        '& .MuiSlider-markLabel': {
          background: theme.palette.background.paper,
          display: 'block',
          boxShadow: theme.shadows[1],
        },
      },
      zIndex: theme.zIndex.appBar,
    },
    slider: {
      marginBottom: 0,
      '& .MuiSlider-markLabel': {
        top: '1.3em',
        padding: '2px',
        borderRadius: '3px',
        display: 'none',
      },
    },
  })
)

interface Props {
  children: React.ReactNodeArray
  defaultLeftSize?: number
  defaultRightSize?: number
  //! sometimes rendering content of the panel, while resizing can be expensive, so we can change the behaviour
  hideLeftOnResize?: boolean
  hideRightOnResize?: boolean
  className?: string
}
const CommanderView = (props: Props) => {
  const classes = useStyles()
  const [showLeft, setShowLeft] = React.useState(true)
  const [cloneLeft, setCloneLeft] = React.useState(true)
  const [cloneRight, setCloneRight] = React.useState(true)
  const [showRight, setShowRight] = React.useState(true)
  const [flipped, setFlipped] = React.useState(false)
  const [resizing, setresizing] = React.useState<boolean>(false)

  const calcDefaultSize = (side: 'left' | 'right') => {
    switch (side) {
      case 'left':
        if (props.defaultLeftSize) return props.defaultLeftSize
        if (props.defaultRightSize) return 100 - props.defaultRightSize
        break
      case 'right':
        if (props.defaultRightSize) return props.defaultRightSize
        if (props.defaultLeftSize) return 100 - props.defaultLeftSize
        break
    }

    return 50
  }
  const [leftSize, setLeftSize] = React.useState(calcDefaultSize('left'))
  const [rightSize, setRightSize] = React.useState(calcDefaultSize('right'))

  React.useEffect(() => {
    if (props.hideLeftOnResize) {
      setCloneLeft(!resizing)
    }
    if (props.hideRightOnResize) setCloneRight(!resizing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizing])

  return (
    <Box className={props.className}>
      <Hidden smDown>
        <Box className={classes.actions}>
          <Slider
            track={false}
            className={classes.slider}
            defaultValue={leftSize}
            step={1}
            min={0}
            max={100}
            // marks={[
            //   { value: 0, label: 'Hide' },
            //   { value: 30, label: '30%' },
            //   { value: 50, label: '50%' },
            //   { value: 70, label: '70%' },
            //   { value: 100, label: 'Hide' },
            // ]}
            onChange={(e, v) => {
              setLeftSize(v as number)
              setRightSize(100 - (v as number))
              setresizing(true)
            }}
            onChangeCommitted={() => setresizing(false)}
          />
        </Box>
      </Hidden>
      <Box className={classes.root}>
        {showLeft && props.children && leftSize > 10 && (
          <Box className={classes.leftPane} flexBasis={leftSize + '%'}>
            {cloneLeft &&
              React.cloneElement(
                props.children[flipped ? 1 : 0] as React.ReactElement<any>
              )}
          </Box>
        )}
        {showRight && props.children && rightSize > 10 && (
          <Box className={classes.rightPane} flexBasis={rightSize + '%'}>
            {cloneRight &&
              React.cloneElement(
                props.children[flipped ? 0 : 1] as React.ReactElement<any>
              )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
export default CommanderView
