import React from 'react'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
interface Props {
  show: boolean
  columns?: number
  rows?: number
}
const SkeletonTable = (props: Props) => {
  const { columns = 5, rows = 3, show } = props

  const Row = () => {
    return (
      <TableRow>
        {new Array(columns).fill(null).map((a, i) => (
          <TableCell key={i}>
            <Skeleton height="1em" />
          </TableCell>
        ))}
      </TableRow>
    )
  }

  if (!show) return null

  return (
    <Table size="small">
      <TableBody>
        {new Array(rows).fill(null).map((r, i) => (
          <Row key={i} />
        ))}
      </TableBody>
    </Table>
  )
}
export default React.memo(SkeletonTable)
