import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'
import { UIActions } from '../../../redux/reducers/ui.reducer'
import { AppState } from '../../../redux/store'
import { IAccount, IOrgProperty } from '../../../redux/types'
import scss from './standard-org-props.module.scss'

type Props = {
  account: IAccount
}

const EditOrgProperties = (props: Props) => {
  const { account } = props
  const properties = useSelector((state: AppState) =>
    state.accountProperties.orgPropertyList.filter(
      p => p.orgNo === account.orgNo
    )
  )
  const dispatch = useDispatch()
  const [ref, inView] = useInView()
  const [limit] = useState(20)

  const [dialogOpen, setDialogOpen] = useState(false)

  // ? form fields
  const [propertyName, setPropertyName] = useState('')
  const [propertyValue, setPropertyValue] = useState('')
  const [propertyNo, setPropertyNo] = useState<number | undefined>(undefined)

  const handlePropertyName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPropertyName(e.currentTarget.value)
  }
  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPropertyValue(e.currentTarget.value)
  }

  const cancel = () => {
    reset()
  }

  const save = () => {
    const p: IOrgProperty = {
      property: propertyName,
      value: propertyValue,
      orgNo: account.orgNo as number,
      orgPropertyNo: propertyNo,
    }
    dispatch(UIActions.uiSetEditDrawerBusy(true))
    if (!propertyNo) {
      dispatch(AccountPropsActions.addOrgProperty(p, account.uniqueKey))
    } else {
      dispatch(AccountPropsActions.replaceOrgProperty(p, account.uniqueKey))
    }
    reset()
  }

  const handleDelete = () => {
    if (propertyNo) {
      dispatch(UIActions.uiSetEditDrawerBusy(true))
      dispatch(
        AccountPropsActions.removeOrgProperty(propertyNo, account.uniqueKey)
      )
      setPropertyNo(undefined)
    }
    setDialogOpen(false)
  }

  const reset = () => {
    setPropertyName('')
    setPropertyValue('')
    setPropertyNo(undefined)
  }

  useEffect(() => {
    dispatch(
      AccountPropsActions.fetchProperties(account.uniqueKey, {
        limit: limit,
        offset: 0,
        more: false,
      })
    )
    return () => {
      reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  // trigger infinite scrolling pagination
  useEffect(() => {
    if (inView) {
      dispatch(
        AccountPropsActions.fetchProperties(account.uniqueKey, {
          limit: limit,
          offset: properties.length,
          more: true,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <Box className={scss.root}>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Delete Property</DialogTitle>
        <DialogContent>
          Selected property is going to be permanently deleted.
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handleDelete}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h2">Basic Properties</Typography>
      <Box className={scss.actions}>
        <FormControl>
          <TextField
            label="Property Name"
            required
            value={propertyName}
            onChange={handlePropertyName}
          ></TextField>
        </FormControl>
        <TextField
          label="Property Value"
          required
          value={propertyValue}
          onChange={handleValue}
        ></TextField>
        <Button
          onClick={save}
          variant="contained"
          color="primary"
          size="small"
          disabled={propertyName === '' || propertyValue === ''}
        >
          Save
        </Button>
        <Button onClick={cancel}>Cancel</Button>
      </Box>
      <Table size="small" className={scss.table}>
        <TableHead>
          <TableRow>
            <TableCell>Property</TableCell>
            <TableCell>Value</TableCell>
            <TableCell padding="checkbox"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {properties.map((p, i) => (
            <TableRow
              hover
              key={`prop_${i}`}
              selected={propertyNo === p.orgPropertyNo}
              ref={
                i + 1 === properties.length && properties.length >= limit
                  ? ref
                  : null
              }
            >
              <TableCell>{p.property}</TableCell>
              <TableCell>{p.value}</TableCell>
              <TableCell padding="checkbox" align="right">
                <Box className={scss.tableButtons}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setPropertyName(p.property)
                      setPropertyValue(p.value)
                      setPropertyNo(p.orgPropertyNo)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setDialogOpen(true)
                      setPropertyNo(p.orgPropertyNo as number)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
export default EditOrgProperties
