import React from 'react'
import { IAccount, IReceivingEmailProperty } from '../../../redux/types'
import scss from './standard-org-props.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@material-ui/core'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'
import ConfirmDialog from './../../confirm-dialog'
import { Delete, Edit } from '@material-ui/icons'
import { AppState } from '../../../redux/store'

const emptyProperty: IReceivingEmailProperty = { code: '', emailAddress: '' }

interface Props {
  account: IAccount
}

const EditOrgReceivingEmail = (props: Props) => {
  const { account } = props
  const [property, setProperty] = React.useState<IReceivingEmailProperty>(
    emptyProperty
  )
  const dispatch = useDispatch()
  const list = useSelector(
    (state: AppState) => state.accountProperties.receivingEmailList
  )

  const reset = () => {
    setProperty(emptyProperty)
  }

  const handleCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProperty({ ...property, code: e.target.value })
  }

  const handleEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProperty({ ...property, emailAddress: e.target.value })
  }

  const handleSave = () => {
    if (property.id) {
      dispatch(
        AccountPropsActions.replaceReceivingEmail(property, account.uniqueKey)
      )
    } else {
      dispatch(
        AccountPropsActions.addReceivingEmail(property, account.uniqueKey)
      )
    }
    reset()
  }

  const cancel = () => {
    reset()
  }

  //? fetching a list of properties
  React.useEffect(() => {
    dispatch(AccountPropsActions.fetchReceivingEmails(account.uniqueKey))
    return () => {
      dispatch(AccountPropsActions.populateReceivingEmailList([]))
    }
  }, [account])

  return (
    <Box className={scss.root}>
      <Typography variant="h2">Receiving Email</Typography>
      <Box className={scss.actions}>
        <FormControl>
          <TextField
            label="Code"
            required
            value={property.code}
            onChange={handleCode}
          ></TextField>
        </FormControl>
        <TextField
          label="Email Address/Pattern"
          required
          value={property.emailAddress}
          onChange={handleEmailAddress}
        ></TextField>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          size="small"
          disabled={property.code === '' || property.emailAddress === ''}
        >
          Save
        </Button>
        <Button onClick={cancel}>Cancel</Button>
      </Box>
      <Table className={scss.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>Email Address/Pattern</TableCell>
            <TableCell padding="checkbox"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(l => (
            <TableRow key={l.id} hover>
              <TableCell>{l.code}</TableCell>
              <TableCell>{l.emailAddress}</TableCell>
              <TableCell>
                <Box className={scss.tableButtons}>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setProperty(l)
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <DeleteProperty orgId={account.uniqueKey} property={l} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

const DeleteProperty = ({
  property,
  orgId,
}: {
  property: IReceivingEmailProperty
  orgId: string
}) => {
  const [dialog, setDialog] = React.useState(false)
  const dispatch = useDispatch()

  const handleClick = () => {
    setDialog(true)
  }
  const handleConfirm = () => {
    setDialog(false)
    dispatch(AccountPropsActions.removeReceivingEmail(property.id!, orgId))
  }
  const handleCancel = () => {
    setDialog(false)
  }
  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <Delete />
      </IconButton>
      <ConfirmDialog
        title="Deleting property"
        open={dialog}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      >
        Are you sure you want to permanently delete this property{' '}
        <strong>
          "{property.code} = {property.emailAddress}"
        </strong>
        ?
      </ConfirmDialog>
    </>
  )
}

export default EditOrgReceivingEmail
