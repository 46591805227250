import { ActionsObservable } from 'redux-observable'
import { of } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import {
  catchError,
  debounceTime,
  filter,
  mergeMap,
  switchMap,
  flatMap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import {
  add,
  addToList,
  fetchAsn,
  fetchASNs,
  fetchMoreASNs,
} from '../actions/asn.actions'
import { IAsn, TAsnActions, IHistory } from '../types'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'
import { UIActions } from '../reducers/ui.reducer'
import { AsnActions } from '../reducers/asn.reducer'

const fetchAsnsEpic = (action$: ActionsObservable<TAsnActions>) =>
  action$.pipe(
    filter(isActionOf([fetchASNs, fetchMoreASNs])),
    debounceTime(500),
    switchMap(action => {
      let url = ''
      if (action.meta.renderFor === 'SUPPLIER') {
        url += process.env.REACT_APP_API_SELLER
      } else if (action.meta.renderFor === 'UNIVERSITY') {
        url += process.env.REACT_APP_API_BUYER
      }
      url += `/${action.payload}/asn?offset=${action.meta.offset}${process.env.REACT_APP_AMPERSAND_SYMBOL}limit=${action.meta.limit}${process.env.REACT_APP_AMPERSAND_SYMBOL}orderNumber=${action.meta.orderNumber}`

      if (action.meta.query) {
        action.meta.query.forEach(q => {
          url += `${process.env.REACT_APP_AMPERSAND_SYMBOL}${q.key}=${encodeURI(
            q.value
          )}`
        })
      }

      return ajax.getJSON(url).pipe(
        mergeMap((response: any) => {
          return [
            action.meta.append
              ? AsnActions.appendToList(
                  (response && (response.data as IAsn[])) || []
                )
              : AsnActions.populateList(
                  (response && (response.data as IAsn[])) || []
                ),
          ]
        }),
        catchError((error: AjaxError) => of(broadcastAjaxErrors(error)))
      )
    })
  )

const fetchAsnEpic = (action$: ActionsObservable<TAsnActions>) =>
  action$.pipe(
    filter(isActionOf([fetchAsn])),
    debounceTime(500),
    switchMap(action => {
      let url = ''
      if (action.meta.renderFor === 'SUPPLIER') {
        url += process.env.REACT_APP_API_SELLER
      } else if (action.meta.renderFor === 'UNIVERSITY') {
        url += process.env.REACT_APP_API_BUYER
      }
      url += `/${action.payload}/asn/${action.meta.asnid}`

      return ajax.getJSON(url).pipe(
        mergeMap((response: any) => {
          return [AsnActions.select((response && (response as IAsn)) || null)]
        }),
        catchError((error: AjaxError) => of(broadcastAjaxErrors(error)))
      )
    })
  )

const postAsnEpic = (action$: ActionsObservable<TAsnActions>) => {
  return action$.pipe(
    filter(isActionOf(add)),

    mergeMap(action => {
      const url = `${process.env.REACT_APP_API_SELLER}/${action.meta.sellerId}/buyer/${action.meta.buyerId}/asn`

      return ajax
        .post(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap(response => [
            addToList(response.response as IAsn),
            broadcastAjaxSuccess('Asn record saved'),
            AsnActions.select(response.response as IAsn),
            UIActions.uiSetEditDrawerBusy(false),
          ]),
          catchError((error: AjaxError) => [
            broadcastAjaxErrors(error),
            UIActions.uiSetEditDrawerBusy(false),
          ])
        )
    })
  )
}

const exportAsnEpic = (action$: ActionsObservable<TAsnActions>) =>
  action$.pipe(
    filter(AsnActions.export.match),
    mergeMap(action => {
      const url = `${process.env.REACT_APP_API_SELLER}/${action.payload}/asn/${action.meta.asnid}/actions/export`
      return ajax
        .post(
          url,
          {},
          {
            'Content-Type': 'application/json',
          }
        )
        .pipe(
          mergeMap(response => [
            broadcastAjaxSuccess('ASN exported successfully!'),
            AsnActions.updateExportedFlag(action.meta.asnid),
            UIActions.uiSetWaitingForServer(false),
          ]),
          catchError((error: AjaxError) => [
            broadcastAjaxErrors(error),
            UIActions.uiSetWaitingForServer(false),
          ])
        )
    })
  )

const fetchAsnHistory = (action$: ActionsObservable<TAsnActions>) =>
  action$.pipe(
    filter(AsnActions.getAsnHistory.match),
    switchMap(action => {
      const url = `${process.env.REACT_APP_API_SELLER}/${action.meta.orgId}/asn/${action.payload}/history?type=${action.meta.type}`

      return ajax.getJSON(url).pipe(
        flatMap((response: any) => [
          AsnActions.populateHistory(response.data as IHistory[]),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

export const asnEpics = [
  fetchAsnEpic,
  fetchAsnsEpic,
  postAsnEpic,
  exportAsnEpic,
  fetchAsnHistory,
]
