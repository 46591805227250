import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate as emailValidator } from 'email-validator'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { add, remove, replace } from '../../redux/actions/user.actions'
import { IUser, IUserType } from '../../redux/types/user.types'
import { UIActions } from '../../redux/reducers/ui.reducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridGap: theme.spacing(2),
      gridTemplateColumns: '1fr 1fr',
      gridTemplateAreas: `
        "firstname  lastname"
        "email type"
        "primaryButtons primaryButtons"
      `,
      alignItems: 'start',
      [theme.breakpoints.down(396)]: {
        gridTemplateAreas: `
          "firstname"
          "lastname"
          "email"
          "type"
          "primaryButtons"
        `,
        gridTemplateColumns: '1fr',
      },
    },
    firstNameControl: {
      gridArea: 'firstname',
    },
    lastNameControl: {
      gridArea: 'lastname',
    },
    emailFormControl: {
      gridArea: 'email',
    },
    typeControl: {
      gridArea: 'type',
    },
    primaryButtons: {
      gridArea: 'primaryButtons',
      alignSelf: 'end',
      marginTop: theme.spacing(8),
    },
  })
)
interface IProps {
  user: IUser | null
  orgid: string
}
const EditUser = (props: IProps) => {
  const { orgid, user } = props

  const [readonly, setReadonly] = useState(false)
  const [deleted, setDeleted] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const dispatch = useDispatch()
  const classes = useStyles()

  const validate = (): boolean => {
    if (firstname === '' || lastname === '' || !emailValidator(email))
      return false
    return true
  }

  const handleSave = (): void => {
    const u: IUser = {
      ...user,
      firstName: firstname,
      lastName: lastname,
      email: email,
      username: email,
      type: type,
    }
    dispatch(UIActions.uiSetEditDrawerBusy(true))
    if (!user) {
      dispatch(add(u, orgid))
    } else {
      dispatch(replace(u, orgid))
    }
  }
  // const handleSaveAndNew = (): void => {
  //   handleSave()
  //   reset()
  // }
  const handleDelete = (): void => {
    if (user && user.userNo) {
      dispatch(UIActions.uiSetEditDrawerBusy(true))
      dispatch(remove(user.userNo, orgid))
      setReadonly(true)
      setDeleteDialogOpen(false)
      setDeleted(true)
    }
  }
  const reset = (): void => {
    setFirstname('')
    setLastname('')
    setEmail('')
    setDeleted(false)
  }

  //   ! form fields
  const [firstname, setFirstname] = useState((user && user.firstName) || '')
  const [lastname, setLastname] = useState((user && user.lastName) || '')
  const [email, setEmail] = useState((user && user.email) || '')
  const [type, setType] = useState<IUserType>((user && user.type) || 'REGULAR')

  React.useEffect(() => {
    if (user) {
      setFirstname(user.firstName)
      setLastname(user.lastName)
      setEmail(user.email)
      setType(user.type)
    } else {
      reset()
    }
  }, [user])

  return (
    <form className={classes.root}>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete User Confirmation</DialogTitle>
        <DialogContent>
          Selected user is going to be permanently deleted.
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setDeleteDialogOpen(false)}>
            Cancel
          </Button>
          <Button color="secondary" onClick={handleDelete}>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <FormControl className={classes.firstNameControl} fullWidth>
        <TextField
          label="First Name"
          required
          disabled={readonly}
          onChange={e => setFirstname(e.target.value)}
          value={firstname}
        ></TextField>
      </FormControl>

      <FormControl className={classes.lastNameControl} fullWidth>
        <TextField
          label="Last Name"
          required
          disabled={readonly}
          onChange={e => setLastname(e.target.value)}
          value={lastname}
        ></TextField>
      </FormControl>

      <FormControl className={classes.emailFormControl} fullWidth>
        <TextField
          label="Email/Username"
          required
          disabled={readonly}
          onChange={e => setEmail(e.target.value)}
          value={email}
        ></TextField>
      </FormControl>

      <FormControl className={classes.typeControl}>
        <InputLabel htmlFor="user-type">User Type</InputLabel>
        <Select
          disabled={readonly}
          value={type}
          inputProps={{
            id: 'user-type',
          }}
          onChange={e => setType(e.target.value as IUserType)}
        >
          <MenuItem key="u_type1" value="REGULAR">
            Regular
          </MenuItem>
          <MenuItem key="u_type2" value="ADMIN">
            Admin
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.typeControl} fullWidth></FormControl>

      <Box
        className={classes.primaryButtons}
        justifyContent="space-between"
        display="flex"
      >
        <Button
          color="primary"
          variant="contained"
          disabled={readonly || !validate() || deleted}
          onClick={handleSave}
        >
          Save
        </Button>

        {/* <Button
          color="primary"
          disabled={readonly || !validate() || deleted}
          onClick={handleSaveAndNew}
        >
          Save & New
        </Button> */}

        <Button
          disabled={!user || readonly}
          color="secondary"
          onClick={() => setDeleteDialogOpen(true)}
        >
          Delete
        </Button>
      </Box>
    </form>
  )
}
export default EditUser
