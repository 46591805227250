import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useCredentials from '../hooks/use-credentials'
import { changeTitle } from '../redux/actions/header.actions'
import AccountsList from './accounts-list'
import { ComponentWithRoutes } from './types'

//? a wrapper page for lists of accounts and their users
const OrdersPage = (props: ComponentWithRoutes) => {
  const { children } = props
  const credentials = useCredentials()
  const [title] = useState('Orders')
  const dispatch = useDispatch()

  useEffect(() => {
    document.title = title
    dispatch(changeTitle(title))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  if (
    credentials.renderForOrgType('SUPPLIER') ||
    credentials.renderForOrgType('UNIVERSITY')
  ) {
    return <>{children}</>
  } else if (credentials.isSupport()) {
    return (
      <>
        <Box marginBottom="2em" padding="1em">
          <Typography variant="h3">
            Please, assume a role of either seller or buyer to see orders from
            their perspective!{' '}
          </Typography>
        </Box>
        <AccountsList path="/" />
      </>
    )
  }
  return <Typography variant="h1"></Typography>
}
export default OrdersPage
