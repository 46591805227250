import {
  ListItemIcon,
  MenuItem,
  MenuList,
  styled,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import {
  Assignment,
  AssignmentOutlined,
  Category,
  Dashboard,
  MonetizationOnRounded,
  SupervisedUserCircle,
  SettingsApplicationsOutlined,
} from '@material-ui/icons'
import { navigate } from '@reach/router'
import React from 'react'
import useCredentials from '../hooks/use-credentials'

const StyledListItemIcon = styled(ListItemIcon)({ paddingLeft: '.5em' })

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    preferences: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
  })
)

export const MainMenu = () => {
  const credentials = useCredentials()
  const classes = useStyles()

  const nav = (target: string) => {
    // setSelected(target)
    navigate(target)
  }

  return (
    <>
      <MenuList>
        {/* <MenuItem
          selected={window.location.pathname.startsWith('/app/dashboard')}
          onClick={e => nav('/app/dashboard')}
        >
          <StyledListItemIcon>
            <Dashboard />
          </StyledListItemIcon>
          <Typography>Dashboard</Typography>
        </MenuItem> */}

        <MenuItem
          selected={window.location.pathname.startsWith('/app/catalogue')}
          onClick={e => nav('/app/catalogue')}
        >
          <StyledListItemIcon>
            <Category />
          </StyledListItemIcon>
          <Typography>Catalogue</Typography>
        </MenuItem>

        {credentials.renderForOrgType('SUPPLIER') && (
          <MenuItem
            selected={window.location.pathname.startsWith('/app/pricelists')}
            onClick={e => nav('/app/pricelists')}
          >
            <StyledListItemIcon>
              <MonetizationOnRounded />
            </StyledListItemIcon>
            <Typography>Pricelists</Typography>
          </MenuItem>
        )}

        <MenuItem
          selected={window.location.pathname.startsWith('/app/orders')}
          onClick={e => nav('/app/orders')}
        >
          <StyledListItemIcon>
            <Assignment />
          </StyledListItemIcon>
          <Typography>Orders</Typography>
        </MenuItem>

        {credentials.renderForOrgType('UNIVERSITY') && (
          <MenuItem
            selected={window.location.pathname.startsWith('/app/invoices')}
            onClick={e => nav('/app/invoices')}
          >
            <StyledListItemIcon>
              <AssignmentOutlined />
            </StyledListItemIcon>
            <Typography>Invoices</Typography>
          </MenuItem>
        )}

        {credentials.renderForUserType('SUPPORT') && (
          <MenuItem
            selected={window.location.pathname.startsWith('/app/accounts')}
            onClick={e => nav('/app/accounts')}
          >
            <StyledListItemIcon>
              <SupervisedUserCircle />
            </StyledListItemIcon>
            <Typography>Accounts</Typography>
          </MenuItem>
        )}
      </MenuList>
      <MenuList className={classes.preferences}>
        <MenuItem
          selected={window.location.pathname.startsWith('/app/preferences')}
          onClick={e => nav('/app/preferences')}
        >
          <StyledListItemIcon>
            <SettingsApplicationsOutlined />
          </StyledListItemIcon>
          <Typography>Preferences</Typography>
        </MenuItem>
      </MenuList>
    </>
  )
}
