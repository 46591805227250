import { useSelector } from 'react-redux'
import { AppState } from '../redux/store'
import { TOrgType, TUserType } from '../redux/types'
const useCredentials = () => {
  const profile = useSelector((state: AppState) => state.profile)
  const renderFor = useSelector((state: AppState) => state.ui.renderFor)

  const policyUserType = (type: TUserType) =>
    profile.policy !== undefined && profile.policy.userType === type

  const policyOrgType = (type: TOrgType): boolean =>
    profile.policy !== undefined && profile.policy.orgType === type

  const renderingForOrgType = (type: TOrgType): boolean =>
    renderFor !== undefined && renderFor.orgType === type

  return {
    // ! use only if you want to check ORIGINAL, not ASSUMED version of it
    isSupport: () => policyUserType('SUPPORT'),
    // ! use only if you want to check ORIGINAL, not ASSUMED version of it
    isSeller: () => policyOrgType('SUPPLIER'),
    // ! use only if you want to check ORIGINAL, not ASSUMED version of it
    isBuyer: () => policyOrgType('UNIVERSITY'),
    isAdmin: () => policyUserType('ADMIN') || policyUserType('SUPPORT'),

    isOrgAssumed: () => {
      if (profile.policy) {
        return !renderingForOrgType(profile.policy.orgType) // ! if is redered for different org type than mine, I've assumed role of that org
      }
      return false
    },

    // ! use if you want to check either original or assumed role
    renderForOrgType: (type: TOrgType) =>
      policyOrgType(type) ||
      (policyUserType('SUPPORT') && renderingForOrgType(type)),

    renderForUserType: (type: TUserType) => policyUserType(type),

    getOrgId: () => {
      if (renderFor && renderFor.orgId) return renderFor.orgId
      return ''
    },
  }
}
export default useCredentials
