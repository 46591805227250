import { ActionsObservable } from 'redux-observable'
import { of } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import {
  add,
  addToList,
  changeUserPassword,
  fetchMoreUsers,
  fetchUsers,
  populateList,
  remove,
  removeFromList,
  replace,
  replaceOnList,
  selectUser,
} from '../actions/user.actions'
import { AppState } from '../store'
import { IUser, TUserActions } from '../types'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'
import { UIActions } from '../reducers/ui.reducer'

const fetchUsersEpic = (action$: ActionsObservable<TUserActions>) =>
  action$.pipe(
    filter(isActionOf([fetchUsers, fetchMoreUsers])),
    debounceTime(500),
    switchMap(action => {
      let url = process.env.REACT_APP_API_ACCOUNTS + `/${action.payload}/user`

      if (action.meta.query) {
        action.meta.query.forEach(q => {
          url += `${process.env.REACT_APP_AMPERSAND_SYMBOL}${q.key}=${encodeURI(
            q.value
          )}`
        })
      }

      return ajax.getJSON(url).pipe(
        map((response: any) =>
          populateList((response && (response.data as IUser[])) || [])
        ),
        catchError((error: AjaxError) => of(broadcastAjaxErrors(error)))
      )
    })
  )

const postUserEpic = (action$: ActionsObservable<TUserActions>) => {
  return action$.pipe(
    filter(isActionOf(add)),

    mergeMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS + `/${action.meta.orgid}/user`
      return ajax
        .post(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap(response => [
            addToList(response.response as IUser),
            broadcastAjaxSuccess('User record saved'),
            selectUser(response.response as IUser),
            UIActions.uiSetEditDrawerBusy(false),
          ]),
          catchError((error: AjaxError) =>
            of(broadcastAjaxErrors(error), UIActions.uiSetEditDrawerBusy(false))
          )
        )
    })
  )
}

const putUserEpic = (action$: ActionsObservable<TUserActions>) =>
  action$.pipe(
    filter(isActionOf(replace)),
    switchMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS +
        `/${action.meta.orgid}/user/${action.payload.userNo}`
      return ajax
        .put(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap(() => [
            replaceOnList(action.payload),
            selectUser(action.payload),
            broadcastAjaxSuccess('User record updated'),
            UIActions.uiSetEditDrawerBusy(false),
          ]),

          catchError((error: AjaxError) =>
            of(broadcastAjaxErrors(error), UIActions.uiSetEditDrawerBusy(false))
          )
        )
    })
  )

const deleteUserEpic = (
  action$: ActionsObservable<TUserActions>,
  state: AppState
) =>
  action$.pipe(
    filter(isActionOf(remove)),
    mergeMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS +
        `/${action.meta.orgid}/user/${action.payload}`
      return ajax.delete(url).pipe(
        mergeMap(() => [
          broadcastAjaxSuccess('User record has been deleted'),
          removeFromList(action.payload),
          UIActions.uiSetEditDrawerBusy(false),
          selectUser(null),
        ]),
        catchError((error: AjaxError) =>
          of(broadcastAjaxErrors(error), UIActions.uiSetEditDrawerBusy(false))
        )
      )
    })
  )

const changeUserPasswordEpic = (action$: ActionsObservable<TUserActions>) =>
  action$.pipe(
    filter(isActionOf(changeUserPassword)),
    switchMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS +
        `/${action.payload}/user/actions/changePassword`
      return ajax
        .put(url, action.meta, {
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap(() => [
            broadcastAjaxSuccess('Password succesfully updated!'),
            UIActions.uiSetWaitingForServer(false),
          ]),

          catchError((error: AjaxError) =>
            of(
              broadcastAjaxErrors(error),
              UIActions.uiSetWaitingForServer(false)
            )
          )
        )
    })
  )

export const userEpics = [
  postUserEpic,
  fetchUsersEpic,
  putUserEpic,
  deleteUserEpic,
  changeUserPasswordEpic,
]
