import {
  Box,
  Button,
  createStyles,
  Drawer,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core'
import {
  EmailOutlined,
  ImportExport,
  ListAlt,
  History,
} from '@material-ui/icons'
import { navigate } from '@reach/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ComponentWithRoutes } from '../../pages/types'
import { exportInvoice } from '../../redux/actions/invoice.actions'
import { InvoiceActions } from '../../redux/reducers/invoice.reducer'
import { AppState } from '../../redux/store'
import CommanderView from '../commander-vew'
import FloatingCardPanel from '../floating-card-panel'
import InvoiceDataView from './invoice-data-view'
import InvoiceList from './invoice-list'
import InvoiceMessageView from './invoice-message-view'
import InvoicePdfView from './invoice-pdf-view'
import InvoiceHistory from './invoice-history'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noData: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    bottomAppbar: {
      padding: theme.spacing(1),
      position: 'sticky',
      bottom: 0,
      background: theme.palette.background.paper,
      justifyContent: 'space-between',
      '& button': {
        marginRight: '1em',
      },
    },
    messagePane: {
      width: '45vw',
    },
    listDrawer: {
      height: '50vh',
    },
    listButton: {},
    panelsLeft: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(0.5),
    },
    panelsRight: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
  })
)

interface Props {
  buyerId?: string
  invoiceId?: string
}
const Invoice = (props: Props & ComponentWithRoutes) => {
  const classes = useStyles()
  const { buyerId, invoiceId } = props
  const dispatch = useDispatch()
  const [showMessage, setshowMessage] = React.useState<boolean>(false)
  const invoice = useSelector((state: AppState) => state.invoices.selected)
  const handleExport = () => {
    if (invoice && buyerId) dispatch(exportInvoice(invoice.oiid, buyerId))
  }
  const getInvoice = () => {
    if (buyerId && invoiceId) {
      dispatch(InvoiceActions.getInvoice(invoiceId, buyerId))
    }
  }
  const [showList, setshowList] = React.useState<boolean>(false)
  const [showHistory, setShowHistory] = React.useState(false)

  const NoData = () => {
    return (
      <Box className={classes.noData}>
        <Typography variant="h1" display="inline">
          No Data!
        </Typography>
      </Box>
    )
  }

  React.useEffect(() => {
    getInvoice()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceId, buyerId])

  const InvoiceData = () => {
    if (invoice) {
      return <InvoiceDataView invoice={invoice} />
    }
    return <NoData />
  }

  const InvoicePdf = () => {
    if (invoice !== undefined && invoice.message !== undefined) {
      if (invoice.message.pdfUrl) {
        const url = `${process.env.REACT_APP_API_ENDPOINT}/${invoice.message.pdfUrl}`
        return <InvoicePdfView url={url} />
      }
      if (invoice.message.htmlBody) {
        return <InvoiceMessageView message={invoice.message} />
      }
    }
    return <NoData />
  }

  return (
    <>
      <CommanderView
        defaultLeftSize={30}
        defaultRightSize={70}
        hideLeftOnResize
      >
        <FloatingCardPanel className={classes.panelsLeft}>
          <InvoicePdf />
        </FloatingCardPanel>
        <FloatingCardPanel className={classes.panelsRight}>
          <InvoiceData />
          <Toolbar disableGutters className={classes.bottomAppbar}>
            {invoice && invoice.document && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ImportExport />}
                  onClick={handleExport}
                >
                  Export
                </Button>
                <Button
                  onClick={() => setShowHistory(true)}
                  startIcon={<History />}
                >
                  History
                </Button>
              </>
            )}
            {invoice &&
              invoice.message &&
              invoice.message.htmlBody &&
              invoice.message.pdfUrl && (
                <Button
                  startIcon={<EmailOutlined />}
                  onClick={() => setshowMessage(true)}
                >
                  Show Original Email
                </Button>
              )}
            <Button
              className={classes.listButton}
              onClick={() => setshowList(true)}
              startIcon={<ListAlt />}
            >
              Show List
            </Button>
          </Toolbar>
        </FloatingCardPanel>
      </CommanderView>
      {invoice && invoice.message && invoice.message.htmlBody && (
        <Drawer
          anchor="left"
          open={showMessage}
          onClose={() => setshowMessage(false)}
          classes={{
            paper: classes.messagePane,
          }}
        >
          <InvoiceMessageView message={invoice.message} />
        </Drawer>
      )}
      <Drawer
        anchor="bottom"
        onClose={() => setshowList(false)}
        open={showList}
        classes={{
          paper: classes.listDrawer,
        }}
      >
        <InvoiceList
          path="/app/invoices"
          navigate={(path: any) => navigate('/app/invoices/' + path)}
          keepList={true}
          selectedLineId={invoice ? invoice.oiid : undefined}
        />
      </Drawer>
      {invoiceId && buyerId && (
        <Drawer
          anchor="bottom"
          onClose={() => setShowHistory(false)}
          open={showHistory}
          classes={{
            paper: classes.listDrawer,
          }}
        >
          <InvoiceHistory buyerId={buyerId} invoiceId={invoiceId} />
        </Drawer>
      )}
    </>
  )
}

export default Invoice
