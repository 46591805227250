import { createSlice, PayloadAction, createAction } from 'redux-starter-kit'
import {
  IPropertiesStore,
  IOrgProperty,
  IOtherOrgProperty,
  IReceivingEmailProperty,
  IEDIExportProperty,
  ISenderSourceProperty,
} from '../types'

const initialData: IPropertiesStore = {
  ediExportList: [],
  orgPropertyList: [],
  otherOrgPropertyList: [],
  receivingEmailList: [],
  senderSourceList: [],
}

const accountProps = createSlice({
  name: 'accountProps',
  initialState: initialData,
  reducers: {
    addToList: (state, action: PayloadAction<IOrgProperty>) => {
      state.orgPropertyList.push(action.payload)
    },
    addToOtherList: (state, action: PayloadAction<IOtherOrgProperty>) => {
      state.otherOrgPropertyList.push(action.payload)
    },
    addToReceivingdEmailList: (
      state,
      action: PayloadAction<IReceivingEmailProperty>
    ) => {
      state.receivingEmailList.push(action.payload)
    },
    addToSenderSourceList: (
      state,
      action: PayloadAction<ISenderSourceProperty>
    ) => {
      state.senderSourceList.push(action.payload)
    },
    replaceOnList: (state, action: PayloadAction<IOrgProperty>) => {
      state.orgPropertyList = state.orgPropertyList.map(p => {
        if (p.orgPropertyNo === action.payload.orgPropertyNo) {
          return action.payload
        }
        return p
      })
    },
    replaceOtherOnList: {
      prepare: (
        newProperty: IOtherOrgProperty,
        oldProperty: IOtherOrgProperty
      ) => ({
        payload: newProperty,
        meta: { oldProperty },
      }),
      reducer: (
        state,
        action: PayloadAction<
          IOtherOrgProperty,
          'orgproperties/replace_other_on_list',
          { oldProperty: IOtherOrgProperty }
        >
      ) => {
        state.otherOrgPropertyList = [
          ...state.otherOrgPropertyList.map(o => {
            const old = action.meta.oldProperty
            if (
              o.property === old.property &&
              o.value === old.value &&
              o.otherOrgUniqueKey === old.otherOrgUniqueKey
            ) {
              return action.payload
            }
            return o
          }),
        ]
      },
    },
    replaceOnReceivingEmailList: (
      state,
      action: PayloadAction<IReceivingEmailProperty>
    ) => {
      state.receivingEmailList = [
        ...state.receivingEmailList.map(p => {
          if (p.id === action.payload.id) {
            return action.payload
          }
          return p
        }),
      ]
    },
    replaceSenderSourceOnList: (
      state,
      action: PayloadAction<ISenderSourceProperty>
    ) => {
      state.senderSourceList = [
        ...state.senderSourceList.map(p => {
          if (p.id === action.payload.id) {
            return action.payload
          }
          return p
        }),
      ]
    },
    removeFromList: (state, action: PayloadAction<number>) => {
      state.orgPropertyList = state.orgPropertyList.filter(
        p => p.orgPropertyNo !== action.payload
      )
    },
    removeOtherFromList: (state, action: PayloadAction<IOtherOrgProperty>) => {
      state.otherOrgPropertyList = [
        ...state.otherOrgPropertyList.filter(
          p =>
            p.property !== action.payload.property ||
            p.value !== action.payload.value ||
            p.otherOrgUniqueKey !== action.payload.otherOrgUniqueKey
        ),
      ]
    },
    removeReceivingEmailFromList: (state, action: PayloadAction<number>) => {
      state.receivingEmailList = [
        ...state.receivingEmailList.filter(p => p.id !== action.payload),
      ]
    },
    removeSenderSourceFromList: (state, action: PayloadAction<number>) => {
      state.senderSourceList = [
        ...state.senderSourceList.filter(p => p.id !== action.payload),
      ]
    },
    populateList: {
      reducer: (
        state,
        action: PayloadAction<
          IOrgProperty[],
          'accountProps/populateList',
          { more: boolean }
        >
      ) => {
        if (action.meta.more) {
          state.orgPropertyList = [...state.orgPropertyList, ...action.payload]
        } else {
          state.orgPropertyList = action.payload
        }
      },
      prepare: (properties: IOrgProperty[], more: boolean) => ({
        payload: properties,
        meta: { more },
      }),
    },
    populateOtherList: {
      prepare: (properties: IOtherOrgProperty[], more: boolean) => ({
        payload: properties,
        meta: { more },
      }),
      reducer: (
        state,
        action: PayloadAction<
          IOtherOrgProperty[],
          'accountProps/populateOtherList',
          { more: boolean }
        >
      ) => {
        if (action.meta.more) {
          state.otherOrgPropertyList = [
            ...state.otherOrgPropertyList,
            ...action.payload,
          ]
        } else {
          state.otherOrgPropertyList = action.payload
        }
      },
    },
    populateReceivingEmailList: (
      state,
      action: PayloadAction<IReceivingEmailProperty[]>
    ) => {
      state.receivingEmailList = action.payload
    },
    populateEDIExportList: (
      state,
      action: PayloadAction<IEDIExportProperty[]>
    ) => {
      state.ediExportList = action.payload
    },
    populateSenderSourceList: (
      state,
      action: PayloadAction<ISenderSourceProperty[]>
    ) => {
      state.senderSourceList = action.payload
    },
  },
})

//! for epics
const EpicActions = {
  addOrgProperty: createAction(
    'orgproperty/ADD',
    (property: IOrgProperty, orgid: string) => ({
      payload: property,
      meta: { orgid },
    })
  ),
  addOtherOrgProperty: createAction(
    'orgproperty/ADD_OTHER',
    (property: IOtherOrgProperty, myOrgId: string) => ({
      payload: property,
      meta: {
        myOrgId,
      },
    })
  ),
  addReceivingEmail: createAction(
    'orgproperty/ADD_RECEIVING_EMAIL',
    (property: IReceivingEmailProperty, myOrgId: string) => ({
      payload: property,
      meta: { myOrgId },
    })
  ),
  addSenderSource: createAction(
    'orgproperty/ADD_SENDER_SOURCE',
    (property: ISenderSourceProperty, myOrgId: string) => ({
      payload: property,
      meta: { myOrgId },
    })
  ),
  removeOrgProperty: createAction(
    'orgproperty/DELETE',
    (propertyId: number, orgid: string) => ({
      payload: propertyId,
      meta: { orgid },
    })
  ),
  removeOtherOrgProperty: createAction(
    'orgproperty/DELETE_OTHER',
    (property: IOtherOrgProperty, myOrgId: string) => ({
      payload: property,
      meta: { myOrgId },
    })
  ),
  removeReceivingEmail: createAction(
    'orgproperty/DELETE_RECEIVING_EMAIL',
    (id: number, myOrgId: string) => ({ payload: id, meta: { myOrgId } })
  ),
  removeSenderSource: createAction(
    'orgproperty/DELETE_SENDER_SOURCE',
    (id: number, myOrgId: string) => ({ payload: id, meta: { myOrgId } })
  ),
  replaceOrgProperty: createAction(
    'orgproperty/REPLACE',
    (property: IOrgProperty, orgid: string) => ({
      payload: property,
      meta: { orgid },
    })
  ),
  replaceOtherOrgProperty: createAction(
    'orgproperty/REPLACE_OTHER',
    (
      property: IOtherOrgProperty,
      myOrgId: string,
      oldproperty: IOtherOrgProperty
    ) => ({
      payload: property,
      meta: { myOrgId, oldproperty },
    })
  ),
  replaceReceivingEmail: createAction(
    'orgproperty/REPLACE_RECEIVING_EMAIL',
    (property: IReceivingEmailProperty, myOrgId: string) => ({
      payload: property,
      meta: { myOrgId },
    })
  ),
  replalceSenderSource: createAction(
    'orgproperty/REPLACE_SENDER_SOURCE',
    (property: ISenderSourceProperty, myOrgId: string) => ({
      payload: property,
      meta: { myOrgId },
    })
  ),
  fetchProperties: createAction(
    'orgproperty/FETCH',
    (
      orgid: string,
      meta: {
        more: boolean // instead of separate actions, if _more_ we are adding to existing list instead of replacing
        offset: number
        limit: number
        propertyLike?: string
        valueLike?: string
      }
    ) => ({
      payload: orgid,
      meta,
    })
  ),
  fetchOtherProperties: createAction(
    'orgproperty/FETCH_OTHER',
    (
      orgid: string,
      meta: { more: boolean; limit: number; offset: number; otherOrgId: string }
    ) => ({
      payload: orgid,
      meta,
    })
  ),
  fetchReceivingEmails: createAction<string>(
    'orgproperty/FETCH_RECEIVING_EMAILS'
  ),
  fetchEDIExports: createAction<string>(
    'orgproperty/FETCH_EDI_EXPORT_PROPERTIES'
  ),
  saveEDIExport: createAction(
    'orgproperty/SAVE_EDI_EXPORT',
    (property: IEDIExportProperty, orgId: string) => ({
      payload: property,
      meta: { myOrgId: orgId },
    })
  ),
  fetchSenderSources: createAction<string>('orgproperty/FETCH_SENDER_SOURCES'),
}

export default accountProps.reducer
export const AccountPropsActions = {
  ...accountProps.actions,
  ...EpicActions,
}
