import { IAsn, IAsnStore, IHistory } from '../types'
import { createAction, createSlice, PayloadAction } from 'redux-starter-kit'

const state: IAsnStore = {
  list: [],
  history: [],
}

const asns = createSlice({
  name: 'asns',
  initialState: state,

  reducers: {
    populateList: (state, action: PayloadAction<IAsn[]>) => {
      state.list = action.payload
    },
    appendToList: (state, action: PayloadAction<IAsn[]>) => {
      state.list = [...state.list, ...action.payload]
    },
    select: (state, action: PayloadAction<IAsn | null>) => {
      state.selected = action.payload
    },
    updateExportedFlag: (state, action: PayloadAction<string>) => {
      if (state.selected)
        state.selected = {
          ...state.selected,
          exported: true,
          waitingForAction: false,
        }
      state.list = [...state.list].map(a => {
        if (a.id === action.payload) {
          return { ...a, exported: true, waitingForAction: false }
        }
        return a
      })
    },
    populateHistory: (state, action: PayloadAction<IHistory[]>) => {
      state.history = action.payload
    },
  },
})

// ! for epics
const EpicActions = {
  export: createAction('ASN/export', (orgid: string, asnid: string) => {
    return {
      payload: orgid,
      meta: { asnid },
    }
  }),
  getAsnHistory: createAction(
    'ASN_history/GET',
    (asnId: string, orgId: string, renderFor: string, type: string) => {
      return {
        payload: asnId,
        meta: { orgId, renderFor, type },
      }
    }
  ),
}

export default asns.reducer
export const AsnActions = {
  ...asns.actions,
  ...EpicActions,
}
