import React from 'react'
import {
  Box,
  makeStyles,
  Theme,
  createStyles,
  Drawer,
  Typography,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core'
import ChangePassword from '../components/settings/change-password'
import CloseIcon from '@material-ui/icons/Close'
import ThemeSelector from '../components/user-profile/theme-selector'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: '30vw',
      height: 'calc(100vh - 64px)',
      marginTop: '64px',
      padding: '1em',
      [theme.breakpoints.down('md')]: {
        width: '65vw',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
      },
    },
    titlebox: {
      position: 'sticky',
      top: '-1em',
      backgroundColor: theme.palette.background.paper,
      zIndex: theme.zIndex.appBar,
    },
  })
)

type Props = {
  openSettings: boolean
  setOpenSettings: (open: boolean) => void
}

const Settings = (props: Props) => {
  const classes = useStyles()

  const {openSettings, setOpenSettings} = props

  const [tab, setTab] = React.useState('password')

  return (
    <div>
      <Drawer
        anchor="right"
        open={openSettings}
        onClose={() => setOpenSettings(false)}
        PaperProps={{elevation: 8}}
        classes={{
          paper: classes.drawer,
        }}
      >
        <Box
          className={classes.titlebox}
          justifyContent="space-between"
          alignItems="center"
          display="flex"
        >
          <Typography variant="h5">Settings</Typography>

          <Tooltip title="Close">
            <IconButton onClick={() => setOpenSettings(false)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Options */}
        <Tabs value={tab}
          onChange={(event: React.ChangeEvent<{}>, newValue: string) => setTab(newValue)}
        >
          <Tab label="Change Password" value="password" />
          <Tab label="Theme" value="theme" />
        </Tabs>
        {
          tab === 'password' &&
          <ChangePassword />
        }
        {
          tab === "theme" &&
          <ThemeSelector />
        }
      </Drawer>
    </div>
  )
}
export default Settings
