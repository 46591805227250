import React from 'react'
import {
  IAccount,
  IEDIExportProperty,
  IEDIExportType,
} from '../../../redux/types'
import {
  Box,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core'
import scss from './edit-edi-export-props.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
import { TextFieldProps } from '@material-ui/core/TextField'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'
import { AppState } from '../../../redux/store'

const emptyProp: IEDIExportProperty = {
  docType: 'inv',
  orderFormat: 0,
  type: 'LOCAL',
  ftpServer: '',
  ftpDir: '',
  user: '',
  password: '',
  endpoint: '',
}

interface Props {
  account: IAccount
}
const EditEdiExportProps = (props: Props) => {
  const { account } = props
  const [po, setPo] = React.useState<IEDIExportProperty>({
    ...emptyProp,
    docType: 'po',
  })
  const [inv, setInv] = React.useState<IEDIExportProperty>({
    ...emptyProp,
    docType: 'inv',
  })
  const [asn, setAsn] = React.useState<IEDIExportProperty>({
    ...emptyProp,
    docType: 'asn',
  })

  const dispatch = useDispatch()
  const list = useSelector(
    (state: AppState) => state.accountProperties.ediExportList
  )

  React.useEffect(() => {
    dispatch(AccountPropsActions.fetchEDIExports(account.uniqueKey))
    return () => {
      dispatch(AccountPropsActions.populateEDIExportList([]))
    }
  }, [account])

  const reset = () => {
    setInv({ ...emptyProp, docType: 'inv' })
    setPo({ ...emptyProp, docType: 'po' })
    setAsn({ ...emptyProp, docType: 'asn' })
  }
  React.useEffect(() => {
    reset()

    list.forEach(l => {
      switch (l.docType) {
        case 'inv':
          setInv(l)
          break

        case 'po':
          setPo(l)
          break

        case 'asn':
          setAsn(l)
          break
      }
    })
  }, [list])

  const handleSave = (property: IEDIExportProperty) => {
    dispatch(AccountPropsActions.saveEDIExport(property, account.uniqueKey))
  }

  return (
    <Box className={scss.root}>
      <Form
        title="EDI Export - Invoice"
        property={inv}
        handleSave={handleSave}
        setProperty={setInv}
      />

      <Form
        title="EDI Export - Purchase Order"
        property={po}
        handleSave={handleSave}
        setProperty={setPo}
      />

      <Form
        title="EDI Export - ASN"
        property={asn}
        handleSave={handleSave}
        setProperty={setAsn}
      />
    </Box>
  )
}
export default EditEdiExportProps

interface FormProps {
  title: string
  property: IEDIExportProperty
  setProperty: (property: IEDIExportProperty) => void
  handleSave: (property: IEDIExportProperty) => void
}
const Form = (props: FormProps) => {
  const { property, setProperty } = props

  const handleSave = () => {
    props.handleSave(property)
  }

  return (
    <Box className={scss.ediexport}>
      <Typography className={scss.title} variant="h2">
        {props.title}
      </Typography>
      <FormControl className={scss.orderFormat}>
        <NumberFormat
          customInput={OrderFormatField}
          value={property.orderFormat}
          decimalScale={0}
          onChange={e => {
            setProperty({ ...property, orderFormat: parseInt(e.target.value) })
          }}
        />
      </FormControl>
      <FormControl className={scss.type}>
        <InputLabel>Type</InputLabel>
        <Select
          value={property.type}
          onChange={e => {
            setProperty({ ...property, type: e.target.value as IEDIExportType })
          }}
        >
          <MenuItem value="S3">S3</MenuItem>
          <MenuItem value="LOCAL">LOCAL</MenuItem>
          <MenuItem value="EMAIL">EMAIL</MenuItem>
          <MenuItem value="cXML">cXML</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={scss.endpoint}>
        <TextField
          label="Endpoint"
          value={property.endpoint}
          onChange={e => {
            setProperty({ ...property, endpoint: e.target.value })
          }}
        />
      </FormControl>
      <FormControl className={scss.ftpServer}>
        <TextField
          label="FTP Server"
          value={property.ftpServer}
          onChange={e => {
            setProperty({ ...property, ftpServer: e.target.value })
          }}
        />
      </FormControl>
      <FormControl className={scss.ftpDir}>
        <TextField
          label="FTP Directory"
          value={property.ftpDir}
          onChange={e => {
            setProperty({ ...property, ftpDir: e.target.value })
          }}
        />
      </FormControl>
      <FormControl className={scss.user}>
        <TextField
          label="User"
          value={property.user}
          onChange={e => {
            setProperty({ ...property, user: e.target.value })
          }}
        />
      </FormControl>
      <FormControl className={scss.password}>
        <TextField
          label="Password"
          value={property.password}
          onChange={e => {
            setProperty({ ...property, password: e.target.value })
          }}
        />
      </FormControl>
      <Box className={scss.buttons}>
        <Button color="primary" variant="outlined" onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Box>
  )
}

const OrderFormatField = (props: TextFieldProps) => {
  const { label, ...rest } = props
  return <TextField label="Order Format" {...rest} />
}
