import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import { PricelistActions } from '../../redux/reducers/pricelist.reducer'
import { AppState } from '../../redux/store'
import { IPricelist, IPricelistPrice } from '../../redux/types'
import FormatCurrencyText from '../utils/format-currency-text'

interface Props {
  pricelist: IPricelist
}
const PriceListPrices = (props: Props) => {
  const { pricelist } = props
  const dispatch = useDispatch()
  const credentials = useCredentials()

  const cursor = useSelector((state: AppState) => state.pricelist.pricesCursor)
  const [limit] = React.useState<number>(20)

  const list: IPricelistPrice[] = useSelector(
    (state: AppState) => state.pricelist.prices
  )

  const [ref, inView] = useInView()
  const getInvViewRef = (position: number) => {
    if (
      position === list.length &&
      list.length >= limit &&
      cursor !== undefined
    ) {
      return ref
    }
    return null
  }

  React.useEffect(() => {
    if (inView) {
      dispatch(
        PricelistActions.getPricelistPrices(
          pricelist.pricelistId!,
          credentials.getOrgId(),
          true,
          cursor
        )
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  React.useEffect(() => {
    dispatch(
      PricelistActions.getPricelistPrices(
        pricelist.pricelistId!,
        credentials.getOrgId(),
        false,
        cursor
      )
    )
    return () => {
      dispatch(PricelistActions.populatePricelistPrices([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricelist])

  return (
    <Table stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell>Product Code</TableCell>
          <TableCell>UOM</TableCell>
          <TableCell>Start Date</TableCell>
          <TableCell>Lower</TableCell>
          <TableCell>Upper</TableCell>
          <TableCell>Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((l, i) =>
          l.prices.map((p, pi) => (
            <TableRow key={i} ref={getInvViewRef(i + 1)} hover>
              <TableCell>{l.productCode}</TableCell>
              <TableCell>{p.uom}</TableCell>
              <TableCell>{p.startDate}</TableCell>
              <TableCell>{p.lowerQuantity}</TableCell>
              <TableCell>{p.upperQuantity}</TableCell>
              <TableCell>
                <FormatCurrencyText value={p.price} />
              </TableCell>
            </TableRow>
          ))
        )}
      </TableBody>
    </Table>
  )
}
export default PriceListPrices
