import React from 'react'
import { useDispatch } from 'react-redux'
import PaperMain from '../components/paper-main'
import ProductProfile from '../components/products/product-profile'
import { changeTitle } from '../redux/actions/header.actions'
import { IAccount, IProductFromAPI } from '../redux/types'
import { ComponentWithRoutes } from './types'

type Props = {
  productId?: string
  sellerId?: string
  buyerId?: string
}
const Product = (props: ComponentWithRoutes & Props) => {
  const { productId, sellerId, buyerId } = props
  const [product, setProduct] = React.useState<IProductFromAPI | undefined>(
    undefined
  )
  const [seller, setSeller] = React.useState<IAccount | undefined>(undefined)

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (productId && sellerId) {
      fetchProduct()
      if (buyerId) {
        fetchSeller()
      }
    }
  }, [productId])

  const fetchProduct = async () => {
    const url = buyerId
      ? `${process.env.REACT_APP_API_BUYER}/${buyerId}/seller/${sellerId}/product/${productId}`
      : `${process.env.REACT_APP_API_SELLER}/${sellerId}/product/${productId}`

    const res = await fetch(url)
    if (res.ok) {
      const prod = await res.json()

      if (prod) {
        setProduct(prod as IProductFromAPI)
        dispatch(changeTitle(`Product: ${prod.product.productCode}`))
        document.title = prod.product.productCode
      }
    }
  }

  const fetchSeller = async () => {
    if (sellerId) {
      const url = `${process.env.REACT_APP_API_ACCOUNTS}/${sellerId}`
      const res = await fetch(url)
      if (res.ok) {
        const s: IAccount = await res.json()
        if (s && s.uniqueKey === sellerId) {
          setSeller(s)
        }
      }
    }
  }

  if (product) {
    return (
      <PaperMain>
        <ProductProfile product={product} buyerId={buyerId} seller={seller} />
      </PaperMain>
    )
  }

  return null
}

export default Product
