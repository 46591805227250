import { combineReducers } from 'redux-starter-kit'
import accountProperties from './account-properties.reducer'
import { accountsList, selectedAccount } from './accounts.reducer'
import { messages } from './feedback.reducer'
import { headerTitleState } from './header-title.reducer'
import orders from './order.reducer'
import asns from './asn.reducer'
import pricelist from './pricelist.reducer'
import { products } from './products.reducer'
import { profile } from './profile.reducer'
import ui from './ui.reducer'
import { selectedUser, userList } from './users.reducer'
import categoryTree from './category.reducer'
import invoices from './invoice.reducer'
export default combineReducers({
  headerTitleState,
  accountsList,
  selectedAccount,
  userList,
  selectedUser,
  messages,
  profile,
  accountProperties,
  ui,
  orders,
  products,
  asns,
  pricelist,
  categoryTree,
  invoices,
})
