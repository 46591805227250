import { Box, Button, FormControl, TextField } from '@material-ui/core'
import React from 'react'
import { IAccount, IOtherOrgProperty } from '../../../redux/types'
import scss from './buyer-seller-props.module.scss'
import { otherOrgProperties, genUrl } from '../../../API'
import { useDispatch } from 'react-redux'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'

interface Props {
  account: IAccount
  myOrgId: string
}
const BuyerSellerAccountCodes = (props: Props) => {
  const { account, myOrgId } = props
  const dispatch = useDispatch()

  const [posn, setPosn] = React.useState<IOtherOrgProperty>({
    property: 'SUPPLIER_CODE',
    value: '',
    otherOrgUniqueKey: account.uniqueKey,
  })

  const [inv, setInv] = React.useState<IOtherOrgProperty>({
    property: 'SUPPLIER_CODE_AP',
    value: '',
    otherOrgUniqueKey: account.uniqueKey,
  })

  const handleChangePosn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPosn({ ...posn, value: e.target.value })
  }
  const handleChangeInv = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInv({ ...inv, value: e.target.value })
  }

  const handleSavePosn = () => {
    dispatch(AccountPropsActions.addOtherOrgProperty(posn, myOrgId))
  }
  const handleSaveInv = () => {
    dispatch(AccountPropsActions.addOtherOrgProperty(inv, myOrgId))
  }

  const handleReset = () => {
    setPosn({ ...posn, value: '' })
    setInv({ ...inv, value: '' })
  }

  React.useEffect(() => {
    fetchProps('SUPPLIER_CODE_AP')
    fetchProps('SUPPLIER_CODE')
    return () => {
      handleReset()
    }
  }, [account, myOrgId])

  const fetchProps = async (type: 'SUPPLIER_CODE_AP' | 'SUPPLIER_CODE') => {
    const url = genUrl(
      otherOrgProperties.getOne,
      { orgid: myOrgId },
      {
        otherOrgUniqueKey: account.uniqueKey,
        offset: 0,
        limit: 1,
        property: type,
      }
    )

    const res1 = await fetch(url)
    if (res1.ok) {
      const j: any = await res1.json()
      if (j && j.data) {
        const prop: IOtherOrgProperty = j.data[0] //? pick first
        if (prop) {
          switch (type) {
            case 'SUPPLIER_CODE_AP':
              setInv(prop)
              break
            case 'SUPPLIER_CODE':
              setPosn(prop)
              break
          }
        }
      }
    }
  }

  return (
    <Box className={scss.root}>
      <FormControl className={scss.formControlWithMargin}>
        <TextField
          value={posn.value}
          onChange={handleChangePosn}
          label="PO & ASN Account Code"
          className={scss.input}
        />
        <Button
          onClick={handleSavePosn}
          color="primary"
          variant="contained"
          disabled={posn.value === ''}
        >
          Save
        </Button>
      </FormControl>
      <FormControl className={scss.formControlWithMargin}>
        <TextField
          label="Invoice Account Code"
          className={scss.input}
          value={inv.value}
          onChange={handleChangeInv}
        />
        <Button
          onClick={handleSaveInv}
          color="primary"
          variant="contained"
          disabled={inv.value === ''}
        >
          Save
        </Button>
      </FormControl>
    </Box>
  )
}
export default BuyerSellerAccountCodes
