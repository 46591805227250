import { ADD_FEEDBACK_MESSAGE, RESET_FEEDBACK_MESSAGE, TFeedbackTypes } from '../actions/feedback.actions'
import { IFeedbackMessages } from '../types'

const initialState: IFeedbackMessages = {
  current: { message: 'Welcome!', variant: 'info' },
  history: []
}

export const messages = (
  state = initialState,
  action: TFeedbackTypes
): IFeedbackMessages => {
  switch (action.type) {
    case ADD_FEEDBACK_MESSAGE:
      const m = {
        ...action.payload,
        timestamp: action.payload.timestamp || new Date().toISOString()
      }
      return {
        history: [...state.history, state.current],
        current: m
      }
    case RESET_FEEDBACK_MESSAGE:
      return initialState
    default:
      return state
  }
}
