import { Box, FormControlLabel, Grid, Switch, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import { ChromePicker } from 'react-color'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../redux/store'
import { UIActions } from '../../redux/reducers/ui.reducer'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    grid: { marginBottom: theme.spacing(3) },
  })
)

interface Props {}
const ThemeSelector = (props: Props) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const palette = useSelector((state: AppState) => state.ui.palette)

  const [primary, setPrimary] = React.useState(
    palette.primary.main || '#10364C'
  )
  const [secondary, setSecondary] = React.useState(
    palette.secondary.main || '#B6534E'
  )
  const [type, setType] = React.useState(palette.type || 'light')
  const toggleLights = () => {
    setType(prev => (prev === 'light' ? 'dark' : 'light'))
  }

  React.useEffect(() => {
    const palette = {
      type: type,
      primary: { main: primary },
      secondary: { main: secondary },
    }
    dispatch(UIActions.uiChangePalette(palette))
    localStorage.setItem('palette', JSON.stringify(palette))
  }, [primary, secondary, type])

  return (
    <Box paddingTop="2em">
      <FormControlLabel
        control={
          <Switch
            checked={type === 'dark'}
            onChange={toggleLights}
            color="primary"
          />
        }
        label="Dark Mode"
      />
      <Grid container className={classes.grid}>
        <Grid item xs={4}>
          Primary Colour
        </Grid>
        <Grid item xs={8} justify="center">
          <ChromePicker
            disableAlpha={true}
            color={primary}
            onChangeComplete={color => setPrimary(color.hex)}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.grid}>
        <Grid item xs={4}>
          Secondary Colour
        </Grid>
        <Grid item xs={8} justify="center">
          <ChromePicker
            disableAlpha={true}
            color={secondary}
            onChangeComplete={color => setSecondary(color.hex)}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ThemeSelector
