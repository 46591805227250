import { ActionsObservable } from 'redux-observable'
import { of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { replaceUOMMapping } from '../actions/uom.actions'
import { TUOMActions } from '../types'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'
import { UIActions } from '../reducers/ui.reducer'

const postUOMMappingEpic = (action$: ActionsObservable<TUOMActions>) =>
  action$.pipe(
    filter(isActionOf(replaceUOMMapping)),

    switchMap(action => {
      const url = `${process.env.REACT_APP_API_SELLER}/${action.meta.orgid}/uom`
      return ajax
        .put(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap(() => [
            broadcastAjaxSuccess('UOM mapping saved'),
            UIActions.uiSetEditDrawerBusy(false),
          ]),
          catchError(error =>
            of(broadcastAjaxErrors(error), UIActions.uiSetEditDrawerBusy(false))
          )
        )
    })
  )

export const uomEpics = [postUOMMappingEpic]
