import { Epic } from 'redux-observable'
import { ICategoryTreeNode } from '../types'
import { AnyAction } from 'redux'
import { filter, switchMap, catchError, flatMap } from 'rxjs/operators'
import { CategoryActions } from '../reducers/category.reducer'
import { ajax } from 'rxjs/ajax'
import { broadcastAjaxErrors } from './utils'
import { UIActions } from '../reducers/ui.reducer'

const fetchCategoryTree: Epic<AnyAction> = action$ =>
  action$.pipe(
    filter(CategoryActions.getCategoryTree.match),
    switchMap(action => {
      const url = `${process.env.REACT_APP_API_BUYER}/${action.payload}/category-tree`
      return ajax.getJSON(url).pipe(
        flatMap(response => [
          CategoryActions.addToStore(response as ICategoryTreeNode),
          UIActions.uiSetFetching('categories', false),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

export const categoryEpics = [fetchCategoryTree]
