import { createSlice, PayloadAction } from 'redux-starter-kit'
import { IUIRenderFor, IUIStore, IUIPalette } from '../types'

const defaultPalette = () => {
  const saved = localStorage.getItem('palette')
  if (saved) return JSON.parse(saved)

  return {
    type: 'light',
    primary: {
      main: '#10364C',
    },
    secondary: {
      main: '#B6534E',
    },
  }
}

const defaultRenderFor = () => {
  const saved = localStorage.getItem('renderFor')
  if (saved) return JSON.parse(saved) as IUIRenderFor
  return undefined
}

const state: IUIStore = {
  editDrawerBusy: false,
  waitingForServer: false,
  fetching: {},
  palette: defaultPalette(),
  renderFor: defaultRenderFor(),
}
const ui = createSlice({
  name: 'ui',
  initialState: state,

  reducers: {
    uiSetEditDrawerBusy: (state: IUIStore, action: PayloadAction<boolean>) => {
      state.editDrawerBusy = action.payload
    },
    uiSetWaitingForServer: (
      state: IUIStore,
      action: PayloadAction<boolean>
    ) => {
      state.waitingForServer = action.payload
    },
    uiRenderFor: (state: IUIStore, action: PayloadAction<IUIRenderFor>) => {
      state.renderFor = action.payload
    },
    uiSwapRenderFor: (state, action: PayloadAction<any>) => {
      state.renderFor = { ...state.renderFor, ...action.payload }
    },
    uiSetFetching: {
      reducer: (
        state,
        action: PayloadAction<{ key: string; value: boolean }>
      ) => {
        state.fetching[action.payload.key] = action.payload.value
      },
      prepare: (key: string, value: boolean) => ({ payload: { key, value } }),
    },
    uiChangePalette: (state, action: PayloadAction<IUIPalette>) => {
      state.palette = action.payload
    },
  },
})

export default ui.reducer

export const UIActions = {
  ...ui.actions,
}
