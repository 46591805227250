import { Epic } from 'redux-observable'
import { ajax } from 'rxjs/ajax'
import {
  catchError,
  filter,
  flatMap,
  mergeMap,
  switchMap,
} from 'rxjs/operators'
import { PricelistActions } from '../reducers/pricelist.reducer'
import { UIActions } from '../reducers/ui.reducer'
import { IPricelist, IPricelistPrice } from '../types'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'
import genUrl from '../../API/gen-url'
import { pricelists } from '../../API/endpoints'

const fetchPricelists: Epic = action$ =>
  action$.pipe(
    filter(PricelistActions.getPricelists.match),
    switchMap(action => {
      const url = genUrl(
        pricelists.getList,
        {
          sellerId: action.payload,
        },
        {
          cursor: action.meta.cursor || '',
        }
      )
      return ajax.getJSON(url).pipe(
        mergeMap((response: any) => [
          action.meta.more
            ? PricelistActions.appendPricelistInStore(
                response ? (response.data as IPricelist[]) : [],
                response.cursor
              )
            : PricelistActions.populatePricelistInStore(
                response ? (response.data as IPricelist[]) : [],
                response.cursor
              ),
          UIActions.uiSetFetching('pricelist_list', false),
        ]),
        catchError(error => [
          broadcastAjaxErrors(error),
          UIActions.uiSetFetching('pricelist_list', false),
        ])
      )
    })
  )

const postPricelist: Epic = action$ =>
  action$.pipe(
    filter(PricelistActions.addPricelist.match),
    flatMap(action => {
      const url = genUrl(pricelists.postOne, {
        sellerId: action.meta.sellerId,
      })
      return ajax
        .post(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          flatMap(response => [
            broadcastAjaxSuccess('Pricelist name has been saved'),
            PricelistActions.addPricelistToStore(
              response.response as IPricelist
            ),
            PricelistActions.selectPriceList(response.response as IPricelist),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )

const putPricelist: Epic = action$ =>
  action$.pipe(
    filter(PricelistActions.modifyPricelist.match),
    flatMap(action => {
      const url = genUrl(pricelists.putOne, {
        sellerId: action.meta.sellerId,
        pricelistId: action.payload.pricelistId as string,
      })
      return ajax
        .put(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          flatMap(response => [
            broadcastAjaxSuccess('Pricelist modified.'),
            PricelistActions.modifyPricelistInStore(action.payload),
            PricelistActions.selectPriceList(action.payload),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )

const fetchPricelistPrices: Epic = action$ =>
  action$.pipe(
    filter(PricelistActions.getPricelistPrices.match),

    switchMap(action => {
      const url = genUrl(
        pricelists.prices.getList,
        {
          sellerId: action.meta.sellerId,
          pricelistId: action.payload,
        },
        {
          cursor: action.meta.cursor || '',
        }
      )
      return ajax.getJSON(url).pipe(
        flatMap((result: any) => [
          action.meta.more
            ? PricelistActions.appendPricelistPrices(
                result.data as IPricelistPrice[],
                result.cursor
              )
            : PricelistActions.populatePricelistPrices(
                result.data as IPricelistPrice[],
                result.cursor
              ),
        ]),
        catchError(error => [
          broadcastAjaxErrors(error),
          PricelistActions.populatePricelistPrices([]),
        ])
      )
    })
  )
export const pricelistEpics = [
  fetchPricelists,
  postPricelist,
  putPricelist,
  fetchPricelistPrices,
]
