import { Redirect, Router } from '@reach/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './App.css'
import Invoice from './components/invoices/invoice'
import InvoiceList from './components/invoices/invoice-list'
import ProductList from './components/products/product-list'
import useCredentials from './hooks/use-credentials'
import MasterPage from './layout/master-page'
import AccountsPage from './pages/accounts'
import AccountsList from './pages/accounts-list'
import Catalog from './pages/catalog'
import Invoices from './pages/invoices'
import LoginPage from './pages/login-page'
import Order from './pages/order'
import OrdersList from './pages/orders-list'
import OrderPage from './pages/orders-page'
import PricelistsPage from './pages/pricelists'
import Product from './pages/product'
import TermsOfUse from './pages/terms-of-use'
import UsersList from './pages/users-list'
import { fetchPolicy } from './redux/actions/profile.actions'
import { AppState } from './redux/store'
import PreferencesPage from './pages/preferences.page'
const App: React.FC = () => {
  const profile = useSelector((state: AppState) => state.profile)
  const dispatch = useDispatch()
  const credentials = useCredentials()
  useEffect(() => {
    dispatch(fetchPolicy())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!profile.policy) {
    return <LoginPage />
  }
  if (profile.policy && profile.policy.state === 'TERMS_OF_USE') {
    return <TermsOfUse path="/app/termsOfUse" />
  }
  return (
    <MasterPage>
      <Router>
        <Redirect from="/" to="/app/catalogue" noThrow />
        {/* <DashboardPage path="/app/dashboard" /> */}
        {credentials.isSupport() && (
          <AccountsPage path="/app/accounts">
            <AccountsList path="/" />
            <UsersList path="/:orgid/user" />
          </AccountsPage>
        )}
        <Catalog path="/app/catalogue">
          <ProductList path="/" />
        </Catalog>

        <PreferencesPage path="/app/preferences" />
        {(credentials.renderForOrgType('SUPPLIER') ||
          credentials.isSupport()) && (
          <PricelistsPage path="/app/pricelists"></PricelistsPage>
        )}
        <Product path="/app/product/:productId/seller/:sellerId/*" />
        <Product path="/app/product/:productId/seller/:sellerId/buyer/:buyerId" />
        <OrderPage path="/app/orders">
          <OrdersList path="/" />
          <Order path="/:orgid/order/:orderid" />
        </OrderPage>
        {(credentials.renderForOrgType('UNIVERSITY') ||
          credentials.isSupport()) && (
          <Invoices path="/app/invoices">
            <InvoiceList path="/" />
            <Invoice path="/:buyerId/:invoiceId" />
          </Invoices>
        )}
      </Router>
    </MasterPage>
  )
}

export default App
