import { IOrder, IHistory, IOrderStore } from '../types'
import { createAction, PayloadAction, createSlice } from 'redux-starter-kit'

const state: IOrderStore = {
  list: [],
  selectedHistory: [],
  query: {
    limit: 20,
    offset: 0,
  },
}

const orders = createSlice({
  name: 'orders',
  initialState: state,
  reducers: {
    select: (state, action: PayloadAction<IOrder>) => {
      state.selectedOrder = action.payload
    },
    populateHistory: (state, action: PayloadAction<IHistory[]>) => {
      state.selectedHistory = action.payload
    },
    populateList: (state, action: PayloadAction<IOrder[]>) => {
      state.list = action.payload
      if (action.payload.length === state.query.limit) {
        state.query.offset += state.query.limit
      }
    },
    extendList: (state, action: PayloadAction<IOrder[]>) => {
      state.list = state.list.concat(action.payload)
      if (action.payload.length === state.query.limit) {
        state.query.offset += state.query.limit
      }
    },
    markAsRead: (state, action: PayloadAction<string>) => {
      state.list = [...state.list].map(l => {
        if (l.id === action.payload) {
          return { ...l, read: true }
        }
        return l
      })
    },
  },
})

// export const orderList = createReducer<IOrder[], TOrderActions>([])
// .handleAction(populateList, (state, action) => {
//   return action.payload
// })
// .handleAction(extendList, (state, action) => {
//   return [...state, ...action.payload]
// })
// .handleAction(markAsRead, (state, action) => {
//   return state.map(s => {
//     if (s.id === action.payload) {
//       return { ...s, read: true }
//     }
//     return s
//   })
// })

// export const selectedOrder = createReducer<IOrder | null, TOrderActions>(
//   null
// ).handleAction(selectOrder, (state, action) => {
//   return action.payload
// })

// ! for epics
const EpicActions = {
  getOrders: createAction(
    'orders/GET',
    (
      orgid: string,
      offset: number,
      limit: number,
      append: boolean,
      renderFor: string,
      query?: { key: string; value: string }[]
    ) => {
      return {
        payload: orgid,
        meta: { offset, limit, append, renderFor, query },
      }
    }
  ),
  getOrder: createAction(
    'order/GET',
    (orgid: string, orderid: string, renderFor: string) => {
      return {
        payload: orgid,
        meta: { orderid, renderFor },
      }
    }
  ),
  getOrderHistory: createAction(
    'order_history/GET',
    (orderId: string, orgId: string, renderFor: string) => {
      return {
        payload: orderId,
        meta: { orgId, renderFor },
      }
    }
  ),

  exportOrder: createAction(
    'order/EXPORT',
    (orderId: string, sellerId: string) => ({
      payload: orderId,
      meta: {
        sellerId,
      },
    })
  ),
}

export default orders.reducer
export const OrderActions = {
  ...orders.actions,
  ...EpicActions,
}
