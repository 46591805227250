import { ActionsObservable } from 'redux-observable'
import { of } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import {
  addToList,
  fetchProducts,
  populateList,
  updateProductMeta,
  updateProductMetaOnList,
} from '../actions/product.actions'
import { IProduct, TProductActions } from '../types'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'
import { UIActions } from '../reducers/ui.reducer'

const fetchProductsEpic = (action$: ActionsObservable<TProductActions>) =>
  action$.pipe(
    filter(isActionOf(fetchProducts)),
    switchMap(action => {
      // TODO difference between buyer and seller
      const cursor = action.meta.cursor || ''
      const queryBit =
        '/product?limit=' +
        action.meta.limit +
        process.env.REACT_APP_AMPERSAND_SYMBOL +
        'cursor=' +
        cursor

      const urlSeller =
        process.env.REACT_APP_API_SELLER +
        '/' +
        action.payload.seller +
        queryBit

      const url = action.payload.buyer
        ? `${process.env.REACT_APP_API_BUYER}/${action.payload.buyer}/seller/${action.payload.seller}${queryBit}`
        : urlSeller

      return ajax.getJSON(url).pipe(
        mergeMap(res => {
          const response = res as {
            data: { product: IProduct }[]
            cursor: string
          }

          return [
            action.meta.append
              ? addToList(response.data, response.cursor)
              : populateList(response.data, response.cursor),

            UIActions.uiSetFetching('product_list', false),
          ]
        }),
        catchError((error: AjaxError) => of(broadcastAjaxErrors(error)))
      )
    })
  )

const putProductMeta = (action$: ActionsObservable<TProductActions>) =>
  action$.pipe(
    filter(isActionOf(updateProductMeta)),

    mergeMap(action => {
      const url = `${process.env.REACT_APP_API_BUYER}/${action.meta.buyerId}/seller/${action.meta.sellerId}/meta/${action.payload.productCode}`
      return ajax
        .post(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          mergeMap(response => {
            return [
              broadcastAjaxSuccess('Product updated'),
              updateProductMetaOnList(
                { ...action.payload },
                action.meta.sellerId,
                action.meta.buyerId
              ),

              UIActions.uiSetEditDrawerBusy(false),
            ]
          }),

          catchError((error: AjaxError) => {
            return [
              broadcastAjaxErrors(error),
              UIActions.uiSetEditDrawerBusy(false),
            ]
          })
        )
    })
  )
export const productEpics = [fetchProductsEpic, putProductMeta]
