import React, { useState } from 'react'
import ReCaptcha from 'react-google-recaptcha'

import {
  FormControl,
  TextField,
  Button,
  Box,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core'
import { IPolicy } from '../../redux/types'
import { addPolicy } from '../../redux/actions/profile.actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginForm: {
      alignSelf: 'end',
      margin: '2em',
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        marginRight: '1em',
      },
    },
    loginFormFields: {
      marginBottom: '1em',
    },
    captcha: {
      marginTop: '2em',
    },
  })
)

interface IProps {
  handleForgotPassword: () => void
}

const Login = (props: IProps) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)

  const dispatch = useDispatch()
  const classes = useStyles()

  const { handleForgotPassword } = props

  const handleCaptcha = (response: string | null) => {
    console.log('Captcha')
    console.log(response)

    setCaptchaToken(response)
  }

  const handleLogin = async () => {
    setError('')
    const formData = new FormData()
    formData.append('M', '1')
    formData.append('password', password)
    formData.append('username', username)
    if (captchaToken) formData.append('captcha', captchaToken)

    const resp = await fetch(process.env.REACT_APP_PLSERVLET_URL as string, {
      method: 'POST',
      body: formData,
    })
    if (resp.ok) {
      const json: IPolicy = await resp.json()
      if (json && json.orgType) {
        dispatch(addPolicy(json))
      }
    } else if (resp.status === 401) {
      setError('Unauthorized access, please check your credentials')
    } else {
      setError('Something went wrong, please try again later')
    }
    // TODO error handling
  }

  return (
    <form className={classes.loginForm}>
      <FormControl className={classes.loginFormFields}>
        <TextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required={true}
        />
      </FormControl>
      <FormControl className={classes.loginFormFields}>
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyPress={(e) => {
            if (
              e.key === 'Enter' &&
              username !== '' &&
              password !== '' &&
              captchaToken
            ) {
              handleLogin()
            }
          }}
        />
      </FormControl>
      <div>
        <Button
          color="primary"
          variant="contained"
          disabled={username === '' || password === '' || !captchaToken}
          onClick={handleLogin}
        >
          Login
        </Button>
        <Button onClick={handleForgotPassword} disabled={username === ''}>
          Forgot Password
        </Button>
        <div className={classes.captcha}>
          <ReCaptcha
            sitekey="6LdWVuYUAAAAAFT7sj9Ltnt1vCbU9yZ1cNlQEESP"
            onChange={handleCaptcha}
          />
        </div>
      </div>

      {error && (
        <Box padding="1em" marginTop="1em" color="red">
          {error}
        </Box>
      )}
    </form>
  )
}

export default Login
