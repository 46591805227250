import { createSlice, PayloadAction, createAction } from 'redux-starter-kit'
import { ICategoryTreeNode } from '../types/category.types'

const categoryTreeSlice = createSlice({
  name: 'categoryTree',
  initialState: {} as ICategoryTreeNode,
  reducers: {
    addToStore: (state, action: PayloadAction<ICategoryTreeNode>) =>
      action.payload,
  },
})

// ? export a reducer created from slice to add into rootReducer via combineReducers
export default categoryTreeSlice.reducer

// ! for epics
const EpicActions = {
  // ? fetch catgory object from API via Epic
  getCategoryTree: createAction<string>('categoryTree/GET'),
}

// ? export all actions here
export const CategoryActions = {
  ...categoryTreeSlice.actions,
  ...EpicActions,
}
