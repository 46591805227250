export const actions = {
  CHANGE_TITLE: 'CHANGE_TITLE'
}

export interface IChangeTitle {
  type: typeof actions.CHANGE_TITLE
  payload: ITitle
}

export interface ITitle {
  title: string
}

export const changeTitle = (title: string): IChangeTitle => ({
  type: actions.CHANGE_TITLE,
  payload: {
    title: title
  }
})
