import React, { useState } from 'react'
import { IAccount, IOtherOrgProperty } from '../../../redux/types'
import { Box, TextField, Button } from '@material-ui/core'
import scss from './other-org-properties.module.scss'
import { useDispatch } from 'react-redux'
import { AccountPropsActions } from '../../../redux/reducers/account-properties.reducer'
import useCredentials from '../../../hooks/use-credentials'

interface Props {
  otherAccount: IAccount
  orgproperty?: IOtherOrgProperty
}
const OtherOrgPropertiesForm = (props: Props) => {
  const { otherAccount, orgproperty } = props

  const [property, setProperty] = React.useState(
    orgproperty && orgproperty.otherOrgUniqueKey === otherAccount.uniqueKey
      ? orgproperty.property
      : ''
  )
  const [value, setValue] = React.useState(
    orgproperty && orgproperty.otherOrgUniqueKey === otherAccount.uniqueKey
      ? orgproperty.value
      : ''
  )
  const [isNew, setIsNew] = React.useState(false)
  const [edit, setEdit] = React.useState(
    orgproperty && orgproperty.otherOrgUniqueKey === otherAccount.uniqueKey
  )
  const dispatch = useDispatch()
  const credentials = useCredentials()

  React.useEffect(() => {
    if (
      orgproperty &&
      orgproperty.otherOrgUniqueKey === otherAccount.uniqueKey
    ) {
      setProperty(orgproperty.property)
      setValue(orgproperty.value)
      setEdit(true)
      setIsNew(false)
    }
  }, [orgproperty])

  const resetForm = () => {
    setIsNew(false)
    setEdit(false)
    setProperty('')
    setValue('')
  }

  const handleSave = () => {
    const p: IOtherOrgProperty = {
      otherOrgUniqueKey: otherAccount.uniqueKey,
      property: property,
      value: value,
    }
    if (isNew) {
      dispatch(
        AccountPropsActions.addOtherOrgProperty(p, credentials.getOrgId())
      )
    } else if (edit && orgproperty) {
      dispatch(
        AccountPropsActions.replaceOtherOrgProperty(
          p,
          credentials.getOrgId(),
          orgproperty
        )
      )
    }
    resetForm()
  }

  const handleProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProperty(e.target.value)
  }
  const handleValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  return (
    <Box className={scss.form}>
      {(isNew || edit) && (
        <>
          <TextField
            label="Property"
            value={property}
            onChange={handleProperty}
            required
          />
          <TextField
            required
            label="Value"
            value={value}
            onChange={handleValue}
          />
          <Button
            color="primary"
            variant="contained"
            disabled={value === '' || property === ''}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button onClick={resetForm}>Cancel</Button>
        </>
      )}
      {!isNew && !edit && (
        <Button
          variant="contained"
          color="primary"
          onClick={e => setIsNew(true)}
        >
          Add New
        </Button>
      )}
    </Box>
  )
}
export default OtherOrgPropertiesForm
