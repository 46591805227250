import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Theme,
  createStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  CardActions,
  Button,
  TextField,
} from '@material-ui/core'
import { AttachMoney } from '@material-ui/icons'
import useCredentials from '../../hooks/use-credentials'
import { useDispatch } from 'react-redux'
import {
  broadcastAjaxErrors,
  broadcastAjaxSuccess,
} from '../../redux/epics/utils'

interface Props {
  className?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(1),
      },
    },
  })
)

const TaxCodes = (props: Props) => {
  const { className } = props
  const classes = useStyles()
  const [taxableCode, setTaxableCode] = React.useState<string>('')
  const [nonTaxableCode, setNonTaxableCode] = React.useState<string>('')
  const dispatch = useDispatch()
  const credentials = useCredentials()

  const url = `${process.env.REACT_APP_API_BUYER}/${credentials.getOrgId()}`

  React.useEffect(() => {
    if (credentials.getOrgId()) getCodes()
  }, [credentials.getOrgId()])

  const getCodes = async () => {
    const res = await fetch(url)

    if (res.ok) {
      const j: TTaxCode = await res.json()
      if (j.taxCodeMapping) {
        setTaxableCode(j.taxCodeMapping.taxableCode)
        setNonTaxableCode(j.taxCodeMapping.nonTaxableCode)
      }
    } else {
      dispatch(broadcastAjaxErrors(res.statusText))
    }
  }

  const handleSubmit = async () => {
    if (taxableCode !== '' && nonTaxableCode !== '') {
      const c: TTaxCode = {
        taxCodeMapping: {
          taxableCode,
          nonTaxableCode,
        },
      }
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(c),
      })
      if (res.ok) {
        dispatch(broadcastAjaxSuccess('Tax Codes have been updated.'))
      } else {
        dispatch(broadcastAjaxErrors(res.statusText))
      }
    }
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        title="Tax Codes"
        avatar={
          <Avatar>
            <AttachMoney />
          </Avatar>
        }
      ></CardHeader>
      <CardContent className={classes.content}>
        <TextField
          fullWidth
          label="Taxable Code"
          value={taxableCode}
          onChange={e => setTaxableCode(e.target.value)}
        />
        <TextField
          fullWidth
          label="Non Taxable Code"
          value={nonTaxableCode}
          onChange={e => setNonTaxableCode(e.target.value)}
        />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          disabled={taxableCode === '' || nonTaxableCode === ''}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </CardActions>
    </Card>
  )
}
export default TaxCodes

export type TTaxCode = {
  taxCodeMapping: {
    taxableCode: string
    nonTaxableCode: string
  }
}
