import { createAction } from 'typesafe-actions'
import { IUser } from './../types/user.types'

export const add = createAction(
  'user/ADD',
  action => (user: IUser, orgid: string) => action(user, { orgid })
)

export const remove = createAction(
  'user/DELETE',
  action => (id: number, orgid: string) => action(id, { orgid })
)

export const replace = createAction(
  'user/REPLACE',
  action => (user: IUser, orgid: string) => action(user, { orgid })
)

export const populateList = createAction(
  'user/POPULATE_LIST',
  action => (users: IUser[]) => action(users)
)

export const extendList = createAction(
  'user/EXPAND_LIST',
  action => (users: IUser[]) => action(users)
)

export const addToList = createAction(
  'user/ADD_TO_LIST',
  action => (user: IUser) => action(user)
)

export const removeFromList = createAction(
  'user/REMOVE_FROM_LIST',
  action => (id: number) => action(id)
)

export const replaceOnList = createAction(
  'user/REPLACE_ON_LIST',
  action => (user: IUser) => action(user)
)

export const fetchUsers = createAction(
  'user/FETCH',
  action => (
    orgid: string,
    offset: number,
    limit: number,
    query?: { key: string; value: string }[]
  ) => action(orgid, { offset, limit, query })
)

export const fetchMoreUsers = createAction(
  'user/FETCH_MORE',
  action => (
    orgid: string,
    offset: number,
    limit: number,
    query?: { key: string; value: string }[]
  ) => action(orgid, { offset, limit, query })
)

export const selectUser = createAction(
  'user/SELECT',
  action => (user: IUser | null) => action(user)
)

export const changeUserPassword = createAction(
  'user/actions/CHANGE_PASSWORD',
  action => (orgid: string, oldPassword: string, newPassword: string) => action(orgid, {oldPassword, newPassword})
)
