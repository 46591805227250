import {
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
} from '@material-ui/core'
import AvatarIcon from '@material-ui/icons/PowerSettingsNew'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiMenu-paper	': {
        minWidth: 200,
      },
    },
    icon: {
      color: theme.palette.primary.contrastText,
    },
  })
)

type Props = {
  onClickSetting: () => void
  items?: { name: string; onClick: () => void }[]
}

const AvatarButton = (props: Props) => {
  const { onClickSetting } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)
  const classes = useStyles()

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    await fetch(process.env.REACT_APP_PLSERVLET_URL + '?M=2')
    localStorage.removeItem('renderFor')
    window.location.reload()
  }

  return (
    <>
      <IconButton edge="end" onClick={handleMenuOpen}>
        <AvatarIcon className={classes.icon} />
      </IconButton>
      <Menu
        className={classes.root}
        variant="menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        keepMounted
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem dense onClick={handleLogout}>
          Logout
        </MenuItem>
        {props.items &&
          props.items.map((i, index) => (
            <MenuItem key={index} dense onClick={i.onClick}>
              {i.name}
            </MenuItem>
          ))}
        <MenuItem
          dense
          onClick={() => {
            onClickSetting()
            setAnchorEl(null)
          }}
        >
          Settings
        </MenuItem>
      </Menu>
    </>
  )
}
export default AvatarButton
