// ? this is written in classic Redux Style
// ? can be refactored to reduce the boilerplate by uisng https://github.com/piotrwitek/typesafe-actions

import { IAccount } from '../types'

export const GET_ACCOUNT = 'GET_ACCOUNT'
export const FETCH_ACCOUNTS = 'FETCH_ACCOUNTS'
export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT'
export const LOAD_ACCOUNTS = 'LOAD_ACCOUNTS'
export const LOAD_MORE_ACCOUNTS = 'LOAD_MORE_ACCOUNTS'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const REMOVE_ACCOUNT_FROM_LIST = 'REMOVE_ACCOUNT_FROM_LIST'
export const LOG_ACCOUNTS_MESSAGE = 'LOG_ACCOUNTS_MESSAGE'
export const SET_BUSY_ON_ACCOUNT = 'SET_BUSY_ON_ACCONT'
export const SET_ALTER_ON_ACCOUNT = 'SET_ALTER_ON_ACCOUNT'

export interface IGetAccount {
  type: typeof GET_ACCOUNT
  payload: string
}

export interface IAddAccount {
  type: typeof ADD_ACCOUNT
  payload: IAccount
}
export interface IEditAccount {
  type: typeof EDIT_ACCOUNT
  payload: IAccount
}
export interface ILoadAccounts {
  type: typeof LOAD_ACCOUNTS
  payload: IAccount[]
}

export interface ILoadMoreAccounts {
  type: typeof LOAD_MORE_ACCOUNTS
  payload: IAccount[]
}

export interface IDeleteAccount {
  type: typeof DELETE_ACCOUNT
  payload: string
}

export interface IRemoveAccountFromList {
  type: typeof REMOVE_ACCOUNT_FROM_LIST
  payload: string
}

interface IFetchPayload {
  offset: number
  merge: boolean
  limit: number
  query?: { key: string; value: string }[]
}
export interface IFetchAccounts {
  type: typeof FETCH_ACCOUNTS
  payload: IFetchPayload
}

export interface ILogAccountsMessage {
  type: typeof LOG_ACCOUNTS_MESSAGE
  payload: string
}

export interface ISetBusyOnAccount {
  type: typeof SET_BUSY_ON_ACCOUNT
  payload: boolean
  id: string
}

export interface ISetAlertOnAccount {
  type: typeof SET_ALTER_ON_ACCOUNT
  payload: any
  id: string
}

export type TAccountsActionTypes =
  | IGetAccount
  | IAddAccount
  | IEditAccount
  | ILoadAccounts
  | IDeleteAccount
  | IFetchAccounts
  | ILogAccountsMessage
  | IRemoveAccountFromList
  | ISetBusyOnAccount
  | ISetAlertOnAccount
  | ILoadMoreAccounts

export const getAccount = (id: string): TAccountsActionTypes => ({
  type: GET_ACCOUNT,
  payload: id
})

export const addAccount = (account: IAccount): TAccountsActionTypes => ({
  type: ADD_ACCOUNT,
  payload: account
})

export const deleteAccount = (id: string): TAccountsActionTypes => ({
  type: DELETE_ACCOUNT,
  payload: id
})

export const removeAccountFromList = (id: string): TAccountsActionTypes => ({
  type: REMOVE_ACCOUNT_FROM_LIST,
  payload: id
})

export const editAccount = (account: IAccount): TAccountsActionTypes => ({
  type: EDIT_ACCOUNT,
  payload: account
})

export const loadAccounts = (accounts: IAccount[]): TAccountsActionTypes => ({
  type: LOAD_ACCOUNTS,
  payload: accounts
})
export const loadMoreAccounts = (
  accounts: IAccount[]
): TAccountsActionTypes => ({
  type: LOAD_MORE_ACCOUNTS,
  payload: accounts
})

export const fetchAccounts = (
  offset: number = 0,
  merge: boolean = false,
  limit: number = 20,
  query?: { key: string; value: string }[]
): TAccountsActionTypes => ({
  type: FETCH_ACCOUNTS,
  payload: { limit: limit, offset: offset, merge: merge, query: query }
})

export const logMessage = (message: string): TAccountsActionTypes => ({
  type: LOG_ACCOUNTS_MESSAGE,
  payload: message
})

export const setBusyOnAccount = (
  id: string,
  state: boolean
): TAccountsActionTypes => ({
  type: SET_BUSY_ON_ACCOUNT,
  payload: state,
  id: id
})

export const setAlertOnAccount = (
  id: string,
  alert: any
): TAccountsActionTypes => ({
  type: SET_ALTER_ON_ACCOUNT,
  payload: alert,
  id: id
})
