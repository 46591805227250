import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from '@material-ui/core'
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme'
import { StylesProvider } from '@material-ui/styles'
import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../redux/store'

const defaultTheme: ThemeOptions = {
  overrides: {
    // MuiTableCell: {
    //   head: {
    //     color: 'red'
    //   }
    // }
  },
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.8rem',
    },
    h3: {
      fontSize: '1.6rem',
    },
    h4: {
      fontSize: '1.4rem',
    },
    h5: {
      fontSize: '1.3rem',
    },
    h6: {
      fontSize: '1.2rem',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#10364C',
    },
    secondary: {
      main: '#B6534E',
    },
  },
}

export const PortalinkTheme = ({ children }: { children: ReactNode }) => {
  const palette = useSelector(
    (state: AppState) => state.ui.palette || defaultTheme.palette
  )

  const theme = createMuiTheme({ ...defaultTheme, palette: { ...palette } })
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <StylesProvider injectFirst>{children}</StylesProvider>
    </MuiThemeProvider>
  )
}
