import {
  Box,
  Breadcrumbs,
  Checkbox,
  createStyles,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { TabProps } from '@material-ui/core/Tab'
import { Error } from '@material-ui/icons'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import React from 'react'
import Iframe from 'react-iframe'
import { useDispatch } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import { updateProductMeta } from '../../redux/actions/product.actions'
import {
  IAccount,
  IProductFromAPI,
  IProductMeta,
  IProductAsset,
} from '../../redux/types'
import AutoPlayView from '../image/AutoPlayView'
import RouterLink from '../RouterLink'
import TabContent from '../tab-content'
import PricingDiscrepancies from './pricing-discrepancies'
import PricingTab from './pricing-tab'
import PropertyTag from './property-tag'
import clsx from 'clsx'
import ExportedDateTimeWidget from './exported-date-time-widget'
import { UIActions } from '../../redux/reducers/ui.reducer'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    divider: {
      marginTop: '1.5em',
      marginBottom: '1.5em',
    },
    sectionHeader: {
      marginBottom: '.5em',
    },
    tabs: {
      marginTop: '1.5em',
      marginBottom: '1.5em',
      position: 'sticky',
      top: '3em',
      background: theme.palette.background.paper,
    },
    iframe: {
      border: 'none',
      width: '100%',
      height: '100%',
    },
    iframenotes: {
      marginBottom: '.5em',
    },
    toolbar: {
      marginTop: '1em',
      display: 'flex',
      justifyContent: 'space-between',
    },
    supplier: {},
    otherName: {},
    breadcrumbs: {
      margin: '.5em 0',
    },
    pricingDiscrepancyTab: {
      '& .MuiTab-wrapper': {
        flexDirection: 'row',
        '& .MuiSvgIcon-root': {
          marginRight: '.5em',
        },
      },
    },
  })
)

type Props = {
  product: IProductFromAPI
  seller?: IAccount
  buyerId?: string
}
const ProductProfile = (props: Props) => {
  const credentials = useCredentials()
  const { product, seller, buyerId } = props
  const classes = useStyles()
  const [tab, setTab] = React.useState('summary')
  const dispatch = useDispatch()
  const images = () => {
    if (product.product.assets) {
      const urls = product.product.assets.reduce(
        (acc: string[], asset: IProductAsset) => {
          if (asset.downloadUrl && (!asset.type || asset.type === 'image')) {
            return [...acc, asset.downloadUrl]
          }
          return acc
        },
        []
      )
      if (urls.length > 0) return urls
    }
    return undefined
  }

  const handleExclusion = () => {
    if (seller && buyerId) {
      const meta = product.meta
        ? { ...product.meta, excluded: !product.meta.excluded }
        : {
            excluded: false,
            productCode: product.product.productCode,
            buyerSellerId: product.product.sellerId,
          }
      dispatch(UIActions.uiSetEditDrawerBusy(true))
      dispatch(
        updateProductMeta(
          {
            ...meta,
          } as IProductMeta,
          seller.uniqueKey,
          buyerId
        )
      )
    }
  }

  const chemicalClassificationList = (classification?: string) => {
    if (classification && classification.includes(';')) {
      return (
        <ul>
          {classification.split(';').map((c, i) => (
            <li key={i}>{c}</li>
          ))}
        </ul>
      )
    }
    return classification
  }

  const Pricing = (tabprops: TabProps) => {
    const { value, label, icon, className, ...rest } = tabprops
    if (
      product.priceDiscrepancyTable &&
      product.priceDiscrepancyTable.length > 0
    ) {
      return (
        <Tab
          value="pricing"
          label="Price(excl)"
          icon={<Error fontSize="small" color="error" />}
          // className={classes.pricingDiscrepancyTab}
          className={clsx(classes.pricingDiscrepancyTab, className)}
          {...rest}
        />
      )
    }
    return (
      <Tab
        value="pricing"
        label="Price(excl)"
        {...rest}
        className={className}
      />
    )
  }

  return (
    <Box>
      {seller && (
        <Typography variant="h4" className={classes.supplier}>
          Supplier: {seller.name}
        </Typography>
      )}
      <Typography className={classes.otherName}>
        {product.product.otherName}
      </Typography>

      {product.productCategoryDescription && (
        <Breadcrumbs className={classes.breadcrumbs}>
          <Typography>{product.productCategoryDescription.catalog}</Typography>
          <Typography>{product.productCategoryDescription._class}</Typography>
          <Typography>{product.productCategoryDescription.category}</Typography>
          <Typography>{product.productCategoryDescription.group}</Typography>
        </Breadcrumbs>
      )}

      <Typography
        display="block"
        style={{ paddingBottom: '2em' }}
        variant="overline"
      >
        {product.product.description}
      </Typography>

      <Grid container spacing={2}>
        <Grid item md={6}>
          <Typography variant="subtitle2">Description</Typography>
          {product.product.longDescription}
        </Grid>
        <Grid item container md={6}>
          {images() && (
            <Box
              width="100%"
              height="100%"
              minHeight="200px"
              borderRadius="1em"
              overflow="hidden"
            >
              <AutoPlayView images={images()} contained />
            </Box>
          )}
        </Grid>
      </Grid>

      <Toolbar disableGutters variant="dense" className={classes.toolbar}>
        {credentials.renderForOrgType('UNIVERSITY') && (
          <FormGroup row>
            <FormControlLabel
              label="Included"
              control={
                <Checkbox
                  color="primary"
                  checked={product.meta !== undefined && !product.meta.excluded}
                />
              }
              onChange={handleExclusion}
            />
          </FormGroup>
        )}

        <Tooltip title="Open in page">
          <RouterLink
            to={
              buyerId
                ? `/app/product/${product.product.productCode}/seller/${product.product.sellerId}/buyer/${buyerId}`
                : `/app/product/${product.product.productCode}/seller/${product.product.sellerId}/`
            }
          >
            <OpenInNewIcon />
          </RouterLink>
        </Tooltip>
      </Toolbar>

      <Divider className={classes.divider} />
      <Tabs
        value={tab}
        onChange={(event: React.ChangeEvent<{}>, newValue: string) =>
          setTab(newValue)
        }
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
      >
        <Tab value="summary" label="Summary" />
        <Tab value="volumetrics" label="Volumetrics" />
        {(product.prices || (product.meta && product.meta.contractPrices)) && (
          <Pricing />
        )}
        {(product.product.message || product.product.comments) && (
          <Tab value="messages" label="Messages" />
        )}
        {product.product.barcodes && <Tab value="barcodes" label="Barcodes" />}
        {product.product.urls &&
          product.product.urls.map((u, i) => (
            <Tab key={i} value={'url_' + i} label={`Custom ${i + 1}`} />
          ))}
      </Tabs>

      <TabContent show={tab === 'summary'}>
        <Box>
          <Grid container spacing={2}>
            <Grid item md>
              <PropertyTag
                propertyKey="Lead Time"
                propertyValue={product.product.leadTime}
                suffix={
                  product.product.leadTime !== undefined &&
                  product.product.leadTime === 1
                    ? ' day'
                    : ' days'
                }
              />

              <PropertyTag
                propertyKey="Flag Code"
                propertyValue={
                  product.product.flagCode === 'A'
                    ? 'Active'
                    : product.product.flagCode === 'C'
                    ? 'Cancelled'
                    : product.product.flagCode
                }
              />

              <PropertyTag
                propertyKey="Unavailable From"
                propertyValue={product.product.unavailableFromDate}
              />

              <PropertyTag
                propertyKey="Warranty Period"
                propertyValue={product.product.warrentyPeriod}
                suffix={
                  (product.product.warrentyPeriod !== undefined &&
                    product.product.warrentyPeriod) === 1
                    ? ' year'
                    : ' years'
                }
              />
            </Grid>
            <Grid item md>
              <PropertyTag
                propertyKey="Taxable"
                propertyValue={product.product.taxable === 'Y' ? 'Yes' : 'No'}
              />
              <PropertyTag
                propertyKey="UOM"
                propertyValue={product.product.unitOfMeasure}
              />

              <PropertyTag
                propertyKey="Unavailable To"
                propertyValue={product.product.unavailableToDate}
              />
              {credentials.renderForOrgType('UNIVERSITY') && (
                <PropertyTag
                  propertyKey="Last Exported"
                  propertyValue={
                    <ExportedDateTimeWidget
                      epoch={
                        product.meta &&
                        product.meta.lastExportedEpochMilliSeconds
                      }
                    />
                  }
                />
              )}
            </Grid>
          </Grid>

          <Divider variant="fullWidth" className={classes.divider} />

          <PropertyTag
            propertyKey="Chemical Classification"
            propertyValue={chemicalClassificationList(
              product.product.chemicalClassifications
            )}
          />
          <PropertyTag
            propertyKey="Chem Management Platform ID"
            propertyValue={product.product.chemManagementPlatformId}
          />

          <PropertyTag
            propertyKey="Chemical Abstracts Service Numbers"
            propertyValue={product.product.casNumbers}
          />

          <Divider variant="fullWidth" className={classes.divider} />

          <Grid container spacing={2}>
            <Grid item md>
              <PropertyTag
                propertyKey="Dangerous Goods"
                propertyValue={product.product.dangerousGoodsFlag}
              />
              <PropertyTag
                propertyKey="Hazardous"
                propertyValue={product.product.hazardousMaterialFlag}
              />
            </Grid>
            <Grid item md>
              <PropertyTag
                propertyKey="Radioactive"
                propertyValue={product.product.radioActiveFlag}
              />
            </Grid>
          </Grid>
        </Box>
      </TabContent>
      <TabContent show={tab === 'volumetrics'}>
        <Grid container>
          <Grid item md>
            <PropertyTag
              propertyKey="Weight"
              propertyValue={product.product.weight}
              suffix={product.product.weightUom}
            />
            <PropertyTag
              propertyKey="Width"
              propertyValue={product.product.width}
              suffix={product.product.widthUom}
            />
            <PropertyTag
              propertyKey="Height"
              propertyValue={product.product.height}
              suffix={product.product.heightUom}
            />
          </Grid>
          <Grid item md>
            <PropertyTag
              propertyKey="Depth"
              propertyValue={product.product.depth}
              suffix={product.product.depthUom}
            />
            <PropertyTag
              propertyKey="Length"
              propertyValue={product.product.length}
              suffix={product.product.lengthUom}
            />
          </Grid>
        </Grid>
      </TabContent>

      {(product.prices || (product.meta && product.meta.contractPrices)) && (
        <TabContent show={tab === 'pricing'}>
          <Box>
            <PricingTab
              prices={product.prices}
              contractPrices={
                product.meta ? product.meta.contractPrices : undefined
              }
            >
              {product.priceDiscrepancyTable &&
                product.priceDiscrepancyTable.length > 0 && (
                  <Box marginTop="2em">
                    <PricingDiscrepancies
                      discrepancies={product.priceDiscrepancyTable}
                    />
                  </Box>
                )}
            </PricingTab>
          </Box>
        </TabContent>
      )}
      <TabContent show={tab === 'messages'}>
        <Box>
          <PropertyTag
            propertyKey="Message"
            propertyValue={product.product.message}
          />
          <Divider className={classes.divider} />
          <PropertyTag
            propertyKey="Comments"
            propertyValue={product.product.comments}
          />
        </Box>
      </TabContent>
      <TabContent show={tab === 'barcodes'}>
        <Box>
          {product.product.barcodes &&
            product.product.barcodes.map((b, i) => (
              <PropertyTag
                key={i}
                propertyKey={b.uom}
                propertyValue={b.code}
                suffix={' ' + b.type}
              />
            ))}
        </Box>
      </TabContent>
      {product.product.urls &&
        product.product.urls.map((u, i) => (
          <TabContent key={i} show={tab === `url_${i}`}>
            <Box>
              {u.notes && (
                <Box>
                  <Typography className={classes.iframenotes} variant="body2">
                    {u.notes}
                  </Typography>
                </Box>
              )}
              <Box height="50vh">
                <Iframe className={classes.iframe} url={u.url}></Iframe>
              </Box>
            </Box>
          </TabContent>
        ))}
    </Box>
  )
}

export default ProductProfile
