import {
  Box,
  CircularProgress,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import { Cancel, CloudUpload } from '@material-ui/icons'
import React from 'react'
import { useDispatch } from 'react-redux'
import { fetchProducts } from '../../redux/actions/product.actions'
import FileUpload from '../filesystem/file-upload'
interface Props {
  sellerId: string
}
const SellerCatalogueToolset = (props: Props) => {
  const { sellerId } = props
  const [uploadURL, setUploadURL] = React.useState<string | undefined>(
    undefined
  )
  const [busyButton, setBusyButton] = React.useState(false)
  const dispatch = useDispatch()

  const getUploadURL = async () => {
    setBusyButton(true)
    const res = await fetch(
      process.env.REACT_APP_API_SELLER +
        '/' +
        sellerId +
        '/ppo/action/upload-url',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )

    if (res.ok) {
      const j = await res.json()
      if (j.uploadUrl) {
        setUploadURL(j.uploadUrl)
      }
    }

    setBusyButton(false)
  }

  const handlePPOUploaded = () => {
    setUploadURL(undefined)
    dispatch(fetchProducts({ seller: sellerId }, false, 20))
  }

  const handleCancel = () => {
    setUploadURL(undefined)
    setBusyButton(false)
  }

  return (
    <>
      <Toolbar>
        {busyButton && <CircularProgress />}
        {uploadURL === undefined && !busyButton && (
          <Tooltip title="Upload PPO file">
            <IconButton onClick={getUploadURL}>
              <CloudUpload />
            </IconButton>
          </Tooltip>
        )}

        {uploadURL !== undefined && (
          <Tooltip title="Close uploader.">
            <IconButton onClick={handleCancel}>
              <Cancel />
            </IconButton>
          </Tooltip>
        )}

        <Slide in={uploadURL !== undefined} direction="down">
          <Box flexGrow={1}>
            {uploadURL && (
              <FileUpload
                url={uploadURL}
                method="PUT"
                callback={() => {
                  setTimeout(() => {
                    handlePPOUploaded()
                  }, 3000)
                }}
              />
            )}
          </Box>
        </Slide>
      </Toolbar>
    </>
  )
}
export default SellerCatalogueToolset
