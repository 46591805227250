import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import VisibilityIcon from '@material-ui/icons/Visibility'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import useCredentials from '../../../hooks/use-credentials'
import { fetchASNs } from '../../../redux/actions/asn.actions'
import { AppState } from '../../../redux/store'
import { AsnActions } from '../../../redux/reducers/asn.reducer'

type Props = {
  orgid?: string

  orderNumber?: string
  handleShowDetail: () => void
}

const AsnList = (props: Props) => {
  const dispatch = useDispatch()
  const credentials = useCredentials()

  const { orgid, orderNumber, handleShowDetail } = props
  const asns = useSelector((state: AppState) => state.asns.list)
  const [ref, inView] = useInView()
  const [limit] = useState(20)

  const isSeller = credentials.renderForOrgType('SUPPLIER')

  useEffect(() => {
    dispatch(
      fetchASNs(
        orgid as string,
        0,
        limit,
        orderNumber as string,
        false,
        isSeller ? 'SUPPLIER' : 'UNIVERSITY'
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderNumber])

  return (
    <>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" size="medium" style={{ zIndex: 1 }}>
              {isSeller ? (
                <Tooltip title="Add ASN">
                  <IconButton
                    onClick={() => {
                      handleShowDetail()
                      dispatch(AsnActions.select(null))
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Typography>Actions</Typography>
              )}
            </TableCell>
            <TableCell>Shipment Id</TableCell>
            <TableCell>Issue Date</TableCell>
            <TableCell>Expected Delivery Date</TableCell>
            <TableCell>Shipped Date</TableCell>
            <TableCell>Tracking ID</TableCell>
            <TableCell>Shipped To Whom</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {asns.map((a, i) => (
            <TableRow
              key={'asn_' + i}
              hover={true}
              ref={
                i + 1 === asns.length && asns.length >= limit // ! the last row will trigger next query by entering the screen
                  ? ref
                  : null
              }
            >
              <TableCell padding="checkbox">
                <Tooltip title="View ASN details">
                  <IconButton
                    onClick={() => {
                      handleShowDetail()
                      dispatch(AsnActions.select(a))
                    }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>{a.shipmentId}</TableCell>
              <TableCell>
                {a.noticeDate && new Date(a.noticeDate).toDateString()}
              </TableCell>
              <TableCell>
                {a.deliveryDate && new Date(a.deliveryDate).toDateString()}
              </TableCell>
              <TableCell>
                {a.shipmentDate && new Date(a.shipmentDate).toDateString()}
              </TableCell>
              <TableCell>{a.trackingId}</TableCell>
              <TableCell>{a.shipTo && a.shipTo.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default AsnList
