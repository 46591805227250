import { createAction, createSlice, PayloadAction } from 'redux-starter-kit'
import { IInvoice, IInvoiceStore } from '../types/invoice.types'
import { IHistory } from '../types'

const state: IInvoiceStore = {
  list: [],
  selectedHistory: [],
  query: {
    limit: 20,
    offset: 0,
  },
}

const invoices = createSlice({
  name: 'invoices',
  initialState: state,
  reducers: {
    populateList: (state, action: PayloadAction<IInvoice[]>) => {
      state.list = action.payload
      if (action.payload.length === state.query.limit) {
        state.query.offset += state.query.limit
      }
    },
    populateHistory: (state, action: PayloadAction<IHistory[]>) => {
      state.selectedHistory = action.payload
    },
    appendToList: (state, action: PayloadAction<IInvoice[]>) => {
      state.list = [...state.list, ...action.payload]
      if (action.payload.length === state.query.limit) {
        state.query.offset += state.query.limit
      }
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.query.offset = action.payload
    },
    setStatus: (state, action: PayloadAction<string | undefined>) => {
      state.query.veStatus = action.payload
    },
    select: (state, action: PayloadAction<IInvoice>) => {
      state.selected = action.payload
    },
    updateReadFlag: (state, action: PayloadAction<string>) => {
      const id = parseInt(action.payload)

      state.list = [...state.list].map((l) => {
        if (l.oiid === id) {
          return { ...l, read: true }
        }
        return l
      })
    },
  },
})

// ! for epics

const EpicActions = {
  getInvoices: createAction(
    'invoices/GET',
    (
      buyerId: string,
      limit: number,
      offset: number,
      more: boolean,
      veStatus?: string
    ) => {
      return {
        payload: buyerId,
        meta: { limit, more, offset, veStatus },
      }
    }
  ),
  getInvoice: createAction(
    'invoices/GET_INVOICE',
    (invoiceId: string, buyerId: string) => ({
      payload: invoiceId,
      meta: { buyerId },
    })
  ),
  setAsRead: createAction(
    'invoices/SET_READ',
    (invoiceId: string, buyerId: string) => ({
      payload: invoiceId,
      meta: { buyerId },
    })
  ),
  getInvoiceHistory: createAction(
    'invoice_history/GET',
    (invoiceId: string, buyerId: string) => ({
      payload: invoiceId,
      meta: { buyerId },
    })
  ),
}

export default invoices.reducer
export const InvoiceActions = {
  ...invoices.actions,
  ...EpicActions,
}
