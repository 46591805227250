import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeTitle } from '../redux/actions/header.actions'
import { ComponentWithRoutes } from './types'
const Invoices = (props: ComponentWithRoutes) => {
  const [title] = useState('Invoices')
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeTitle(title))
    document.title = title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{props.children}</>
}
export default Invoices
