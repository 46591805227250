import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import SkeletonTable from '../skeleton-table'
import useCredentials from '../../hooks/use-credentials'
import {
  fetchAccounts,
  loadAccounts,
} from '../../redux/actions/account.actions.old'
import { UIActions } from '../../redux/reducers/ui.reducer'
import { AppState } from '../../redux/store'
import { IAccount } from '../../redux/types'

type Props = {
  actions?: (account: IAccount) => React.ReactNode
  query?: { key: string; value: string }[]
}
const OrgList = (props: Props) => {
  const theme = useTheme()
  // ? filter by name
  const [name, setName] = useState('')
  const credentials = useCredentials()

  //? limit of results per query
  const [limit] = useState(20)

  const dispatch = useDispatch()
  const accounts = useSelector((state: AppState) => state.accountsList)
  const loadingLines = useSelector(
    (state: AppState) => state.ui.fetching['account_lines']
  )
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      fetchNextBatch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  useEffect(() => {
    dispatch(UIActions.uiSetFetching('account_lines', true))
    dispatch(fetchAccounts(0, false, limit, constructQuery()))
    return () => {
      //? clear the list on exit
      dispatch(loadAccounts([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, limit])

  const fetchNextBatch = () => {
    dispatch(UIActions.uiSetFetching('account_lines', true))
    dispatch(fetchAccounts(accounts.length, true, limit, constructQuery()))
  }

  const constructQuery = () => {
    let query: { key: string; value: string }[] = []
    if (name) {
      query.push({ key: 'nameLike', value: name })
    }
    if (props.query && props.query.length > 0) {
      query = [...query, ...props.query]
    }
    if (query.length > 0) return query
    return undefined
  }

  return (
    <>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell style={{ zIndex: 1 }}></TableCell>
            <TableCell>
              <TextField
                value={name}
                placeholder="Account Name"
                onChange={e => setName(e.target.value)}
                fullWidth
                helperText="Filter by Name"
              />
            </TableCell>
            <TableCell>Account Type</TableCell>
            <TableCell>Suburb</TableCell>
            <TableCell>State</TableCell>
            <TableCell>Post Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((a, i) => (
            <TableRow
              key={'acc_' + a.uniqueKey}
              hover={true}
              ref={
                i + 1 === accounts.length && accounts.length >= limit // ! the last row will trigger next query by entering the screen
                  ? ref
                  : null
              }
            >
              <TableCell padding="checkbox">
                {props.actions && props.actions(a)}
              </TableCell>
              <TableCell>{a.name}</TableCell>
              <TableCell>{a.type === 1 ? 'Supplier' : 'University'}</TableCell>
              <TableCell>{a.address2}</TableCell>
              <TableCell>{a.state}</TableCell>
              <TableCell>{a.postCode}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <SkeletonTable columns={5} rows={5} show={loadingLines} />
    </>
  )
}
export default OrgList
