import React from 'react'
import { ComponentWithRoutes } from './types'

//? a wrapper page for lists of accounts and their users
const AccountsPage = (props: ComponentWithRoutes) => {
  const { children } = props

  return <>{children}</>
}
export default AccountsPage
