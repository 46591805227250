import { addToasterMessage } from '../../actions/feedback.actions'
import { APIError } from '../../types'

export const broadcastAjaxErrors = (error: any) => {
  const eb: APIError = error.response
  const e = ((errorM: any, error: any) => {
    if (errorM) {
      if (errorM.errors) return errorM.errors.join()
      if (errorM.Message) return errorM.Message
    }
    if (error) {
      if (error.message) return error.message
      if (error !== '') return error
    }
    console.log(error)
    return "Can't process this request!"
  })(eb, error)

  return addToasterMessage({ message: e, variant: 'error' })
}

export const broadcastAjaxSuccess = (message: string) =>
  addToasterMessage({
    message: message,
    variant: 'success',
  })
