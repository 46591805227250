import { ActionsObservable } from 'redux-observable'
import { TCustomerActions } from '../types/customer.types'
import { isActionOf } from 'typesafe-actions'
import {
  modifyCustomerMeta,
  getCustomerMeta,
} from '../actions/customer.actions'
import { flatMap, filter, catchError, switchMap } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'

const fetchCustomerMeta = (action$: ActionsObservable<TCustomerActions>) =>
  action$.pipe(
    filter(isActionOf(getCustomerMeta)),

    switchMap(action => {
      const url = `${process.env.REACT_APP_API_SELLER}/${action.payload.sellerId}/buyer/${action.payload.buyerId}`
      return ajax.getJSON(url).pipe(
        flatMap(response => []),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )
export const customerEpics = [fetchCustomerMeta]
