import clsx from 'clsx'
import {
  createStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  TextField,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import FormatCurrencyText from '../components/utils/format-currency-text'
import useCredentials from '../hooks/use-credentials'
import { changeTitle } from '../redux/actions/header.actions'
import { UIActions } from '../redux/reducers/ui.reducer'
import { AppState } from '../redux/store'
import SkeletonTable from './../components/skeleton-table'
import { ComponentWithRoutes } from './types'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { OrderActions } from '../redux/reducers/order.reducer'

type Props = {
  orgid?: string
  keepList?: boolean
  selectedLineId?: string
} & ComponentWithRoutes

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    boldFont: {
      '& td': {
        fontWeight: 'bold',
      },
    },
    clickable: {
      cursor: 'pointer',
    },
  })
)

const Orders = (props: Props) => {
  const styles = useStyles()
  const [title] = useState<string>('Orders')
  const dispatch = useDispatch()
  const credentials = useCredentials()
  const orgid: string = credentials.getOrgId()
  const isSeller = credentials.renderForOrgType('SUPPLIER')
  const canSeeOrders =
    credentials.renderForOrgType('SUPPLIER') ||
    credentials.renderForOrgType('UNIVERSITY')
  const [poNoLike, setPoNoLike] = useState<string>('')
  const [limit] = useState(20)
  const [ref, inView] = useInView()

  const scrollToRef = React.useRef<HTMLTableCellElement>(null)
  const handleScrollTo = () => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
      })
    }
  }

  const orders = useSelector((state: AppState) => state.orders.list)
  const loadingList = useSelector(
    (state: AppState) => state.ui.fetching['orders_list']
  )

  const fetchOrders = (more: boolean, offset: number) => {
    if (credentials.getOrgId() && canSeeOrders) {
      dispatch(UIActions.uiSetFetching('orders_list', true))
      dispatch(
        OrderActions.getOrders(
          orgid as string,
          offset,
          20,
          more,
          isSeller ? 'SUPPLIER' : 'UNIVERSITY',
          constructQuery()
        )
      )
    }
  }

  const constructQuery = () => {
    let query: { key: string; value: string }[] = []
    if (poNoLike) {
      query.push({ key: 'orderNumberLike', value: poNoLike })
    }
    if (query.length > 0) return query
    return undefined
  }

  useEffect(() => {
    if (scrollToRef !== null) handleScrollTo()
  }, [scrollToRef])

  useEffect(() => {
    document.title = title
    dispatch(changeTitle(title))
    if (!props.keepList || !orders || orders.length === 0) fetchOrders(false, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.getOrgId()])

  useEffect(() => {
    if (poNoLike) {
      fetchOrders(false, 0)
    }
  }, [poNoLike])

  useEffect(() => {
    if (inView) {
      fetchOrders(true, orders.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  if (canSeeOrders) {
    return (
      <>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TextField
                  value={poNoLike}
                  placeholder="PO#"
                  onChange={e => setPoNoLike(e.target.value)}
                  fullWidth
                  helperText="Filter by PO#"
                />
              </TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Order Total</TableCell>
              <TableCell>Raised By User</TableCell>
              <TableCell>{isSeller ? 'Customer' : 'Supplier'}</TableCell>
              <TableCell>
                <LocalShippingIcon />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.length == 0 && (
              <TableRow>
                <TableCell colSpan={6}>No results</TableCell>
              </TableRow>
            )}
            {orders.map((o, i) => (
              <TableRow
                key={i}
                hover={true}
                ref={
                  i + 1 === orders.length && orders.length >= limit // ! the last row will trigger next query by entering the screen
                    ? ref
                    : null
                }
                className={clsx(!o.read && styles.boldFont, styles.clickable)}
                onClick={e => {
                  e.stopPropagation()
                  props.navigate && props.navigate(`${orgid}/order/${o.id}`)
                }}
                selected={
                  props.selectedLineId !== undefined &&
                  props.selectedLineId === o.id
                }
              >
                <TableCell
                  ref={
                    props.selectedLineId !== undefined &&
                    props.selectedLineId === o.id
                      ? scrollToRef
                      : null
                  }
                >
                  {o.orderNo}
                </TableCell>
                <TableCell>
                  {o.orderDate && new Date(o.orderDate).toString().slice(0, 21)}
                </TableCell>
                <TableCell align="right">
                  <FormatCurrencyText
                    value={
                      (o.taxTotal ? o.taxTotal : 0) +
                      (o.orderTotalExtTax ? o.orderTotalExtTax : 0)
                    }
                    currency={o.orderTotalCurrency}
                  />
                </TableCell>
                <TableCell>{o.contact ? o.contact.name : 'Unknown'}</TableCell>
                <TableCell>{isSeller ? o.buyerName : o.sellerName}</TableCell>
                <TableCell>{o.state}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SkeletonTable show={loadingList} />
      </>
    )
  }
  return <Typography variant="h1"></Typography>
}

export default Orders
