import { Epic } from 'redux-observable'
import { of } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  flatMap,
} from 'rxjs/operators'
import { AccountPropsActions } from '../reducers/account-properties.reducer'
import { UIActions } from '../reducers/ui.reducer'
import {
  IOrgProperty,
  IReceivingEmailProperty,
  ISenderSourceProperty,
} from '../types'
import { broadcastAjaxErrors, broadcastAjaxSuccess } from './utils'
import { genUrl, receivingEmail, ediExport, senderSource } from '../../API'

const fetchOrgPropertiesEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.fetchProperties.match),
    switchMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS +
        `/${action.payload}/property?offset=${action.meta.offset}${process.env.REACT_APP_AMPERSAND_SYMBOL}limit=${action.meta.limit}`
      return ajax(url).pipe(
        map(res =>
          AccountPropsActions.populateList(res.response.data, action.meta.more)
        ),
        catchError(error => of(broadcastAjaxErrors(error)))
      )
    })
  )

const fetchOtherOrgPropertiesEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.fetchOtherProperties.match),

    switchMap(action => {
      const amp = process.env.REACT_APP_AMPERSAND_SYMBOL
      const url = `${process.env.REACT_APP_API_ACCOUNTS}/${action.payload}/other-org-property?otherOrgUniqueKey=${action.meta.otherOrgId}${amp}limit=${action.meta.limit}${amp}offset=${action.meta.offset}`
      return ajax.getJSON(url).pipe(
        map((res: any) =>
          AccountPropsActions.populateOtherList(res.data, action.meta.more)
        ),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

const fetchReceivingEmailsEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.fetchReceivingEmails.match),
    switchMap(action => {
      const url = genUrl(receivingEmail.getList, { orgId: action.payload })
      return ajax.getJSON(url).pipe(
        flatMap((response: any) => [
          AccountPropsActions.populateReceivingEmailList(
            response.data as IReceivingEmailProperty[]
          ),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

const fetchEDIExportsEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.fetchEDIExports.match),
    switchMap(action => {
      const url = genUrl(ediExport.getList, { orgId: action.payload })
      return ajax(url).pipe(
        flatMap(response => [
          AccountPropsActions.populateEDIExportList(
            response.response.data ? response.response.data : []
          ),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

const fetchSenderSourceEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.fetchSenderSources.match),
    switchMap(action => {
      const url = genUrl(senderSource.getList, { orgId: action.payload })
      return ajax(url).pipe(
        flatMap(response => [
          AccountPropsActions.populateSenderSourceList(
            response.response.data as ISenderSourceProperty[]
          ),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

const saveEDIExportEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.saveEDIExport.match),
    flatMap(action => {
      const url = genUrl(ediExport.postOne, { orgId: action.meta.myOrgId })
      return ajax
        .post(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          flatMap(response => [
            broadcastAjaxSuccess('EDI export property has been saved.'),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )

const addOrgPropertyEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.addOrgProperty.match),

    mergeMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS + `/${action.meta.orgid}/property`
      return ajax
        .post(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          mergeMap(response => [
            broadcastAjaxSuccess('Property has been saved.'),
            AccountPropsActions.addToList(response.response as IOrgProperty),
            UIActions.uiSetEditDrawerBusy(false),
          ]),
          catchError((error: AjaxError) => [
            broadcastAjaxErrors(error),
            UIActions.uiSetEditDrawerBusy(false),
          ])
        )
    })
  )

const addOtherPropertyEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.addOtherOrgProperty.match),
    mergeMap(action => {
      const myOrgId = action.meta.myOrgId
      const url = `${process.env.REACT_APP_API_ACCOUNTS}/${myOrgId}/other-org-property`
      return ajax
        .post(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          mergeMap(response => [
            AccountPropsActions.addToOtherList(action.payload),
            broadcastAjaxSuccess('Property added.'),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )

const addReceivingEmailEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.addReceivingEmail.match),
    flatMap(action => {
      const url = genUrl(receivingEmail.getList, { orgId: action.meta.myOrgId })
      return ajax
        .post(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          flatMap(response => [
            broadcastAjaxSuccess(
              `Property ${action.payload.code} has been added.`
            ),
            AccountPropsActions.addToReceivingdEmailList(
              response.response as IReceivingEmailProperty
            ),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )

const addSenderSourceEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.addSenderSource.match),
    flatMap(action => {
      const url = genUrl(senderSource.postOne, { orgId: action.meta.myOrgId })
      return ajax
        .post(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          flatMap(response => [
            AccountPropsActions.addToSenderSourceList(
              response.response as ISenderSourceProperty
            ),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )

const removeOrgPropertyEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.removeOrgProperty.match),
    mergeMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS +
        `/${action.meta.orgid}/property/${action.payload}`
      return ajax.delete(url).pipe(
        mergeMap(() => [
          broadcastAjaxSuccess('Property has been deleted'),
          AccountPropsActions.removeFromList(action.payload),
          UIActions.uiSetEditDrawerBusy(false),
        ]),
        catchError((error: AjaxError) => {
          return [
            broadcastAjaxErrors(error),
            UIActions.uiSetEditDrawerBusy(false),
          ]
        })
      )
    })
  )

const removeOtherOrgPropertyEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.removeOtherOrgProperty.match),
    switchMap(action => {
      const url = `${process.env.REACT_APP_API_ACCOUNTS}/${action.meta.myOrgId}/other-org-property/${action.payload.otherOrgUniqueKey}/${action.payload.property}`
      return ajax.delete(url).pipe(
        mergeMap(() => [
          broadcastAjaxSuccess('The peroperty has beend deleted'),
          AccountPropsActions.removeOtherFromList(action.payload),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

const removeReceivedEmailEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.removeReceivingEmail.match),
    switchMap(action => {
      const url = genUrl(receivingEmail.deleteOne, {
        orgId: action.meta.myOrgId,
        propid: action.payload,
      })
      return ajax.delete(url).pipe(
        flatMap(() => [
          broadcastAjaxSuccess('Property deleted.'),
          AccountPropsActions.removeReceivingEmailFromList(action.payload),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

const removeSenderSourceEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.removeSenderSource.match),
    switchMap(action => {
      const url = genUrl(senderSource.deleteOne, {
        propid: action.payload,
        orgId: action.meta.myOrgId,
      })
      return ajax.delete(url).pipe(
        flatMap(() => [
          AccountPropsActions.removeSenderSourceFromList(action.payload),
        ]),
        catchError(error => [broadcastAjaxErrors(error)])
      )
    })
  )

const replaceOrgPropertyEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.replaceOrgProperty.match),

    switchMap(action => {
      const url =
        process.env.REACT_APP_API_ACCOUNTS +
        `/${action.meta.orgid}/property/${action.payload.orgPropertyNo}`
      return ajax
        .put(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          mergeMap(response => [
            AccountPropsActions.replaceOnList(action.payload),
            broadcastAjaxSuccess('Property has been edited'),
            UIActions.uiSetEditDrawerBusy(false),
          ]),
          catchError(error =>
            of(broadcastAjaxErrors(error), UIActions.uiSetEditDrawerBusy(false))
          )
        )
    })
  )

const replaceOtherOrgPropertyEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.replaceOtherOrgProperty.match),
    switchMap(action => {
      const myOrgId = action.meta.myOrgId
      const url = `${process.env.REACT_APP_API_ACCOUNTS}/${myOrgId}/other-org-property`
      return ajax
        .post(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          mergeMap(response => [
            broadcastAjaxSuccess('Property modified.'),
            AccountPropsActions.replaceOtherOnList(
              action.payload,
              action.meta.oldproperty
            ),
          ]),

          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )

const replaceReceivingEmailEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.replaceReceivingEmail.match),
    switchMap(action => {
      const property: IReceivingEmailProperty = action.payload
      const url = genUrl(receivingEmail.putOne, {
        orgId: action.meta.myOrgId,
        propid: property.id!,
      })
      return ajax
        .put(url, property, { 'Content-Type': 'application/json' })
        .pipe(
          flatMap(() => [
            broadcastAjaxSuccess('Property has been updated.'),
            AccountPropsActions.replaceOnReceivingEmailList(property),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )
const replaceSenderSourceEpic: Epic = action$ =>
  action$.pipe(
    filter(AccountPropsActions.replalceSenderSource.match),
    flatMap(action => {
      const url = genUrl(senderSource.putOne, {
        propid: action.payload.id as number,
        orgId: action.meta.myOrgId,
      })
      return ajax
        .put(url, action.payload, { 'Content-Type': 'application/json' })
        .pipe(
          flatMap(() => [
            AccountPropsActions.replaceSenderSourceOnList(action.payload),
          ]),
          catchError(error => [broadcastAjaxErrors(error)])
        )
    })
  )
export const propertiesEpics = [
  addOrgPropertyEpic,
  fetchOrgPropertiesEpic,
  removeOrgPropertyEpic,
  replaceOrgPropertyEpic,
  addOtherPropertyEpic,
  fetchOtherOrgPropertiesEpic,
  replaceOtherOrgPropertyEpic,
  removeOtherOrgPropertyEpic,
  fetchReceivingEmailsEpic,
  addReceivingEmailEpic,
  removeReceivedEmailEpic,
  replaceReceivingEmailEpic,
  fetchEDIExportsEpic,
  saveEDIExportEpic,
  addSenderSourceEpic,
  fetchSenderSourceEpic,
  removeSenderSourceEpic,
  replaceSenderSourceEpic,
]
