import React from 'react'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox'
import { CircularProgress } from '@material-ui/core'
interface Props {
  loading: boolean
}
const LoadingCheckbox = (props: Props & CheckboxProps) => {
  const { onChange, color, loading, checked } = props

  return (
    <>
      {loading && <CircularProgress color="primary" size={24} />}
      {!loading && (
        <Checkbox
          color={color || 'primary'}
          checked={checked}
          onChange={onChange}
        />
      )}
    </>
  )
}
export default LoadingCheckbox
