import {
  Box,
  Checkbox,
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@material-ui/core'
import { Error } from '@material-ui/icons'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import {
  fetchProducts,
  populateList,
  selectProduct,
  updateProductMeta,
} from '../../redux/actions/product.actions'
import { AppState } from '../../redux/store'
import { IAccount, IProductFromAPI, IProductMeta } from '../../redux/types'
import EditDrawer from '../edit-drawer'
import BuyerCatalogueToolset from './buyer-catalogue-toolset'
import ProductProfile from './product-profile'
import UomMappingBadge from './uom-mapping-badge'
import PriceForQtyOneBadge from './price-for-qty-one-badge'
import ExportedDateTimeWidget from './exported-date-time-widget'
import SkeletonTable from './../skeleton-table'
import { UIActions } from '../../redux/reducers/ui.reducer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    tableRow: {
      cursor: 'pointer',
    },
  })
)

const BuyersProductList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const credentials = useCredentials()
  const [limit] = React.useState(20)
  const [peek, setPeek] = React.useState(false)
  const [sellerId, setSellerId] = React.useState<undefined | string>(undefined)
  const [seller, setSeller] = React.useState<undefined | IAccount>(undefined)

  const loadingList = useSelector(
    (state: AppState) => state.ui.fetching['product_list']
  )
  const cursor: string | undefined = useSelector(
    (state: AppState) => state.products.cursor
  )
  const list: IProductFromAPI[] = useSelector(
    (state: AppState) => state.products.list
  )
  const selectedProduct: IProductFromAPI | null = useSelector(
    (state: AppState) => state.products.selected
  )

  const suppliersList: IAccount[] = useSelector(
    (state: AppState) => state.accountsList
  )

  const [ref, inView] = useInView()
  const getInvViewRef = (position: number) => {
    if (
      position === list.length &&
      list.length >= limit &&
      cursor !== undefined
    ) {
      return ref
    }
    return null
  }

  React.useEffect(() => {
    if (
      credentials.getOrgId() &&
      sellerId &&
      credentials.renderForOrgType('UNIVERSITY') &&
      limit > 0
    ) {
      dispatch(UIActions.uiSetFetching('product_list', true))
      dispatch(
        fetchProducts(
          {
            seller: sellerId,
            buyer: credentials.getOrgId(),
          },
          false,
          limit
        )
      )
    }
    return () => {
      dispatch(populateList([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, sellerId])

  React.useEffect(() => {
    if (inView) loadMore()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  const loadMore = () => {
    if (sellerId) {
      dispatch(UIActions.uiSetFetching('product_list', true))
      dispatch(
        fetchProducts(
          {
            seller: sellerId,
            buyer: credentials.getOrgId(),
          },
          true,
          limit,
          cursor
        )
      )
    }
  }

  const handleSupplierSelection = (id: string) => {
    if (id !== '') {
      const seller = suppliersList.find(s => s.uniqueKey === id)
      setSeller(seller)
      setSellerId(id)
      setPeek(false)
    }
  }

  const handleExclusion = (product: IProductFromAPI) => {
    if (seller) {
      const meta = product.meta
        ? { ...product.meta, excluded: !product.meta.excluded }
        : {
            excluded: false,
            productCode: product.product.productCode,
            buyerSellerId: product.product.sellerId,
          }
      dispatch(
        updateProductMeta(
          {
            ...meta,
          } as IProductMeta,
          seller.uniqueKey,
          credentials.getOrgId()
        )
      )
    }
  }

  return (
    <>
      <BuyerCatalogueToolset
        handleSelect={handleSupplierSelection}
        buyerId={credentials.getOrgId()}
        areProducts={list && list.length > 0}
      />

      <Box>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" style={{ zIndex: 1 }} size="medium">
                Included
              </TableCell>
              <TableCell
                padding="checkbox"
                style={{ zIndex: 1 }}
                size="medium"
              ></TableCell>
              <TableCell size="medium">Code</TableCell>
              <TableCell size="medium">Description</TableCell>
              <TableCell size="medium">Flag Code</TableCell>
              <TableCell size="medium">Purchasing Unit</TableCell>
              <TableCell size="medium">Our Price(excl)</TableCell>
              <TableCell size="medium">Supplier Price(excl)</TableCell>
              <TableCell size="medium">Last Exported</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((p: IProductFromAPI, i) => (
              <TableRow
                className={classes.tableRow}
                key={i}
                hover
                selected={
                  selectedProduct !== null &&
                  selectedProduct.product.productCode === p.product.productCode
                }
                ref={getInvViewRef(i + 1)}
                onClick={() => {
                  setPeek(true)
                  dispatch(selectProduct(p))
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={p.meta !== undefined && !p.meta.excluded}
                    color="primary"
                    onChange={() => handleExclusion(p)}
                    onClick={e => e.stopPropagation()}
                  />
                </TableCell>
                <TableCell padding="checkbox">
                  {p.priceDiscrepancyTable &&
                    p.priceDiscrepancyTable.length > 0 && (
                      <Tooltip title="Discrepancies detected!">
                        <IconButton size="small">
                          <Error color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                </TableCell>
                <TableCell>{p.product.productCode}</TableCell>
                <TableCell>{p.product.description}</TableCell>
                <TableCell>
                  {p.product.flagCode === 'A'
                    ? 'Active'
                    : p.product.flagCode === 'C'
                    ? 'Cancelled'
                    : p.product.flagCode}
                </TableCell>
                <TableCell>
                  <UomMappingBadge mapping={p.uomMapping} />
                </TableCell>
                <TableCell>
                  <PriceForQtyOneBadge
                    prices={p.meta ? p.meta.contractPrices : undefined}
                    type="contract"
                  />
                </TableCell>
                <TableCell>
                  <PriceForQtyOneBadge type="product" prices={p.prices} />
                </TableCell>
                <TableCell>
                  {p.meta && (
                    <ExportedDateTimeWidget
                      epoch={p.meta.lastExportedEpochMilliSeconds}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SkeletonTable columns={5} show={loadingList} />
        {selectedProduct !== null && (
          <EditDrawer
            open={peek}
            name={`Product Code: ${selectedProduct.product.productCode}`}
            handleClose={() => setPeek(false)}
          >
            <ProductProfile
              product={selectedProduct}
              buyerId={credentials.getOrgId()}
              seller={seller}
            />
          </EditDrawer>
        )}
      </Box>
    </>
  )
}
export default BuyersProductList
