import { createAction } from 'typesafe-actions'
import { IPolicy, IUserProfile } from '../types/profile.types'

export const addPolicy = createAction(
  'profile/ADD_POLICY',
  action => (policy: IPolicy) => action(policy)
)

export const fetchPolicy = createAction(
  'profile/FETCH_POLICY', 
  action => () => action()
)

export const fetchProfile = createAction(
  'profile/FETCH_USER_PROFILE',
  action => () => action()
)

export const addUserProfile = createAction(
  'profile/ADD_USER_PROFILE',
  action => (userprofile: IUserProfile) => action(userprofile)
)

export const acceptTermsOfUse = createAction(
  'profile/ACCEPT_TERMS_OF_USE',
  action => (policy: IPolicy) => action(policy)
)
