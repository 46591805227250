import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../redux/store'
import { InvoiceActions } from '../../redux/reducers/invoice.reducer'
import { Box, Typography } from '@material-ui/core'

interface Props {
  invoiceId: string
  buyerId: string
}
const InvoiceHistory = (props: Props) => {
  const { buyerId, invoiceId } = props
  const history = useSelector(
    (state: AppState) => state.invoices.selectedHistory
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(InvoiceActions.getInvoiceHistory(invoiceId, buyerId))
  }, [invoiceId, buyerId])

  return (
    <Box ml={2} mt={2}>
      <Typography variant="h2">
        <strong>Invoice History</strong>
      </Typography>
      {history &&
        history.map((h, i) => (
          <Box m={2} key={i}>
            <div>
              <strong>Date: </strong>
              {h.date && new Date(h.date).toString().slice(0, 21)}
            </div>
            <div>
              <strong>Action: </strong>
              {h.action}
            </div>
            <div>
              <strong>By: </strong>
              {h.user}
            </div>
          </Box>
        ))}
    </Box>
  )
}

export default InvoiceHistory
