import React, { useState } from 'react';
import AsnEdit from './asn-edit';
import AsnList from './asn-list';

type Props = {
    orgid: string,
    buyerId: string
    orderNumber: string
  }

const AsnPage = (props: Props) => {
    const { orgid, buyerId, orderNumber } = props
    const [showDetail, setShowDetail] = useState(false);

    if (showDetail)
        return <AsnEdit orgid={orgid} buyerId={buyerId} orderNumber={orderNumber} hanldeShowList={() => setShowDetail(false)}/>
    else 
        return <AsnList handleShowDetail={() => setShowDetail(true)} orgid={orgid} orderNumber={orderNumber}/>
}

export default AsnPage