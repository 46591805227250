import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { IProductContractPrice, IProductPrice } from '../../redux/types'

interface Props {
  prices?: IProductPrice[]
  contractPrices?: IProductContractPrice[]
  children?: React.ReactNode
}
const PricingTab = ({ contractPrices, prices, children }: Props) => {
  const ContractPrices = () => {
    if (contractPrices) {
      return (
        <>
          <Typography variant="subtitle2">Contract Prices</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>UOM</TableCell>
                <TableCell>QTY</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Effective Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contractPrices.map((p: IProductContractPrice, i) => (
                <TableRow key={i} hover>
                  <TableCell>{p.uom}</TableCell>
                  <TableCell>{p.quantity}</TableCell>
                  <TableCell>{p.price}</TableCell>
                  <TableCell>{p.effectiveDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )
    }
    return null
  }

  const SuppliersPrices = () => {
    if (prices) {
      return (
        <>
          <Typography variant="subtitle2">Supplier's Prices</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>UOM</TableCell>
                <TableCell>L.Qty</TableCell>
                <TableCell>U.Qty</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>St. Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {prices.map((p: IProductPrice, i: number) => (
                <TableRow key={i} hover>
                  <TableCell>{p.uom}</TableCell>
                  <TableCell>{p.lowerQuantity}</TableCell>
                  <TableCell>{p.upperQuantity}</TableCell>
                  <TableCell>{p.price}</TableCell>
                  <TableCell>{p.startDate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )
    }
    return null
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md>
          <ContractPrices />
        </Grid>
        <Grid item md>
          <SuppliersPrices />
        </Grid>
      </Grid>
      {children}
    </>
  )
}

export default PricingTab
