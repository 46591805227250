import { ActionsObservable, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { ajax, AjaxError } from 'rxjs/ajax'
import {
  catchError,
  debounceTime,
  mergeMap,
  switchMap,
  flatMap,
} from 'rxjs/operators'
import { selectAccount } from '../actions/account.actions'
import {
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT,
  FETCH_ACCOUNTS,
  IAddAccount,
  IDeleteAccount,
  IEditAccount,
  IFetchAccounts,
  loadAccounts,
  loadMoreAccounts,
  removeAccountFromList,
  setAlertOnAccount,
  setBusyOnAccount,
} from '../actions/account.actions.old'
import { addToasterMessage } from '../actions/feedback.actions'
import { APIError, IAccount } from '../types'
import { UIActions } from '../reducers/ui.reducer'
const fetchAccountsEpic = (action$: ActionsObservable<IFetchAccounts>) => {
  return action$.pipe(
    ofType(FETCH_ACCOUNTS),
    debounceTime(500),
    switchMap(action => {
      let url = `${process.env.REACT_APP_API_ACCOUNTS}?offset=${action.payload.offset}${process.env.REACT_APP_AMPERSAND_SYMBOL}limit=${action.payload.limit}`
      if (action.payload.query) {
        action.payload.query.forEach(q => {
          url += `${process.env.REACT_APP_AMPERSAND_SYMBOL}${q.key}=${encodeURI(
            q.value
          )}`
        })
      }
      return ajax.getJSON(url).pipe(
        flatMap((response: any) => {
          const data: IAccount[] =
            response && response.data ? response.data : []
          if (action.payload.merge) {
            return of(
              loadMoreAccounts(data),
              UIActions.uiSetFetching('account_lines', false)
            )
          }
          return of(
            loadAccounts(data),
            UIActions.uiSetFetching('account_lines', false)
          )
        })
      )
    })
  )
}

const postAccountEpic = (action$: ActionsObservable<IAddAccount>) => {
  return action$.pipe(
    ofType(ADD_ACCOUNT),
    mergeMap(action => {
      const url = `${process.env.REACT_APP_API_ACCOUNTS}`
      return ajax
        .post(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap(response => [
            addToasterMessage({
              message: 'Record saved',
              variant: 'success',
            }),
            setBusyOnAccount(action.payload.uniqueKey, false),
            selectAccount(response.response as IAccount),
            UIActions.uiSetEditDrawerBusy(false),
          ]),
          catchError((error: AjaxError) => {
            const eb: APIError = error.response
            const e: string = eb && eb.errors ? eb.errors.join() : error.message
            return of(
              setAlertOnAccount(action.payload.uniqueKey, e),
              setBusyOnAccount(action.payload.uniqueKey, false),
              addToasterMessage({ message: e, variant: 'error' }),
              UIActions.uiSetEditDrawerBusy(false)
            )
          })
        )
    })
  )
}

const putAccountEpic = (action$: ActionsObservable<IEditAccount>) => {
  return action$.pipe(
    ofType(EDIT_ACCOUNT),
    debounceTime(500),
    mergeMap(action => {
      const url = `${process.env.REACT_APP_API_ACCOUNTS}/${action.payload.uniqueKey}`
      return ajax
        .put(url, action.payload, {
          'Content-Type': 'application/json',
        })
        .pipe(
          mergeMap(() => [
            setBusyOnAccount(action.payload.uniqueKey, false),
            selectAccount(action.payload),
            addToasterMessage({
              message: 'Record updated',
              variant: 'success',
            }),
            UIActions.uiSetEditDrawerBusy(false),
          ]),
          catchError((error: AjaxError) => {
            const eb: APIError = error.response
            const e: string = eb && eb.errors ? eb.errors.join() : error.message
            return of(
              setAlertOnAccount(action.payload.uniqueKey, e),
              setBusyOnAccount(action.payload.uniqueKey, false),
              addToasterMessage({ message: e, variant: 'error' }),
              UIActions.uiSetEditDrawerBusy(false)
            )
          })
        )
    })
  )
}

const deleteAccountEpic = (action$: ActionsObservable<IDeleteAccount>) => {
  return action$.pipe(
    ofType(DELETE_ACCOUNT),
    debounceTime(500),
    mergeMap(action => {
      const url = `${process.env.REACT_APP_API_ACCOUNTS}/${action.payload}`
      return ajax.delete(url).pipe(
        mergeMap(() => [
          removeAccountFromList(action.payload),
          selectAccount(null),
          addToasterMessage({
            message: 'Record has been deleted',
            variant: 'success',
          }),
          UIActions.uiSetEditDrawerBusy(false),
        ]),
        catchError((error: AjaxError) => {
          const eb: APIError = error.response
          const e: string = eb && eb.errors ? eb.errors.join() : error.message
          return of(
            setAlertOnAccount(action.payload, e),
            setBusyOnAccount(action.payload, false),
            addToasterMessage({ message: e, variant: 'error' }),
            UIActions.uiSetEditDrawerBusy(false)
          )
        })
      )
    })
  )
}

export const accountEpics = [
  fetchAccountsEpic,
  postAccountEpic,
  putAccountEpic,
  deleteAccountEpic,
]
