import {
  createStyles,
  Drawer,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Button,
  Typography,
} from '@material-ui/core'
import { CloudDone, Error } from '@material-ui/icons'
import clsx from 'clsx'
import React from 'react'
import { useInView } from 'react-intersection-observer'
import { useDispatch, useSelector } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import { ComponentWithRoutes } from '../../pages/types'
import { InvoiceActions } from '../../redux/reducers/invoice.reducer'
import { UIActions } from '../../redux/reducers/ui.reducer'
import { AppState } from '../../redux/store'
import { IInvoiceValidationErrors } from '../../redux/types/invoice.types'
import FormatCurrencyText from '../utils/format-currency-text'
import SkeletonTable from './../skeleton-table'
import InvoiceErrorsView from './invoice-errors-view'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorsPane: {
      width: '45vw',
    },
    actions: {
      display: 'flex',
    },
    unread: {
      '& td': {
        fontWeight: 'bold',
      },
    },
    clickable: {
      cursor: 'pointer',
    },
    exported: {
      opacity: '.3',
    },
    statuses: {
      padding: theme.spacing(1),
    },
  })
)

interface Props {
  keepList?: boolean
  selectedLineId?: number
}
const InvoiceList = (props: Props & ComponentWithRoutes) => {
  const classes = useStyles()

  const invoices = useSelector((state: AppState) => state.invoices.list)
  const limit = useSelector((state: AppState) => state.invoices.query.limit)
  const veStatus = useSelector(
    (state: AppState) => state.invoices.query.veStatus
  )
  const loadingList = useSelector(
    (state: AppState) => state.ui.fetching['invoices_list']
  )
  const [errorPage, seterrorPage] = React.useState<
    IInvoiceValidationErrors | undefined
  >(undefined)

  const [ref, inView] = useInView()
  const getInvViewRef = (position: number) => {
    if (position === invoices.length - 5 && invoices.length >= limit) {
      return ref
    }
    return null
  }
  const credentials = useCredentials()
  const dispatch = useDispatch()

  const scrollToRef = React.useRef<HTMLTableDataCellElement>(null)
  const handleScrollTo = () => {
    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
      })
    }
  }

  const getInvoices = (more = false, offset: number) => {
    if (credentials.getOrgId()) {
      dispatch(UIActions.uiSetFetching('invoices_list', true))
      dispatch(
        InvoiceActions.getInvoices(
          credentials.getOrgId(),
          limit,
          offset,
          more,
          veStatus
        )
      )
    }
  }

  React.useEffect(() => {
    if (scrollToRef !== null) handleScrollTo()
  }, [scrollToRef])

  React.useEffect(() => {
    if (!props.keepList || !invoices || invoices.length === 0)
      getInvoices(false, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials.getOrgId(), veStatus])

  React.useEffect(() => {
    if (inView) {
      getInvoices(true, invoices.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView])

  return (
    <div>
      {!props.keepList && (
        <div className={classes.statuses}>
          <Typography variant="caption">
            Show documents with status:{' '}
          </Typography>
          <Button
            onClick={() => dispatch(InvoiceActions.setStatus(undefined))}
            disabled={!veStatus}
          >
            All
          </Button>
          <Button
            onClick={() => dispatch(InvoiceActions.setStatus('Pending'))}
            disabled={veStatus === 'Pending'}
          >
            Pending
          </Button>
          <Button
            onClick={() => dispatch(InvoiceActions.setStatus('Emailed'))}
            disabled={veStatus === 'Emailed'}
          >
            Emailed
          </Button>
          <Button
            onClick={() => dispatch(InvoiceActions.setStatus('Exported'))}
            disabled={veStatus === 'Exported'}
          >
            Exported
          </Button>
        </div>
      )}
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">Actions</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Number</TableCell>
            <TableCell>PO Number</TableCell>
            <TableCell>From</TableCell>
            <TableCell>Total Value</TableCell>
            <TableCell>Created</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices.map((inv, i) => (
            <TableRow
              key={i}
              ref={getInvViewRef(i + 1)}
              hover
              className={clsx(!inv.read && classes.unread, classes.clickable)}
              onClick={() =>
                props.navigate &&
                props.navigate(`${credentials.getOrgId()}/${inv.oiid}`)
              }
              selected={
                props.selectedLineId !== undefined &&
                props.selectedLineId === inv.oiid
              }
            >
              <TableCell
                padding="checkbox"
                ref={
                  props.selectedLineId !== undefined &&
                  props.selectedLineId === inv.oiid
                    ? scrollToRef
                    : null
                }
              >
                {inv.document && inv.document.validationErrors && (
                  <Tooltip title="Validation errors detected, click for details.">
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={(e) => {
                        e.stopPropagation()
                        seterrorPage(inv.document!.validationErrors)
                      }}
                    >
                      <Error color="error" />
                    </IconButton>
                  </Tooltip>
                )}
                {!inv.document && (
                  <Tooltip title="Validation errors detected, click for details.">
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={(e) => {
                        e.stopPropagation()
                        seterrorPage({
                          general: [{ error: 'Document extraction error.' }],
                        })
                      }}
                    >
                      <Error color="action" />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell>{inv.veStatus}</TableCell>
              <TableCell>
                {inv.title ||
                  (inv.message && inv.message.subject) ||
                  'Document with extraction problems'}
              </TableCell>
              <TableCell>
                {(inv.document && inv.document.documentId) ||
                  'No Number detected'}
              </TableCell>
              <TableCell>{inv?.document?.referenceId}</TableCell>
              <TableCell>
                {inv.supplierName || (inv.message && inv.message.senderEmail)}
              </TableCell>
              <TableCell align="right">
                {inv.document && inv.document.totalValue && (
                  <FormatCurrencyText
                    value={inv.document.totalValue}
                    currency={inv.document.currency}
                  />
                )}
              </TableCell>
              <TableCell>
                {(inv.document &&
                  inv.document.dateCreated &&
                  new Date(inv.document.dateCreated).toDateString()) ||
                  (inv.message &&
                    inv.message.createdDate &&
                    new Date(inv.message.createdDate).toDateString())}
              </TableCell>
              <TableCell padding="checkbox">
                {inv.exported && (
                  <Tooltip title="Document Exported">
                    <CloudDone className={classes.exported} />
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <SkeletonTable show={loadingList} rows={invoices.length === 0 ? 10 : 3} />
      {errorPage && (
        <Drawer
          anchor="right"
          open={errorPage !== undefined}
          onClose={() => seterrorPage(undefined)}
          classes={{
            paper: classes.errorsPane,
          }}
        >
          <InvoiceErrorsView errors={errorPage} />
        </Drawer>
      )}
    </div>
  )
}

export default InvoiceList
