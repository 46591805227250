import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slide,
  Theme,
  Tooltip,
} from '@material-ui/core'
import {
  CloudDownloadOutlined,
  CloudUpload,
  InsertDriveFile,
} from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addToasterMessage } from '../../redux/actions/feedback.actions'
import { changeTitle } from '../../redux/actions/header.actions'
import { AppState } from '../../redux/store'
import FileUpload from '../filesystem/file-upload'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
    },
    fileToolset: {
      flexGrow: 1,
      margin: '1em',
      padding: '1em',
      borderRadius: 5,
    },
  })
)

interface Props {
  buyerId: string
  handleSelect: (id: string) => void
  areProducts: boolean
}

const BuyerCatalogueToolset = (props: Props) => {
  const [sellerId, setSellerID] = React.useState<string>('')
  const [busyDownloadButton, setBusyDownloadButton] = React.useState(false)
  const [downloadTemplateUrl, setDownloadTemplateUrl] = React.useState('')
  const [busyUploadButton, setBusyUploadButton] = React.useState(false)
  const [uploadTemplateUrl, setUploadTemplateUrl] = React.useState('')
  const [busyExportButton, setBusyExportButton] = React.useState(false)
  const classes = useStyles()
  const dispatch = useDispatch()
  const suppliersList = useSelector((state: AppState) => state.accountsList)

  React.useEffect(() => {
    if (sellerId !== '') {
      props.handleSelect(sellerId)
      setBusyDownloadButton(false)
      setBusyUploadButton(false)
      setUploadTemplateUrl('')
      setDownloadTemplateUrl('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerId])

  const generateDownloadURL = async () => {
    const url = `${process.env.REACT_APP_API_BUYER}/${props.buyerId}/seller/${sellerId}/meta/action/download`
    setBusyDownloadButton(true)
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    if (res.ok) {
      const j = await res.json()
      if (j && j.downloadUrl) {
        setDownloadTemplateUrl(j.downloadUrl)
      }
    } else {
      dispatch(
        addToasterMessage({
          message: "Cound't generate file, please try later",
          variant: 'error',
        })
      )
    }
    setBusyDownloadButton(false)
  }

  const handleExport = async () => {
    setBusyExportButton(true)
    const url = `${process.env.REACT_APP_API_BUYER}/${props.buyerId}/seller/${sellerId}/product/action/export`

    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })

    if (res.ok) {
      dispatch(
        addToasterMessage({
          message: 'Export added to the queue.',
          variant: 'info',
        })
      )
    } else {
      dispatch(
        addToasterMessage({
          message: "Cound't export catalogue, please try later",
          variant: 'error',
        })
      )
    }

    setBusyExportButton(false)
  }

  const generateUploadURL = async () => {
    const url = `${process.env.REACT_APP_API_BUYER}/${props.buyerId}/seller/${sellerId}/meta/action/upload-url`
    setBusyUploadButton(true)
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
    if (res.ok) {
      const j = await res.json()
      if (j && j.uploadUrl) {
        setUploadTemplateUrl(j.uploadUrl)
      }
    } else {
      dispatch(
        addToasterMessage({
          message: "Cound't upload file, please try later",
          variant: 'error',
        })
      )
    }
    setBusyUploadButton(false)
  }

  const GenerateTemplateButton = () => {
    if (busyDownloadButton) {
      return (
        <IconButton>
          <CircularProgress size={25} />
        </IconButton>
      )
    }
    return (
      <Tooltip title="Generate Template File">
        <Box display="inline-block">
          <IconButton
            onClick={generateDownloadURL}
            disabled={downloadTemplateUrl !== ''}
          >
            <InsertDriveFile />
          </IconButton>
        </Box>
      </Tooltip>
    )
  }

  const RequestModificationButton = () => {
    if (busyUploadButton) {
      return (
        <IconButton>
          <CircularProgress size={25} />
        </IconButton>
      )
    }
    return (
      <Tooltip title="Request Modification">
        <IconButton onClick={generateUploadURL}>
          <CloudUpload />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <Grid container>
      <Grid item sm={12} md={4}>
        <FormControl fullWidth className={classes.formControl}>
          <InputLabel htmlFor="supplier">Select a Supplier</InputLabel>
          <Select
            value={sellerId}
            inputProps={{
              id: 'supplier',
            }}
            onChange={e => {
              const v = e.target.value as string
              setSellerID(v)
              const supplier = suppliersList.find(s => s.uniqueKey === v)
              if (supplier) {
                dispatch(changeTitle('Catalogue of ' + supplier.name))
              }
            }}
          >
            {suppliersList &&
              suppliersList.map((s, i) => (
                <MenuItem key={i} value={s.uniqueKey}>
                  {s.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item md={8} sm={12}>
        {sellerId && (
          <Box className={classes.fileToolset}>
            <Grid container>
              <Grid item sm={12} md={4}>
                <GenerateTemplateButton />
                <Tooltip title="Download Template File">
                  <Box display="inline-block">
                    <IconButton
                      disabled={downloadTemplateUrl === ''}
                      href={downloadTemplateUrl}
                      download
                    >
                      <CloudDownloadOutlined />
                    </IconButton>
                  </Box>
                </Tooltip>
                <RequestModificationButton />
              </Grid>
              <Grid item sm={12} md={4}>
                <Slide in={uploadTemplateUrl !== ''} direction="down">
                  <Box flexGrow={1}>
                    {uploadTemplateUrl !== '' && (
                      <FileUpload
                        url={uploadTemplateUrl}
                        method="PUT"
                        callback={() => {
                          setTimeout(() => {
                            setUploadTemplateUrl('')
                          }, 3000)
                        }}
                      />
                    )}
                  </Box>
                </Slide>
              </Grid>
              <Grid
                item
                container
                sm={12}
                md={4}
                justify="flex-end"
                alignItems="flex-start"
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!props.areProducts || busyExportButton}
                  onClick={handleExport}
                >
                  Export Catalogue
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}
export default BuyerCatalogueToolset
