import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditUser from '../components/accounts/edit-user'
import EditDrawer from '../components/edit-drawer'
import { changeTitle } from '../redux/actions/header.actions'
import { fetchUsers, selectUser } from '../redux/actions/user.actions'
import { AppState } from '../redux/store'
import { IAccount } from '../redux/types'
import { ComponentWithRoutes } from './types'

type Props = {
  orgid?: string
} & ComponentWithRoutes

const UsersList = (props: Props) => {
  const [title, setTitle] = useState('User Community')
  const { orgid } = props
  const dispatch = useDispatch()
  const [acc, setAcc] = useState<IAccount | undefined>(undefined)
  const [openDrawer, setOpenDrawer] = useState(false)
  // const [selectedUser, setSelectedUser] = useState<IUser | undefined>(undefined)

  const users = useSelector((state: AppState) => state.userList)
  const selectedUser = useSelector((state: AppState) => state.selectedUser)

  useEffect(() => {
    dispatch(changeTitle(title))
    document.title = title
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  useEffect(() => {
    dispatch(fetchUsers(orgid as string, 0, 20))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchAccount = async (id?: string) => {
    if (id) {
      const url = process.env.REACT_APP_API_ACCOUNTS + '/' + id
      const resp = await fetch(url)
      if (resp.ok) {
        const j: IAccount = await resp.json()
        if (j && j.name) {
          setAcc(j)
          setTitle(`User Community of ${j.name}`)
        }
      }
    }
  }
  useEffect(() => {
    if (!acc) fetchAccount(orgid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acc])

  if (acc) {
    return (
      <>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <Tooltip title="Add User">
                  <IconButton
                    onClick={() => {
                      setOpenDrawer(true)
                      dispatch(selectUser(null))
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>User Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((u, i) => (
              <TableRow key={'us_' + u.userNo} hover={true}>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => {
                      dispatch(selectUser(u))
                      setOpenDrawer(true)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{u.firstName}</TableCell>
                <TableCell>{u.lastName}</TableCell>
                <TableCell>{u.email}</TableCell>
                <TableCell>{u.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <EditDrawer
          name="Add/Edit User"
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
        >
          <EditUser orgid={orgid as string} user={selectedUser}></EditUser>
        </EditDrawer>
      </>
    )
  }
  return <Typography>Wrong account... </Typography>
}

export default UsersList
