import { createReducer } from 'typesafe-actions'
import { selectAccount } from '../actions/account.actions'
import { ADD_ACCOUNT, EDIT_ACCOUNT, LOAD_ACCOUNTS, LOAD_MORE_ACCOUNTS, REMOVE_ACCOUNT_FROM_LIST, SET_ALTER_ON_ACCOUNT, SET_BUSY_ON_ACCOUNT, TAccountsActionTypes } from '../actions/account.actions.old'
import { IAccount, TAccountTypes } from '../types'

const initialState: IAccount[] = []

export const accountsList = (
  state = initialState,
  action: TAccountsActionTypes
): IAccount[] => {
  switch (action.type) {
    case ADD_ACCOUNT:
      return [...state, action.payload]
    case EDIT_ACCOUNT:
      return state.map(s => {
        if (s.uniqueKey === action.payload.uniqueKey) {
          return action.payload
        }
        return s
      })
    case REMOVE_ACCOUNT_FROM_LIST:
      return state.filter(s => s.uniqueKey !== action.payload)
    case LOAD_ACCOUNTS:
      if (action.payload) return action.payload
      return state
    case LOAD_MORE_ACCOUNTS:
      if (action.payload) return [...state, ...action.payload]
      return state
    case SET_BUSY_ON_ACCOUNT:
      return state.map(s => {
        if (s.uniqueKey === action.id) {
          return { ...s, busy: action.payload }
        }
        return s
      })
    case SET_ALTER_ON_ACCOUNT:
      return state.map(s => {
        if (s.uniqueKey === action.id) {
          return { ...s, alert: action.payload }
        }
        return s
      })
    default:
      return state
  }
}

// ! refactor reducers to this
export const selectedAccount = createReducer<IAccount | null, TAccountTypes>(
  null
).handleAction(selectAccount, (state, action) => {
  return action.payload
})
