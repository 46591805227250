import { Box, List, ListItem, ListItemIcon, Theme, Typography } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import { IInvoiceValidationErrors } from '../../redux/types/invoice.types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    section: {
      margin: '3em 0'
    }
  })
)

interface Props {
  errors: IInvoiceValidationErrors
}
const InvoiceErrorsView = (props: Props) => {
  const { errors } = props
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Typography variant="h1">Validation Errors</Typography>
      {errors.general && (
        <section className={classes.section}>
          <Typography variant="h3">General Errors</Typography>

          <List>
            {errors.general.map((e, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <Error />
                </ListItemIcon>
                {e.error}
              </ListItem>
            ))}
          </List>
        </section>
      )}

      {errors.header && (
        <section className={classes.section}>
          <Typography variant="h3">Header Errors</Typography>

          <List>
            {errors.header.map((e, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <Error />
                </ListItemIcon>
                {e.error}
              </ListItem>
            ))}
          </List>
        </section>
      )}

      {errors.line && (
        <section className={classes.section}>
          <Typography variant="h3">Line Errors</Typography>

          <List>
            {errors.line.map((e, i) => (
              <ListItem key={i}>
                <ListItemIcon>
                  <Error />
                </ListItemIcon>
                {e.error}
              </ListItem>
            ))}
          </List>
        </section>
      )}
    </Box>
  )
}
export default InvoiceErrorsView
