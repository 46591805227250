import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
const NameTag = () => {
  const nameTag = useSelector((state: AppState) => state.ui.renderFor)
  const profile = useSelector((state: AppState) => state.profile)
  return (
    <Box marginRight="1em">
      {profile && profile.user && (
        <Typography variant="caption" display="block">
          {profile.user.firstName} {profile.user.lastName}
        </Typography>
      )}
      {nameTag && (
        <Typography variant="subtitle1" display="block">
          {nameTag.companyName}
        </Typography>
      )}
    </Box>
  )
}

export default NameTag
