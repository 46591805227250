import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  createStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import React from 'react'
import { useDispatch } from 'react-redux'
import useCredentials from '../../hooks/use-credentials'
import {
  broadcastAjaxErrors,
  broadcastAjaxSuccess,
} from '../../redux/epics/utils'
import { IOrgProperty } from '../../redux/types'

interface Props {
  className?: string
  name: string
  title: string
  subheader?: string
  pattern?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(1),
      },
    },
  })
)

const PropertyBox = (props: Props) => {
  const { className, name, title, subheader, pattern } = props
  const classes = useStyles()

  const [id, setId] = React.useState<number>()
  const [value, setValue] = React.useState<string>('')
  const [display, setDisplay] = React.useState<boolean>(true)

  const dispatch = useDispatch()
  const credentials = useCredentials()
  const orgid: string = credentials.getOrgId()

  const url = `${process.env.REACT_APP_API_ACCOUNTS}/${orgid}/property?property=${name}`

  React.useEffect(() => {
    if (orgid) getProperty()
  }, [])

  const getProperty = async () => {
    const res = await fetch(url)
    const jsonResponse = await res.json()

    if (res.ok) {
      const properties = jsonResponse.data as IOrgProperty[]
      if (properties) {
        if (properties.length === 1) {
          setId(properties[0].orgPropertyNo)
          setValue(properties[0].value)
        } else if (properties.length > 1) {
          //More than one, cannot display it
          setDisplay(false)
        }
      }
    } else {
      dispatch(broadcastAjaxErrors(jsonResponse.errors))
    }
  }

  const isValid = () => {
    if (value === '') return false

    if (pattern) {
      let patt = new RegExp(pattern)
      return patt.test(value)
    }
    return true
  }

  const handleSubmit = async () => {
    if (value !== '') {
      const p: IOrgProperty = {
        property: name,
        value: value,
        orgNo: 0,
        orgPropertyNo: id,
      }
      let method = 'POST'
      let urlUpdate = `${process.env.REACT_APP_API_ACCOUNTS}/${orgid}/property`
      if (id) {
        method = 'PUT'
        urlUpdate += `/${id}`
      }
      const res = await fetch(urlUpdate, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(p),
      })

      if (res.ok) {
        if (method === 'POST') {
          const jsonResponse = await res.json()
          const np = jsonResponse as IOrgProperty
          setId(np.orgPropertyNo)
        }
        dispatch(broadcastAjaxSuccess(`${title} has been updated.`))
      } else {
        const jsonResponse = await res.json()
        dispatch(broadcastAjaxErrors(jsonResponse.errors))
      }
    }
  }

  if (!display) {
    return (
      <Card className={clsx(classes.root, className)}>
        <CardHeader title={title} />
        <CardContent className={classes.content}>
          <Typography>
            Cannot display value because there is a conflict, please contact
            support
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        title={title}
        subheader={subheader}
        avatar={
          <Avatar>
            <Settings />
          </Avatar>
        }
      ></CardHeader>
      <CardContent className={classes.content}>
        <TextField
          fullWidth
          label="Value"
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid()}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </CardActions>
    </Card>
  )
}
export default PropertyBox

export type TTaxCode = {
  taxCodeMapping: {
    taxableCode: string
    nonTaxableCode: string
  }
}
