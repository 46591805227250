import { Box, Button } from '@material-ui/core'
import 'filepond/dist/filepond.min.css'
import React from 'react'
import { FilePond } from 'react-filepond'

interface Props {
  url: string
  callback?: () => void
  method?: 'PUT' | 'POST'
  simple?: boolean
}
const FileUpload = ({ url, callback, method, simple }: Props) => {
  const [files, setFiles] = React.useState<FileList | null>(null)

  const handleFiles = (e: React.FormEvent<HTMLInputElement>) =>
    setFiles(e.currentTarget.files)

  const handleUpload = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (files && files[0]) {
      const res = await fetch(url, {
        method: method || 'POST',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        body: files[0]
      })
    }
    if (callback) callback()
  }

  if (simple) {
    return (
      <Box>
        <input type="file" name="templateFile" onChange={handleFiles} />
        <Button onClick={handleUpload}>Upload</Button>
      </Box>
    )
  }
  return (
    <FilePond
      server={{
        // process: {
        //   url: url,
        //   method: method || 'POST',
        //   headers: {
        //     'Content-Type': 'application/octet-stream'
        //   },
        //   onload: callback
        // }

        process: (
          /** The name of the input field */
          fieldName: string,
          /** The actual file object to send */
          file: Blob & {
            readonly lastModified: number
            readonly name: string
          },
          letadata: { [key: string]: any },
          /**
           * Should call the load method when done and pass the returned server file id.
           * This server file id is then used later on when reverting or restoring a file
           * so that your server knows which file to return without exposing that info
           * to the client.
           */
          load: (p: string | { [key: string]: any }) => void,
          /** Can call the error method is something went wrong, should exit after */
          error: (errorText: string) => void,
          /**
           * Should call the progress method to update the progress to 100% before calling load()
           * Setting computable to false switches the loading indicator to infinite mode
           */
          progress: any,
          /** Let FilePond know the request has been cancelled */
          abort: () => void
        ) => {
          progress(false)
          fetch(url, {
            method: method || 'POST',
            headers: {
              'Content-Type': 'application/octet-stream'
            },
            body: file
          }).then(res => {
            if (res.status >= 200 && res.status < 300) {
              load('done')
              if (callback) {
                callback()
              }
            } else {
              error("Something's wrong!")
            }
          })

          // Should expose an abort method so the request can be cancelled
          return {
            abort: () => {
              abort()
            }
          }
        }
      }}
    />
  )
}

export default FileUpload
