import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch } from 'react-redux'
import { genUrl, pricelists } from '../../API'
import useCredentials from '../../hooks/use-credentials'
import {
  broadcastAjaxErrors,
  broadcastAjaxSuccess,
} from '../../redux/epics/utils'
import { IAccount, IPricelist } from '../../redux/types'
import OrgList from '../accounts/list'
import LoadingCheckbox from '../loading-checkbox'
import scss from './pricelist-mapping.module.scss'
interface Props {
  pricelist: IPricelist
}
const PricelistMapping = (props: Props) => {
  const { pricelist } = props
  const dispatch = useDispatch()
  const [mapped, setmapped] = React.useState<string[]>([])
  const [loading, setLoading] = React.useState<boolean>(true)

  const credentials = useCredentials()

  const handleMapping = async () => {
    const url = genUrl(pricelists.buyers.putList, {
      sellerId: credentials.getOrgId(),
      pricelistId: pricelist.pricelistId!,
    })
    setLoading(true)
    const data = { buyerIds: mapped }
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    if (res.ok) {
      dispatch(
        broadcastAjaxSuccess(
          `Pricelist ${pricelist.name} mapped with ${mapped.length} accounts`
        )
      )
    } else {
      dispatch(broadcastAjaxErrors(res.statusText))
    }
    setLoading(false)
  }

  const checkExistingMapping = async () => {
    const url = genUrl(pricelists.buyers.getList, {
      sellerId: credentials.getOrgId(),
      pricelistId: pricelist.pricelistId!,
    })

    setLoading(true)
    const res = await fetch(url)
    if (res.ok) {
      const j: { buyerIds: string[] } = await res.json()
      if (j && j.buyerIds) {
        setmapped(j.buyerIds)
      }
    } else {
      dispatch(broadcastAjaxErrors(res.statusText))
    }
    setLoading(false)
  }

  const toggleCheckbox = (id: string) => {
    if (mapped.includes(id)) {
      setmapped(prev => [...prev.filter(n => n !== id)])
    } else {
      setmapped(prev => [...prev, id])
    }
  }

  const ListAction = (account: IAccount) => {
    return (
      <LoadingCheckbox
        loading={loading}
        checked={mapped.includes(account.uniqueKey)}
        onChange={() => toggleCheckbox(account.uniqueKey)}
      />
    )
  }

  React.useEffect(() => {
    checkExistingMapping()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricelist])

  return (
    <Box>
      <Box className={scss.actions}>
        <Typography variant="body1">
          Selected for {pricelist.name}: {mapped.length}
        </Typography>
        <Button color="primary" variant="contained" onClick={handleMapping}>
          Save Mapping
        </Button>
      </Box>
      <OrgList query={[{ key: 'type', value: '2' }]} actions={ListAction} />
    </Box>
  )
}
export default PricelistMapping
